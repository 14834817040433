import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
} from '@energy-stacks/core/ui';
import { Box, Dialog, Link, Stack, Typography } from '@mui/material';
import { WelcomeIcon } from './WelcomeIcon';
import { IconExternalLink } from '@tabler/icons-react';
import { useDialog } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
  markFirstTimeAccess,
} from '@energy-stacks/fleet/store';
import { useEffectOnce } from 'usehooks-ts';

export const USER_MANUAL_URL =
  'https://www.figma.com/proto/fz3oa5P4jwkBsLtihHSBG3/Working-file-%F0%9F%9A%A7?page-id=10775%3A26553&node-id=11303-80702&node-type=frame&viewport=634%2C361%2C0.04&t=pEWHZi2uw5nZgBem-1&scaling=contain&content-scaling=fixed&starting-point-node-id=10775%3A26554';

export const UserWelcomeDialog = () => {
  const isFirstTimeAccess = useAppSelector(
    (state) => state.userAccess.isFirstTimeAccess
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation('users');
  const {
    open,
    dialogProps: { isOpen, onClose },
  } = useDialog();

  useEffectOnce(() => {
    if (isFirstTimeAccess) {
      dispatch(markFirstTimeAccess());
      open();
    }
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: 604,
        },
      }}
    >
      <ESDialogContent sx={{ '&.MuiDialogContent-root': { paddingX: 6 } }}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box>
            <WelcomeIcon />
          </Box>
          <Typography
            variant="h1"
            marginY={6}
            // TODO: Configure typography variants through the theme
            sx={{
              color: 'grey.900',
              fontSize: '34px',
              lineHeight: '42px',
              fontWeight: 700,
            }}
          >
            {t('userWelcomeDialogTitle')}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            marginBottom={9}
            // TODO: Configure typography variants through the theme
            sx={{
              color: 'grey.600',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500,
            }}
          >
            {t('userWelcomeDialogText')}
          </Typography>
        </Stack>
      </ESDialogContent>
      <ESDialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <ESButton
          onClick={onClose}
          variant="outlined"
          sx={{
            '&.MuiButton-outlined': {
              borderColor: 'primary.main',
              '&:hover': {
                borderColor: 'primary.main',
              },
            },
          }}
        >
          {t('userWelcomeDialogSkipButtonText')}
        </ESButton>
        <Link
          href={USER_MANUAL_URL}
          rel="noopener noreferrer"
          target="_blank"
          sx={{
            color: 'grey.100',
            marginLeft: 6,
          }}
        >
          <ESButton
            sx={{ '&.MuiLoadingButton-root': { paddingX: 7 } }}
            endIcon={<IconExternalLink />}
          >
            {t('userWelcomeDialogOpenManualText')}
          </ESButton>
        </Link>
      </ESDialogActions>
    </Dialog>
  );
};
