import { useDrag, useDrop } from 'react-dnd';
import { Row } from '@tanstack/react-table';
import { DndRowsOptions, DroppableItem } from './DndRowsOptions';
import { ESTableBodyRow, ESTableBodyRowProps } from './ESTableBodyRow';
import { useEffect, CSSProperties, useMemo } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';

export interface ESTableDraggableRowProps<T>
  extends Omit<ESTableBodyRowProps, 'sx'> {
  dndRowsOptions: DndRowsOptions<T> & {
    onRowClick?: (event: React.MouseEvent, row: Row<T>) => void;
  };
  row: Row<T>;
  rowStyles?: CSSProperties;
  isLocalDrag?: boolean;
}

export const ESTableDraggableRow = <T,>({
  dndRowsOptions,
  row,
  rowStyles = {},
  isLocalDrag = false,
  ...rest
}: ESTableDraggableRowProps<T>) => {
  const canDrag = dndRowsOptions.canDrag?.(row) ?? true;
  const [, dragRef, previewRef] = useDrag(
    {
      item: () => ({
        id: dndRowsOptions.dragRowsSelectId?.(row) || '',
        index: row.index,
        row,
        selectedRowIds: dndRowsOptions.selectedItemIds,
      }),
      type: 'row',
      canDrag,
    },
    [dndRowsOptions.dragRowsSelectId?.(row), dndRowsOptions.selectedItemIds]
  );

  const [{ isOver, draggedItemIndex }, dropRef] = useDrop(
    {
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        draggedItemIndex: monitor.getItem()?.index,
      }),
      accept: dndRowsOptions.accept?.(row) || 'row',
      drop: (item: DroppableItem<T>) => {
        requestAnimationFrame(() => {
          dndRowsOptions.onDrop?.(
            item.selectedRowIds.length > 0
              ? item.selectedRowIds
              : [dndRowsOptions.dragRowsSelectId?.(item.row) || ''],
            dndRowsOptions.dragRowsSelectId?.(row) || ''
          );
        });
      },
    },
    [dndRowsOptions.dragRowsSelectId?.(row) || '', dndRowsOptions.onDrop]
  );

  useEffect(() => {
    previewRef(getEmptyImage());
  }, [previewRef]);

  const rowBorderStyles = useMemo(() => {
    if (draggedItemIndex && draggedItemIndex > row.index && isLocalDrag) {
      return {
        borderTop: '2px solid',
        borderTopColor: 'grey.400',
        transition: 'border-top 0.15s ease-in-out',
      };
    }
    return {
      borderBottom: '2px solid',
      borderBottomColor: 'grey.400',
      transition: 'border-bottom 0.15s ease-in-out',
    };
  }, [draggedItemIndex, isLocalDrag, row.index]);

  const isRowSelected = dndRowsOptions.selectedItemIds?.find(
    (itemId) => itemId === row.id
  );

  return (
    <ESTableBodyRow
      {...rest}
      onClick={(event) => {
        dndRowsOptions.onRowClick?.(event, row);
      }}
      sx={{
        '&.MuiTableRow-root': {
          cursor: canDrag ? 'grabbing' : 'not-allowed',
          userSelect: 'none',
          opacity: canDrag ? 1 : 0.5,
          backgroundColor: isRowSelected ? 'primary.light' : undefined,
          '&:hover': {
            backgroundColor: isRowSelected ? 'primary.light' : undefined,
          },
          ...rowStyles,
          '& > *': {
            '&.MuiTableCell-root': isOver
              ? {
                  boxSizing: 'border-box',
                  ...rowBorderStyles,
                }
              : {},
          },
        },
      }}
      ref={
        dndRowsOptions.dragRole.drag && dndRowsOptions.dragRole.drop
          ? (instance) => {
              dragRef(instance);
              dropRef(instance);
            }
          : dndRowsOptions.dragRole.drop
          ? (instance) => {
              dropRef(instance);
            }
          : dndRowsOptions.dragRole.drag
          ? (instance) => {
              dragRef(instance);
            }
          : undefined
      }
    />
  );
};
