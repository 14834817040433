import { Navigate, Route, Routes } from 'react-router-dom';

import { ESAppProvider } from '@energy-stacks/feature-app-provider';
import {
  AccessDeniedPage,
  ESOidcProvider,
  UserAccessGuard,
} from '@energy-stacks/user-access';

import {
  BusinessAccountDetailsPage,
  BusinessAccountsPage,
} from '@energy-stacks/fleet-is/feature-business-accounts';
import {
  CreateTourWizard,
  DeleteTour,
  TourPage,
} from '@energy-stacks/fleet/feature-tours';
import { FleetUserLogin } from '@energy-stacks/fleet/feature-users';
import { DeleteVehicle } from '@energy-stacks/fleet/feature-vehicles';
import { FleetLocationProvider } from '@energy-stacks/fleet/shared';
import {
  ESErrorBoundary,
  ESRoutes,
  LocationWithState,
  NotFound,
  useAppLocation,
} from '@energy-stacks/shared';
import { i18nextInitialization } from '../i18n/i18n';
import { oidcConfiguration } from '../oidcConfiguration';
import { AppAuth } from './AppAuth';
import {
  AddVehicle,
  EditVehicle,
  VehiclesPage,
} from '@energy-stacks/fleet-is/feature-vehicles';
import { DashboardPage } from '@energy-stacks/broker/feature-dashboard';
import {
  BrokerAddChargingStation,
  ChargingStationDetailsSlider,
  ChargingStationsPage,
  DeleteChargingStation,
  EditChargingStations,
  EditConfiguration,
} from '@energy-stacks/broker/feature-charging-stations';
import { ChargingSchedulePage } from '@energy-stacks/fleet-is/feature-charging-schedule';
import { CreateChargingScheduleWizard } from '@energy-stacks/fleet-is/feature-charging-schedule-create';
import {
  FleetIsLogoOverlay,
  FleetIsRoutes,
} from '@energy-stacks/fleet-is/shared';
import { ToursPage } from '@energy-stacks/fleet-is/feature-tours';

// NOTE: This is here just to use i18next..init({...})
// or else the bundler will ignore it.x
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const i18nextInit = i18nextInitialization;

export const App = () => {
  const location = useAppLocation();
  const background =
    location.state &&
    (location.state.background as LocationWithState<unknown> | null);

  return (
    <ESErrorBoundary>
      <ESAppProvider>
        <ESOidcProvider
          configuration={oidcConfiguration}
          LoadingComponent={FleetIsLogoOverlay}
        >
          <FleetLocationProvider>
            <FleetUserLogin />
            <UserAccessGuard />
            <Routes location={background || location}>
              <Route path={ESRoutes.Root} element={<AppAuth />}>
                <Route
                  index
                  element={<Navigate to={FleetIsRoutes.Dashboard} replace />}
                />
                <Route
                  path={FleetIsRoutes.CreateTour}
                  element={<CreateTourWizard />}
                />
                <Route
                  path={FleetIsRoutes.CreateChargingSchedule}
                  element={<CreateChargingScheduleWizard />}
                />
                <Route
                  path={FleetIsRoutes.Dashboard}
                  element={<DashboardPage />}
                />
                <Route path={FleetIsRoutes.Tours} element={<ToursPage />}>
                  <Route
                    path={FleetIsRoutes.DeleteTour}
                    element={<DeleteTour />}
                  />
                </Route>
                <Route
                  path={FleetIsRoutes.TourDetails}
                  element={<TourPage />}
                />
                <Route
                  path={FleetIsRoutes.ChargingStations}
                  element={<ChargingStationsPage />}
                >
                  <Route
                    path={FleetIsRoutes.AddChargingStation}
                    element={<BrokerAddChargingStation />}
                  />
                  <Route
                    path={FleetIsRoutes.DeleteChargingStation}
                    element={<DeleteChargingStation />}
                  />
                  <Route
                    path={FleetIsRoutes.EditChargingStationFromTable}
                    element={<EditChargingStations />}
                  />
                  <Route
                    path={`${FleetIsRoutes.ChargingStationDetails}/*`}
                    element={<ChargingStationDetailsSlider />}
                  >
                    <Route
                      path={`${FleetIsRoutes.ChargingStationDetailsGeneralTab}/${FleetIsRoutes.EditChargingStation}`}
                      element={<EditChargingStations />}
                    />
                    <Route
                      path={`${FleetIsRoutes.ChargingStationDetailsConfigurationTab}/${FleetIsRoutes.ChargingStationDetailsConfigurationTabEdit}`}
                      element={<EditConfiguration />}
                    />
                  </Route>
                </Route>
                <Route
                  path={FleetIsRoutes.ChargingSchedule}
                  element={<ChargingSchedulePage />}
                />
                <Route path={FleetIsRoutes.Vehicles} element={<VehiclesPage />}>
                  <Route
                    path={FleetIsRoutes.AddVehicle}
                    element={<AddVehicle />}
                  />
                  <Route
                    path={FleetIsRoutes.DeleteVehicle}
                    element={<DeleteVehicle />}
                  />
                  <Route
                    path={FleetIsRoutes.EditVehicle}
                    element={<EditVehicle />}
                  />
                </Route>
                {/* <Route path={FleetIsRoutes.Drivers} element={<DriversPage />} /> */}
                <Route
                  path={FleetIsRoutes.BusinessAccounts}
                  element={<BusinessAccountsPage />}
                />
                <Route
                  path={FleetIsRoutes.BusinessAccountDetails}
                  element={<BusinessAccountDetailsPage />}
                />
              </Route>
              <Route
                path={ESRoutes.NotFound}
                element={<NotFound navigateLink={ESRoutes.Root} />}
              />
              <Route
                path={ESRoutes.AccessDenied}
                element={
                  <AccessDeniedPage
                    LoadingComponent={() => <FleetIsLogoOverlay />}
                    userActive={true}
                    userHasRoleAssigned={true}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={ESRoutes.NotFound} replace />}
              />
            </Routes>
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.CreateTour}
                  element={<CreateTourWizard />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.CreateChargingSchedule}
                  element={<CreateChargingScheduleWizard />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.DeleteTour}
                  element={<DeleteTour />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.AddVehicle}
                  element={<AddVehicle />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.AddChargingStation}
                  element={<BrokerAddChargingStation />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.DeleteVehicle}
                  element={<DeleteVehicle />}
                />
              </Routes>
            )}
            {background && (
              <Routes>
                <Route
                  path={FleetIsRoutes.EditVehicle}
                  element={<EditVehicle />}
                />
              </Routes>
            )}
          </FleetLocationProvider>
        </ESOidcProvider>
      </ESAppProvider>
    </ESErrorBoundary>
  );
};
