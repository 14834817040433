import { appendZToDateString } from '@energy-stacks/shared';
import { TransitionDto } from '../transitionMetricsDto';
import { TransitionModel } from '../transitionModel';

export const transitionsNormalizer = (
  transitionsDto: TransitionDto[]
): TransitionModel[] => {
  return transitionsDto.map((transitionDto) => ({
    distance: transitionDto.distance,
    endDate: appendZToDateString(transitionDto.endDate),
    startDate: appendZToDateString(transitionDto.startDate),
    vehicleIsEmpty: transitionDto.vehicleIsEmpty,
    waitDuration: transitionDto.waitDuration,
    polyline: transitionDto.polyline,
  }));
};
