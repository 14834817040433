import {
  OptimizedTourModel,
  OptimizedTours,
  SkippedJob,
  editVirtualTourDetailsDataToPayload,
  useEditVirtualTourDetailsMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { Typography, Stack, Box } from '@mui/material';
import { Row } from '@tanstack/react-table';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { OptimizedToursTable } from '../OptimizedToursTable';
import { TourDetails, TourInfo } from '../tour-details/TourDetails';
import { IconChevronLeft } from '@tabler/icons-react';
import { TourDetailsMap } from '../tour-details-map/TourDetailsMap';
import {
  ESFullScreenLoadingIndicator,
  MoreOptionsMenu,
  usePromptDisplay,
} from '@energy-stacks/shared';
import { toursApiErrors } from '../toursApiErrors';
import { TourInfoBanner } from '../tour-details/TourInfoBanner';
import { TourDetailsViewModeButton } from '../tour-details/TourDetailsViewModeButton';
import { EditTourDetailsContextProvider } from '../edit-tour/EditTourDetailsContext';
import { ESMenuItem, useESSnackbar } from '@energy-stacks/core/ui';
import { TourDetailsEditMode } from '../edit-tour/TourDetailsEditMode';
import { TourDetailsEditModeHeader } from '../edit-tour/TourDetailsEditModeHeader';
import { SkippedJobsAlert } from './SkippedJobsAlert';
import { compareAsc } from 'date-fns';
import { useInvalidateJobPool } from '@energy-stacks/fleet/feature-jobs';
import { TourDetailsActions } from '../tour-details/TourDetailsActions';
import { TourJobsTableHeader } from '../tour-details/TourJobsTableHeader';
import { FALLBACK_TIMEZONE_ID } from '@energy-stacks/fleet/shared';

interface ReviewStepProps {
  optimizedToursResponse: OptimizedTourModel[];
  setOptimizedToursResponse?: Dispatch<
    SetStateAction<Omit<OptimizedTours, 'timeZoneId'>>
  >;
  selectedJobsCount: number;
  onToursSelected?: (tours: OptimizedTourModel[]) => void;
  enableRowSelection?: boolean;
  selectedTours?: OptimizedTourModel[];
  enableActions?: boolean;
  isEditTourMode?: boolean;
  setIsEditTourMode?: (isEditTourMode: boolean) => void;
  tourDetails: TourDetails | undefined;
  setTourDetails: (tourDetails: TourDetails | undefined) => void;
}

export type TourDetails = {
  tour: OptimizedTourModel;
  initialTour: OptimizedTourModel;
  index: number;
};

export const ReviewStep: React.FC<ReviewStepProps> = ({
  optimizedToursResponse,
  setOptimizedToursResponse,
  onToursSelected,
  enableRowSelection = true,
  selectedTours = [],
  enableActions = true,
  setIsEditTourMode,
  isEditTourMode,
  tourDetails,
  setTourDetails,
}) => {
  const { t } = useTranslation('tours');
  const [viewMode, setViewMode] = useState<'table' | 'map'>('table');
  const [recalculatedSkippedJobs, setRecalculatedSkippedJobs] = useState<
    SkippedJob[] | undefined
  >();
  const invalidateJobPool = useInvalidateJobPool();

  const toursWithSameVehicle = useMemo(() => {
    return (
      optimizedToursResponse
        // Only the tours with the same vehicle can violate rules
        .filter((optimizedTour) => {
          return optimizedTour.vehicleId === tourDetails?.tour.vehicleId;
        })
        // Since optimized tours table is sortable sorting again by startDate is needed
        // in case tours are re-ordered
        .sort((a, b) => {
          if (a.startDate === null || b.startDate === null) {
            return 0;
          }
          return compareAsc(new Date(a.startDate), new Date(b.startDate));
        })
    );
  }, [optimizedToursResponse, tourDetails?.tour.vehicleId]);

  // If the same vehicle is involved in the preceding or subsequent tour relative
  // to the one being edited, there's a chance of penalty rules violations.
  // Therefore, it's necessary to send jobs from both previous and next tour to the BE for penalty rules violations check.
  const tourDetialsIndex = useMemo(() => {
    return toursWithSameVehicle.findIndex(
      (tour) => tour.id === tourDetails?.tour.id
    );
  }, [tourDetails?.tour.id, toursWithSameVehicle]);
  const previousVirtualTour = tourDetails
    ? toursWithSameVehicle[tourDetialsIndex - 1]
    : undefined;
  const nextVirtualTour = tourDetails
    ? toursWithSameVehicle[tourDetialsIndex + 1]
    : undefined;

  const [editVirtualTourDetails, { isLoading: isEditingTour }] =
    useEditVirtualTourDetailsMutation();
  const { showSnackbar } = useESSnackbar();

  const handleRowClick = useCallback(
    (row: Row<OptimizedTourModel>) => {
      setTourDetails({
        tour: row.original,
        initialTour: row.original,
        index: row.index,
      });
    },
    [setTourDetails]
  );

  const deselectTour = useCallback(() => {
    setTourDetails(undefined);
    setRecalculatedSkippedJobs(undefined);
    setIsEditTourMode?.(false);
  }, [setIsEditTourMode, setTourDetails]);

  const handleRecalculateTour = useCallback(() => {
    if (!tourDetails) {
      return;
    }
    editVirtualTourDetails(
      editVirtualTourDetailsDataToPayload({
        tourDetails: tourDetails.tour,
        previousTourJobs: previousVirtualTour
          ? previousVirtualTour.tourJobs.jobs
          : undefined,
        nextTourJobs: nextVirtualTour
          ? nextVirtualTour.tourJobs.jobs
          : undefined,
      })
    )
      .unwrap()
      .then((response) => {
        // Now that tour is re-calculated it becomes the initial tour
        setTourDetails({
          index: tourDetails.index,
          tour: response.optimizedTour,
          initialTour: response.optimizedTour,
        });

        const updatedOptimizedTours = (optimizedToursResponse || []).map(
          (tour) => {
            if (tour.id === tourDetails.tour.id) {
              return response.optimizedTour;
            }

            return tour;
          }
        );

        setOptimizedToursResponse?.({
          tours: updatedOptimizedTours,
          skippedJobs: [],
        });
        // If old (edited) tour was selected previously in the list
        // we need to replace it with new one from the response so that it
        // actually get's created
        onToursSelected?.(
          selectedTours.map((tour) => {
            if (tour.vehicleId === response.optimizedTour.vehicleId) {
              return { ...response.optimizedTour };
            }

            return tour;
          })
        );

        if (response.skippedJobs.length) {
          invalidateJobPool();
        }

        setRecalculatedSkippedJobs(response.skippedJobs);
        setIsEditTourMode?.(false);
      })
      .catch((error) => {
        const errorCode = toursApiErrors[error.data?.errorCode];
        showSnackbar(
          'error',
          errorCode ? `toursApiErrors.${errorCode}` : undefined,
          'tours'
        );
      });
  }, [
    editVirtualTourDetails,
    nextVirtualTour,
    onToursSelected,
    optimizedToursResponse,
    previousVirtualTour,
    invalidateJobPool,
    selectedTours,
    setIsEditTourMode,
    setOptimizedToursResponse,
    setTourDetails,
    showSnackbar,
    tourDetails,
  ]);

  const tourInfo = useMemo(() => {
    if (!tourDetails) {
      return;
    }

    return {
      polyline: tourDetails.tour.polyline.points,
      distance: tourDetails.tour.distance,
      endDate: tourDetails.tour.endDate,
      tourJobs: tourDetails.tour.tourJobs,
      vehicleName: tourDetails.tour.vehicleName,
      startDate: tourDetails.tour.startDate,
      tourIndex: tourDetails.index,
      totalJobs: tourDetails.tour.totalJobs,
      emptyRunsMetrics: tourDetails.tour.emptyRunsMetrics,
      timeZoneId: tourDetails.tour.timeZoneId,
    } satisfies TourInfo;
  }, [tourDetails]);

  const handleDeleteTour = useCallback(() => {
    setOptimizedToursResponse?.((prev) => {
      return {
        tours: prev.tours.filter((t) => t.id !== tourDetails?.tour.id),
        skippedJobs: [],
        timeZoneId: tourDetails?.tour.timeZoneId ?? FALLBACK_TIMEZONE_ID,
      };
    });
    setTourDetails(undefined);
  }, [setOptimizedToursResponse, setTourDetails, tourDetails]);

  const showDeleteConfirmation = usePromptDisplay({
    shouldPrompt: true,
    promptMessage: t('deleteTourConfirmMessage'),
    onAction: handleDeleteTour,
  });

  return (
    <EditTourDetailsContextProvider
      config={{
        isEditTourModeActive: !!isEditTourMode,
        tourDetails,
        jobIds: optimizedToursResponse
          .map((tour) => tour.tourJobs.jobs.map((job) => job.jobId))
          .flat(),
        onCancelEditing: () => {
          setIsEditTourMode?.(false);
        },
        onTourDetailsChange: setTourDetails,
        nextVirtualTour,
        previousVirtualTour,
      }}
    >
      <Stack height="100%" gap={6}>
        {tourInfo ? (
          <>
            {isEditTourMode ? (
              <>
                <TourDetailsEditModeHeader
                  shouldDisableSave={
                    !tourInfo.tourJobs.jobs.length ||
                    tourInfo.tourJobs.jobs.some(
                      (job) =>
                        job.status === 'CANCELLED' || job.status === 'FAILED'
                    )
                  }
                  onRecalculateTour={handleRecalculateTour}
                />
                <TourDetailsEditMode
                  TourJobsTableHeader={
                    <TourJobsTableHeader
                      vehicleName={tourInfo.vehicleName}
                      vehicleIndex={tourInfo.tourIndex}
                      startDate={tourInfo.startDate}
                    />
                  }
                />
              </>
            ) : (
              <>
                <SkippedJobsAlert
                  totalJobsCount={
                    recalculatedSkippedJobs
                      ? recalculatedSkippedJobs.length +
                        tourInfo.tourJobs.jobs.length
                      : 0
                  }
                  skippedJobs={recalculatedSkippedJobs ?? []}
                  onDismiss={() => setRecalculatedSkippedJobs([])}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'space-between'}
                >
                  <Stack direction="row" alignItems="center" gap={4}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                      }}
                      bgcolor="primary.main"
                      width="30px"
                      height="30px"
                      borderRadius={100}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      left="-15px"
                      onClick={deselectTour}
                    >
                      <IconChevronLeft color="white" />
                    </Box>
                    <Typography variant="h3" color="grey.900">
                      {t('tourDetails')}
                    </Typography>
                  </Stack>
                  <TourDetailsActions
                    tourDetailsViewMode={viewMode}
                    onTourDetailsViewModeChange={setViewMode}
                  />
                </Stack>
                <TourInfoBanner
                  tourInfo={tourInfo}
                  Actions={
                    enableActions
                      ? () => (
                          <MoreOptionsMenu>
                            <ESMenuItem
                              onClick={() => setIsEditTourMode?.(true)}
                            >
                              {t('editTourCTA')}
                            </ESMenuItem>
                            <ESMenuItem onClick={showDeleteConfirmation}>
                              {t('deleteTourCTA')}
                            </ESMenuItem>
                          </MoreOptionsMenu>
                        )
                      : undefined
                  }
                />
                <TourDetails viewMode={viewMode} tourInfo={tourInfo} />
              </>
            )}
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" color="dark.900">
                {t('reviewTours')}
              </Typography>
              <TourDetailsViewModeButton onViewModeChange={setViewMode} />
            </Stack>
            {viewMode === 'table' ? (
              <OptimizedToursTable
                tours={optimizedToursResponse ?? []}
                onRowClick={handleRowClick}
                enableRowSelection={enableRowSelection}
                defaultRowSelection={
                  enableRowSelection
                    ? (item) => {
                        return Boolean(
                          selectedTours.find(
                            (tour) => item.vehicleId === tour.vehicleId
                          )
                        );
                      }
                    : undefined
                }
                onSelectionChange={(toursRowSelection) => {
                  const parsedTours = Object.keys(toursRowSelection).map(
                    (tourString) => JSON.parse(tourString)
                  );
                  onToursSelected?.(parsedTours);
                }}
              />
            ) : (
              <TourDetailsMap
                tours={optimizedToursResponse.map((tour, index) => ({
                  polyline: tour.polyline.points,
                  distance: tour.distance,
                  endDate: tour.endDate,
                  tourJobs: tour.tourJobs,
                  vehicleName: tour.vehicleName,
                  startDate: tour.startDate,
                  tourIndex: index,
                  totalJobs: tour.totalJobs,
                }))}
              />
            )}
          </>
        )}
      </Stack>
      {isEditingTour ? (
        <ESFullScreenLoadingIndicator text={t('editTourDetailsLoadingText')} />
      ) : null}
    </EditTourDetailsContextProvider>
  );
};
