import {
  DndRowsOptions,
  ESDnDVirtualizedTable,
  ESTableWrapper,
  PortalTarget,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDateTimeCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import {
  NoTableData,
  TableColumnSelect,
  TableRowsCount,
} from '@energy-stacks/shared';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TourJobIdCell } from '../tour-details/TourJobIdCell';
import { EditingTourJob } from './editingTourJobModel';
import { JobStatusChip } from '@energy-stacks/fleet/shared';
import { isWithinInterval } from 'date-fns';
import { useEditTourDetailsContext } from './useEditTourDetailsContext';

type TourJobsDndTableProps = {
  tourJobs: EditingTourJob[];
  dndRowsOptions: DndRowsOptions<EditingTourJob>;
};

const ROW_HEIGHT = 65;

const jobSideActivities = ['BREAK'];

const JobTypeGuard: React.FC<{
  jobType: EditingTourJob['jobType'] | 'BREAK';
  children: React.ReactNode;
}> = ({ jobType, children }) => {
  if (jobSideActivities.includes(jobType)) {
    return null;
  }

  return <>{children}</>;
};

export const TourJobsDndTable: React.FC<TourJobsDndTableProps> = ({
  tourJobs,
  dndRowsOptions,
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<EditingTourJob>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();
  const theme = useTheme();
  const { isTourChanged } = useEditTourDetailsContext();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <TourJobIdCell
          jobType={info.row.original.jobType}
          jobId={removePlantIdPrefix(info.getValue())}
          contaminationViolation={info.row.original.contaminationViolation}
        />
      ),
      size: 100,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.origin?.id, {
      id: 'origin',
      header: () => t('origin'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row }) => (
        <JobLocationCell
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="origin"
          location={row.original.origin}
        />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.destination?.id, {
      id: 'destination',
      header: () => t('destination'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row }) => (
        <JobLocationCell
          location={row.original.destination}
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="destination"
        />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => t('status'),
      footer: (props) => props.column.id,
      size: 130,
      enableSorting: false,
      cell: (info) => <JobStatusChip status={info.getValue()} />,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('product.name', {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 180,
      enableSorting: false,
      cell: ({ row }) => (
        <JobTypeGuard jobType={row.original.jobType}>
          <Stack direction="column" alignItems="start">
            <Typography variant="inherit">
              {row.original.product ? row.original.product.name : '---'}
            </Typography>
          </Stack>
        </JobTypeGuard>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('startTime', {
      header: () => t('plannedStart'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const {
          startTime,
          datePickUpFrom,
          datePickUpTill,
          confirmedStartTime,
          forecastedStartTime,
        } = row.original;
        return (
          <JobDateTimeCell
            showWarning={
              !!datePickUpFrom &&
              !!datePickUpTill &&
              !isWithinInterval(
                new Date(
                  confirmedStartTime
                    ? confirmedStartTime
                    : forecastedStartTime
                    ? forecastedStartTime
                    : startTime
                ),
                {
                  start: new Date(datePickUpFrom),
                  end: new Date(datePickUpTill),
                }
              ) &&
              !isTourChanged
            }
            date={startTime}
          />
        );
      },
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('endTime', {
      header: () => t('plannedEnd'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const {
          endTime,
          dateDropOffFrom,
          dateDropOffTill,
          confirmedEndTime,
          forecastedEndTime,
        } = row.original;
        return (
          <JobDateTimeCell
            showWarning={
              !!dateDropOffFrom &&
              !!dateDropOffTill &&
              !isWithinInterval(
                new Date(
                  confirmedEndTime
                    ? confirmedEndTime
                    : forecastedEndTime
                    ? forecastedEndTime
                    : endTime
                ),
                {
                  start: new Date(dateDropOffFrom),
                  end: new Date(dateDropOffTill),
                }
              ) &&
              !isTourChanged
            }
            date={endTime}
          />
        );
      },
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('datePickUpFrom', {
      header: () => t('pickupFrom'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDateTimeCell date={info.getValue()} />,
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('datePickUpTill', {
      header: () => t('pickupTill'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDateTimeCell date={info.getValue()} />,
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('dateDropOffFrom', {
      header: () => t('dropOffFrom'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDateTimeCell date={info.getValue()} />,
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('dateDropOffTill', {
      header: () => t('dropOffTill'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDateTimeCell date={info.getValue()} />,
      enableSorting: false,
      size: 180,
    }),
  ];

  const { instance, rows } = useESTableBasic(tourJobs, columns, {
    tableId: 'tour-jobs-dnd-table',
    fitRowHeight: ROW_HEIGHT,
    manualPagination: true,
    enableColumnResizing: true,
    getRowId: (row) => row.jobId,
  });

  return (
    <ESTableWrapper>
      <PortalTarget targetId="tourJobsDndTableHeaderRef">
        <Box sx={{ marginLeft: 'auto' }}>
          <TableColumnSelect instance={instance} />
        </Box>
      </PortalTarget>
      <ESDnDVirtualizedTable
        instance={instance}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        dndRowsOptions={dndRowsOptions}
        applyRowStyles={(row) => {
          if (row.original.status === 'CANCELLED') {
            return {
              backgroundColor: theme.palette.warning.light,
              '&:hover': {
                backgroundColor: theme.palette.warning.light,
              },
            };
          }
        }}
      />
      {rows.length === 0 ? <NoTableData message={t('thereAreNoJobs')} /> : null}
      <TableRowsCount
        instance={instance}
        totalCountLabel={t('totalRowsCount')}
      />
    </ESTableWrapper>
  );
};
