import { IconButton, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { ChargingStationsActionsContext } from '../ChargingStationsActionsContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { IconPencil } from '@tabler/icons-react';
import { ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { ClearAuthorizationCacheDialog } from './clear-authorization-cache/ClearAuthorizationCacheDialog';
import { BrokerSoftReset } from './BrokerSoftReset';
import { BrokerHardReset } from './BrokerHardReset';
import { TriggerChargingStationMessageDialog } from './trigger-message/TriggerChargingStationMessageDialog';
import { ChangeChargingStationAvailabilityStatusDialog } from './change-availability/ChangeChargingStationAvailabilityStatusDialog';
import { ChargingStationDetailsModel } from '@energy-stacks/broker/feature-charging-stations-data';

type ChargingStationDialog =
  | 'softReset'
  | 'hardReset'
  | 'authorization'
  | 'triggerMessage'
  | 'availability';

interface ChargingStationDetailsActionsProps {
  chargingStationDetails?: ChargingStationDetailsModel;
}

export const ChargingStationDetailsActions = ({
  chargingStationDetails,
}: ChargingStationDetailsActionsProps) => {
  const [openedDialog, setOpenedDialog] = useState<ChargingStationDialog>();
  const chargingSationsActions = useContext(ChargingStationsActionsContext);
  const { t } = useTranslation('chargingStations');
  const navigate = useNavigate();

  return (
    chargingStationDetails && (
      <Stack gap={4} direction="row" alignItems="center" ml={4}>
        {chargingSationsActions.includes('edit') ? (
          <IconButton
            data-testid="editChargingStationPencilButton"
            onClick={() =>
              navigate(
                `${BrokerRoutes.ChargingStationDetailsGeneralTab}/${BrokerRoutes.EditChargingStation}`
              )
            }
          >
            <IconPencil />
          </IconButton>
        ) : null}
        <ESMenu testId={'chargingStationDetails'} disableMenu={!!openedDialog}>
          <ESMenuItem
            disabled={chargingStationDetails.online === 'offline'}
            testId={'clearAuthorizationCacheMenuItem'}
            onClick={() => setOpenedDialog('authorization')}
          >
            {t('clearAuthorizationCache')}
          </ESMenuItem>

          <ESMenuItem
            disabled={chargingStationDetails.online === 'offline'}
            testId={'softResetMenuItem'}
            onClick={() => setOpenedDialog('softReset')}
          >
            {t('softReset')}
          </ESMenuItem>
          <ESMenuItem
            disabled={chargingStationDetails.online === 'offline'}
            testId={'hardResetMenuItem'}
            onClick={() => setOpenedDialog('hardReset')}
          >
            {t('hardReset')}
          </ESMenuItem>
          <ESMenuItem
            disabled={chargingStationDetails.online === 'offline'}
            testId={'triggerMessageMenuItem'}
            onClick={() => setOpenedDialog('triggerMessage')}
          >
            {t('remoteTriggerMessage')}
          </ESMenuItem>
          <ESMenuItem
            disabled={chargingStationDetails.online === 'offline'}
            testId={'stationAvailabilityMenuItem'}
            onClick={() => setOpenedDialog('availability')}
          >
            {t('stationAvailability')}
          </ESMenuItem>
        </ESMenu>

        <ClearAuthorizationCacheDialog
          identityKey={chargingStationDetails.identityKey}
          isOpen={openedDialog === 'authorization'}
          onClose={() => setOpenedDialog(undefined)}
        />

        <BrokerSoftReset
          isOpen={openedDialog === 'softReset'}
          onClose={() => setOpenedDialog(undefined)}
        />

        <BrokerHardReset
          isOpen={openedDialog === 'hardReset'}
          onClose={() => setOpenedDialog(undefined)}
        />

        <TriggerChargingStationMessageDialog
          identityKey={chargingStationDetails.identityKey}
          isOpen={openedDialog === 'triggerMessage'}
          onClose={() => setOpenedDialog(undefined)}
        />

        <ChangeChargingStationAvailabilityStatusDialog
          identityKey={chargingStationDetails.identityKey}
          currentStatus={chargingStationDetails.csStatus}
          isOpen={openedDialog === 'availability'}
          onClose={() => setOpenedDialog(undefined)}
        />
      </Stack>
    )
  );
};
