import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTableFilters,
  ESTableWrapper,
  useElementScrollRestoration,
  ESTablePagination,
  ESTableBody,
  ESTableHead,
  ESTable,
  useESTableServer,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@energy-stacks/core/ui';
import {
  ClearFiltersButton,
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnFilter,
  TableColumnSelect,
  TableDateRangeFilter,
  TableRowsCount,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import {
  ProductTypeChip,
  ServerSideTableProps,
} from '@energy-stacks/fleet/shared';
import { isEqual } from 'date-fns';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  JobModel,
  JobPageModel,
  jobStatusFilterOptions,
  jobTypes,
} from '@energy-stacks/fleet/feature-jobs-data';
import { JobTypeIcon } from './JobTypeIcon';
import { JobQuantityCell } from './JobQuantityCell';
import { JobLocationCell } from './JobLocationCell';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import { useMemo } from 'react';
import { JobDateTimeCell } from './JobDateTimeCell';
import { VehicleLogo } from '@energy-stacks/fleet/feature-vehicles';
import { JobsTableJobStatusChip } from './JobsTableJobStatusChip';

interface JobsTableProps extends ServerSideTableProps<JobModel> {
  tableId?: string;
  jobs: JobPageModel;
  enableColumnSelection?: boolean;
  defaultDateRange?: DateRange | undefined;
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
  scrollRestorationId?: string;
  excludeStatusFilter?: boolean;
  selectedRows?: RowSelectionState;
}

export const JobsTable: React.FC<JobsTableProps> = ({
  tableId = 'jobs',
  jobs,
  enableRowSelection,
  defaultRowSelection,
  selectedRows,
  onSelectionChange,
  enableColumnSelection = false,
  defaultDateRange,
  onDateRangeChange,
  hiddenColumnsIds = [],
  scrollRestorationId,
  onPaginationChange,
  pagination,
  sorting,
  onSortingChange,
  isFetching: isFetchingJobs,
  search,
  setSearch,
  tableColumnFilters,
  setFilters,
  excludeStatusFilter,
}) => {
  const [t] = useTranslation('jobs');
  const [tFleetShared] = useTranslation('fleetShared');
  const [tShared] = useTranslation('shared');
  const columnHelper = createColumnHelper<JobModel>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
        id: 'jobId',
        sortingFn: 'alphanumeric',
        header: () => t('jobId'),
        footer: (props) => props.column.id,
        cell: (info) => (
          <Typography>{removePlantIdPrefix(info.getValue())}</Typography>
        ),
        enableGlobalFilter: true,
        size: 120,
      }),
      columnHelper.accessor(
        (row) =>
          `${removePlantIdPrefix(row.supplier.supplierId)} ${
            row.supplier.supplierName
          }`,
        {
          id: 'supplier',
          header: () => t('supplier'),
          footer: (props) => props.column.id,
          size: 200,
          cell: ({ row }) => {
            return (
              <Stack direction="column">
                <Typography variant="inherit">
                  {removePlantIdPrefix(row.original.supplier.supplierId)}
                </Typography>
                <Typography variant="inherit">
                  {row.original.supplier.supplierName}
                </Typography>
              </Stack>
            );
          },
        }
      ),
      columnHelper.accessor((row) => row.origin?.id, {
        id: 'origin',
        header: () => t('origin'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="origin"
            location={row.original.origin}
            supplierName={row.original.supplier.supplierName}
          />
        ),
      }),
      columnHelper.accessor((row) => row.destination?.id, {
        id: 'destination',
        header: () => t('destination'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="destination"
            location={row.original.destination}
            supplierName={row.original.supplier.supplierName}
          />
        ),
      }),
      columnHelper.accessor((row) => row.jobType, {
        id: 'jobType',
        header: () => t('quantity'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobQuantityCell
            jobType={row.original.jobType}
            quantity={row.original.product.quantity}
          />
        ),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.product?.name, {
        id: 'product',
        header: () => t('productType'),
        footer: (props) => props.column.id,
        size: 125,
        cell: ({ row }) => (
          <ProductTypeChip productName={row.original.product.name} />
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('startDate', {
        header: () => t('plannedStart'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.startDate}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('endDate', {
        header: () => t('plannedEnd'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.endDate}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('datePickUpFrom', {
        header: () => t('pickupFrom'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.datePickUpFrom}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('datePickUpTill', {
        header: () => t('pickupTill'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.datePickUpTill}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('dateDropOffFrom', {
        header: () => t('dropOffFrom'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.dateDropOffFrom}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('dateDropOffTill', {
        header: () => t('dropOffTill'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDateTimeCell
            date={row.original.dateDropOffTill}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        enableSorting: false,
        size: 180,
      }),
      columnHelper.accessor('status', {
        header: () => t('status'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobsTableJobStatusChip
            status={row.original.status}
            tourId={row.original.tourId}
          />
        ),
        size: 120,
        enableSorting: false,
      }),
      columnHelper.accessor('vehicle', {
        header: () => t('vehicle'),
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          const { vehicle } = row.original;
          return vehicle ? (
            <Stack direction="row" gap={2} alignItems="center">
              <VehicleLogo />
              <Typography variant="inherit">{vehicle.vehicleName}</Typography>
            </Stack>
          ) : null;
        },
        size: 200,
        enableSorting: false,
      }),
    ],
    [columnHelper, removePlantIdPrefix, t]
  );

  const { instance, rows, handleSelectRow } = useESTableServer(
    jobs.jobs,
    columns,
    {
      tableId,
      state: {
        pagination,
        sorting,
        columnFilters: tableColumnFilters,
        globalFilter: search,
        rowSelection: enableRowSelection ? selectedRows : {},
      },
      onSortingChange,
      enableRowSelection,
      onSelectionChange,
      onPaginationChange,
      defaultRowSelection,
      getRowId: (row) => row.jobId,
      onColumnFiltersChange: setFilters,
      hiddenColumnsIds,
      enableColumnResizing: true,
    }
  );

  const scrollCache = useElementScrollRestoration(
    scrollRestorationId
      ? {
          id: scrollRestorationId,
        }
      : undefined
  );

  const dateRange = instance.getColumn('startDate')?.getFilterValue() as
    | DateRange
    | undefined;

  return (
    <>
      <ESTableFilters>
        <ESTooltip title={t('searchJobsPlaceholder')}>
          <TableSearchField
            placeholder={t('searchJobsPlaceholder')}
            value={search}
            onChange={setSearch}
            tableInstance={instance}
          />
        </ESTooltip>
        <TableDateRangeFilter
          testId="jobsTable"
          title={tShared('timeRange')}
          weekStartsOn={1}
          selectablePassive
          isActive={dateRange !== undefined}
          dateRange={dateRange}
          defaultDateRange={defaultDateRange}
          onDateRangeChange={(dateRange) => {
            // Sometimes state needs to be lifted up. e.g. in wizard we use the value in other steps as well
            onDateRangeChange?.(dateRange);
          }}
          onDateRangeCleared={() =>
            instance.getColumn('startDate')?.setFilterValue(defaultDateRange)
          }
          column={instance.getColumn('startDate')}
        />
        {!excludeStatusFilter ? (
          <TableColumnFilter
            column={instance.getColumn('status')}
            columnLabel={t('status')}
            options={jobStatusFilterOptions.map((v) => ({
              label: tFleetShared(`jobStatuses.${v}`),
              value: v,
            }))}
            isClearable
          />
        ) : null}
        <TableColumnFilter
          column={instance.getColumn('jobType')}
          columnLabel={t('jobType')}
          options={jobTypes.map((v) => ({
            label: t(`jobTypes.${v}`),
            value: v,
            Icon: <JobTypeIcon jobType={v} />,
          }))}
        />
        <ClearFiltersButton
          tableInstance={instance}
          ignoreColumnIds={
            dateRange?.startDate &&
            dateRange?.endDate &&
            defaultDateRange?.startDate &&
            defaultDateRange?.endDate &&
            isEqual(dateRange.startDate, defaultDateRange.startDate) &&
            isEqual(dateRange.endDate, defaultDateRange.endDate)
              ? ['startDate', ...(excludeStatusFilter ? ['status'] : [])]
              : excludeStatusFilter
              ? ['status']
              : []
          }
          clearFilters={() => {
            instance.resetColumnFilters(); // Reset filters to default values
            instance.setColumnFilters([
              { id: 'startDate', value: defaultDateRange },
            ]);
            // Sometimes state needs to be lifted up. e.g. in wizard we use the value in other steps as well
            onDateRangeChange?.(defaultDateRange);
          }}
        />
        {enableColumnSelection ? (
          <Box sx={{ marginLeft: 'auto' }}>
            <TableColumnSelect
              instance={instance}
              hiddenColumnsIds={hiddenColumnsIds}
            />
          </Box>
        ) : null}
      </ESTableFilters>
      <ESTableWrapper>
        <ESTable
          instance={instance}
          scrollRestorationId={scrollRestorationId}
          initialScrollTop={scrollCache.scrollY}
        >
          <ESTableHead showProgress={isFetchingJobs} instance={instance} />
          <ESTableBody
            rows={rows}
            table={instance}
            onRowClick={handleSelectRow}
            rowSelection={instance.getSelectedRowModel()}
          />
        </ESTable>

        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
          isTablePaginated
        />
        <ESTablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          instance={instance}
          count={jobs?.totalElements || rows.length}
        />
      </ESTableWrapper>
    </>
  );
};
