import {
  DndRowsOptions,
  ESDnDVirtualizedTable,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDateTimeCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import {
  ClearFiltersButton,
  ESTooltip,
  NoTableData,
  TableColumnFilter,
  TableColumnSelect,
  TableRowsCount,
  TableSearchField,
} from '@energy-stacks/shared';
import { Box } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { JobDurationCell } from '../tour-details/JobDurationCell';
import { TourDndTableHeader } from '../tour-details/TourDndTableHeader';
import {
  TourDetailsViewMode,
  TourDetailsViewModeButton,
} from '../tour-details/TourDetailsViewModeButton';
import { TourDetailsMap } from '../tour-details-map/TourDetailsMap';
import { useEditTourDetailsContext } from './useEditTourDetailsContext';
import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { useState } from 'react';
import { ProductTypeChip } from '@energy-stacks/fleet/shared';

interface JobsPoolDndTableProps {
  jobPool: JobModel[];
  addToTour: (job: OptimizedTourJob) => void;
  dndRowsOptions: DndRowsOptions<JobModel>;
}

const ROW_HEIGHT = 90;
const productTypeFilterValues = ['raw', 'degassed'];

export const JobsPoolDndTable: React.FC<JobsPoolDndTableProps> = ({
  jobPool,
  addToTour,
  dndRowsOptions,
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<JobModel>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();
  const [viewMode, setViewMode] = useState<TourDetailsViewMode>('table');
  const { tourDetails } = useEditTourDetailsContext();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      sortingFn: 'alphanumeric',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => removePlantIdPrefix(info.getValue()),
      size: 100,
    }),
    columnHelper.accessor(
      (row) =>
        `${row.origin?.id} ${row.supplier.supplierId} ${row.supplier.supplierName} ${row.origin?.street}, ${row.origin?.city}`,
      {
        id: 'origin',
        header: () => t('origin'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            jobType={row.original.jobType}
            locationType="origin"
            supplierName={row.original.supplier.supplierName}
            location={row.original.origin}
          />
        ),
        enableGlobalFilter: true,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row.destination?.id} ${row.supplier.supplierId} ${row.supplier.supplierName} ${row.destination?.street}, ${row.destination?.city}`,
      {
        id: 'destination',
        header: () => t('destination'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            location={row.original.destination}
            jobType={row.original.jobType}
            locationType="destination"
            supplierName={row.original.supplier.supplierName}
          />
        ),
        enableGlobalFilter: true,
      }
    ),
    columnHelper.accessor((row) => row.product?.name, {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 125,
      cell: ({ row }) => (
        <ProductTypeChip productName={row.original.product.name} />
      ),
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('estimatedDuration.timeMinutes', {
      header: () => t('duration'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDurationCell duration={row.original.estimatedDuration} />
      ),
    }),
    columnHelper.accessor('datePickUpFrom', {
      header: () => t('pickupFrom'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.datePickUpFrom}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      size: 180,
    }),
    columnHelper.accessor('datePickUpTill', {
      header: () => t('pickupTill'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.datePickUpTill}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      size: 180,
    }),
    columnHelper.accessor('dateDropOffFrom', {
      header: () => t('dropOffFrom'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.dateDropOffFrom}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      size: 180,
    }),
    columnHelper.accessor('dateDropOffTill', {
      header: () => t('dropOffTill'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.dateDropOffTill}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      size: 180,
    }),
  ];

  const { instance, rows, globalFilter, onGlobalFilterChange } =
    useESTableBasic(jobPool, columns, {
      tableId: 'jobs-pool-dnd-table',
      fitRowHeight: ROW_HEIGHT,
      getRowId: (row) => row.jobId,
      manualPagination: true,
      enableColumnResizing: true,
    });

  return (
    <>
      <TourDndTableHeader>
        <ESTooltip title={t('searchJobsPoolPlaceholder')}>
          <TableSearchField
            placeholder={t('searchJobsPoolPlaceholder')}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </ESTooltip>
        <TableColumnFilter
          column={instance.getColumn('product')}
          columnLabel={t('productType')}
          options={productTypeFilterValues.map((v) => ({
            label: t(`productTypes.${v}`),
            value: v,
          }))}
        />
        <ClearFiltersButton tableInstance={instance} />

        <Box sx={{ marginLeft: 'auto' }}>
          {viewMode === 'table' && <TableColumnSelect instance={instance} />}
          <TourDetailsViewModeButton onViewModeChange={setViewMode} />
        </Box>
      </TourDndTableHeader>
      <ESTableWrapper>
        <ESDnDVirtualizedTable
          instance={instance}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          dndRowsOptions={dndRowsOptions}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        {viewMode === 'table' && (
          <TableRowsCount
            instance={instance}
            totalCountLabel={t('totalRowsCount')}
          />
        )}
        {viewMode === 'map' && tourDetails && (
          <TourDetailsMap
            tours={[
              {
                distance: tourDetails.tour.distance,
                endDate: tourDetails.tour.endDate,
                polyline: tourDetails.tour.polyline.points,
                startDate: tourDetails.tour.startDate,
                totalJobs: tourDetails.tour.totalJobs,
                //NOTE: Always a single tour since we are in edit mode
                tourIndex: 0,
                tourJobs: tourDetails.tour.tourJobs,
                vehicleName: tourDetails.tour.vehicleName,
                emptyRunsMetrics: tourDetails.tour.emptyRunsMetrics,
                tourStatus: tourDetails.tour.status,
              },
            ]}
            // NOTE: Edit Mode
            onAddToTour={(job) => addToTour(job)}
            jobPool={rows.map((row) => ({
              ...row.original,
              confirmedEndTime: null,
              confirmedStartTime: null,
              startTime: row.original.earliestStartDate,
              endTime: row.original.dueDate,
              duration: 0,
              contaminationViolation: null,
              jobType: row.original.jobType ?? 'INBOUND',
              visitOrder: -1,
            }))}
          />
        )}
      </ESTableWrapper>
    </>
  );
};
