import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { formatDate } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { JobTimeIcon } from './JobTimeIcon';

export const JobDateTimeCell = ({
  date,
  showWarning = false,
}: {
  date: JobModel['startDate'] | null | undefined;
  showWarning?: boolean;
}) => {
  if (!date) {
    return <Typography>---</Typography>;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <JobTimeIcon showWarning={showWarning} />
      <Stack gap={1} alignItems="start">
        <Typography fontWeight={500} color="grey.900">
          {formatDate(date, 'h:mm a').toLowerCase()}
        </Typography>
        <Typography>{formatDate(date, 'dd/MM/yyyy')}</Typography>
      </Stack>
    </Stack>
  );
};
