import {
  DEFAULT_PAGINATION_CONFIG,
  DEFAULT_SORT_CONFIG,
  SortingOrderModel,
} from '@energy-stacks/core/ui';
import {
  toPayloadDateBroker,
  useChargingStationIdentityKey,
} from '@energy-stacks/shared';
import {
  useGetOcppNetworkLogsQuery,
  CommunicationDirectionModel,
  EventTypeModel,
} from '@energy-stacks/broker/feature-ocpp-network-logs-data';

interface GetOcppNetworkLogsConfig {
  filterDate: {
    timeFrom?: Date;
    timeTo?: Date;
  };
  pagination: {
    page: number;
    size: number;
  };
  communicationDirection?: CommunicationDirectionModel;
  eventType?: EventTypeModel;
  sort: SortingOrderModel;
  serviceInstance?: string[];
}

export const useOcppNetworkLogs = (config?: GetOcppNetworkLogsConfig) => {
  const identityKey = useChargingStationIdentityKey();

  const {
    data: ocppNetworkLogs,
    isSuccess,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useGetOcppNetworkLogsQuery(
    {
      identityKey: identityKey,
      timeFrom: config?.filterDate.timeFrom
        ? toPayloadDateBroker(config?.filterDate.timeFrom)
        : undefined,
      timeTo: config?.filterDate.timeTo
        ? toPayloadDateBroker(config?.filterDate.timeTo)
        : undefined,
      webSocketInteraction: config?.communicationDirection,
      eventType: config?.eventType,
      page: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
      size: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
      sort: { order: config?.sort.order ?? DEFAULT_SORT_CONFIG.order },
    },
    {
      skip: !identityKey,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    ocppNetworksLogs: ocppNetworkLogs,
    isSuccess,
    isError,
    isLoading,
    refetch,
    isFetching,
  };
};
