import {
  ESPopover,
  ESTableBasicConfig,
  ESTableFilters,
  ESTableWrapper,
  ESVirtualizedTable,
  useESTableBasic,
  useElementScrollRestoration,
} from '@energy-stacks/core/ui';
import {
  TourModel,
  tourStatuses,
} from '@energy-stacks/fleet/feature-tours-data';
import {
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnFilter,
  formatDate,
  ClearFiltersButton,
  TableColumnSelect,
  TableRowsCount,
  TableDateRangeFilter,
} from '@energy-stacks/shared';
import { Typography, Box, CircularProgress, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TourStatusChip } from './TourStatusChip';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  differenceInMinutes,
  differenceInSeconds,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import { ToursDurationCell } from './shared/ToursDurationCell';
import { sortByQuantity } from './shared/sortByQuantity';
import { ToursQuantityCell } from './shared/ToursQuantityCell';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FleetRoutes,
  TimeWindowWarningPopover,
} from '@energy-stacks/fleet/shared';
import {
  ToursTableVehicleFilter,
  VehicleLogo,
} from '@energy-stacks/fleet/feature-vehicles';
import { TourIdCell } from './TourIdCell';
import { ContaminationGlobalAlert } from './create-tour-steps/ContaminationGlobalAlert';
import { ConfirmedDuration } from './shared/ConfirmedDuration';
import { TourDurationCellEmptyRuns } from './shared/TourDurationCellEmptyRuns';
import { ConfirmedTime } from './shared/ConfirmedTime';
import { ToursTableMoreOptionsMenu } from './ToursTableMoreOptionsMenu';
import { ForecastedTime } from './shared/ForecastedTime';
import { TourContaminationWarning } from './shared/TourContaminationWarning';
import { IconClockExclamation } from '@tabler/icons-react';

const TABLE_SCROLL_RESTORATION_ID = 'toursTable';

interface ToursTableProps extends ESTableBasicConfig<TourModel> {
  tours: TourModel[];
  enableColumnSelection?: boolean;
  isFilterable?: boolean;
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
  dateRangeFilter?: DateRange;
}

const hasTimeWindowWarning = (
  confirmedStartDate: TourModel['confirmedStartDate'],
  startDate: TourModel['startDate'],
  confirmedEndDate: TourModel['confirmedEndDate'],
  endDate: TourModel['endDate']
) => {
  if (
    confirmedStartDate &&
    Math.abs(
      differenceInMinutes(new Date(confirmedStartDate), new Date(startDate))
    ) > 15
  ) {
    return true;
  } else if (
    confirmedEndDate &&
    Math.abs(
      differenceInMinutes(new Date(confirmedEndDate), new Date(endDate))
    ) > 15
  ) {
    return true;
  }

  return false;
};

export const ToursTable: FC<ToursTableProps> = ({
  tableId = 'tours',
  tours,
  enableColumnSelection,
  isFilterable = true,
  onDateRangeChange,
  dateRangeFilter,
}) => {
  const [t] = useTranslation('tours');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TourModel>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { palette } = useTheme();

  const sortByProgress = useCallback(
    (rowA: Row<TourModel>, rowB: Row<TourModel>) => {
      const progressA =
        (rowA.original.jobsCountPerStatus.DONE * 100) / rowA.original.totalJobs;
      const progressB =
        (rowB.original.jobsCountPerStatus.DONE * 100) / rowB.original.totalJobs;
      return progressA - progressB;
    },
    []
  );

  const columns = [
    columnHelper.accessor('vehicle.name', {
      id: 'vehicle',
      header: () => t('vehicleColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { brandId, name } = row.original.vehicle;
        const { confirmedStartDate, confirmedEndDate, endDate, startDate } =
          row.original;

        return (
          <Stack direction="row" gap={2} alignItems="center">
            <VehicleLogo brandId={brandId} />
            <Typography variant="inherit">{name}</Typography>
            <Stack direction="row" gap={1.5}>
              {hasTimeWindowWarning(
                confirmedStartDate,
                startDate,
                confirmedEndDate,
                endDate
              ) ? (
                <TimeWindowWarningPopover
                  message={t('timeWindowWarningMessage')}
                >
                  <IconClockExclamation color={palette.error.dark} />
                </TimeWindowWarningPopover>
              ) : null}
              {row.original.isViolatingContaminationRules ? (
                <TourContaminationWarning />
              ) : null}
            </Stack>
          </Stack>
        );
      },
      enableGlobalFilter: true,
      filterFn: (row, _, filterValue) => {
        if (!filterValue || !filterValue.length) {
          return true;
        }
        return filterValue.includes(row.original.vehicle.name);
      },
      size: 270,
    }),
    columnHelper.accessor('tourId', {
      sortingFn: 'alphanumeric',
      header: () => t('tourIdColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourIdCell tourId={info.getValue()} />,
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('status', {
      header: () => t('statusColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourStatusChip status={info.getValue()} />,
      filterFn: 'arrIncludesSome',
      size: 150,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('date', {
      header: () => t('dateColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">{formatDate(info.getValue())}</Typography>
      ),
      enableColumnFilter: true,
      filterFn: (row, _, filterValue: DateRange | undefined) => {
        if (!filterValue) {
          return true;
        }

        return isWithinInterval(parseISO(row.original.date), {
          start: filterValue.startDate,
          end: filterValue.endDate,
        });
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('startDate', {
      header: () => t('startTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="inherit">
            {formatDate(row.original.startDate, 'HH:mm').toLowerCase()}
          </Typography>
          {row.original.confirmedStartDate ? (
            <>
              <Box>•</Box>
              <ConfirmedTime
                confirmedTime={row.original.confirmedStartDate}
                plannedTime={row.original.startDate}
              />
            </>
          ) : null}
        </Stack>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('endDate', {
      header: () => t('endTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="inherit">
            {formatDate(row.original.endDate, 'HH:mm').toLowerCase()}
          </Typography>
          {row.original.confirmedEndDate ? (
            <>
              <Box>•</Box>
              <ConfirmedTime
                confirmedTime={row.original.confirmedEndDate}
                plannedTime={row.original.endDate}
              />
            </>
          ) : null}
          {row.original.forecastedEndDate && !row.original.confirmedEndDate && (
            <>
              <Box>•</Box>
              <ForecastedTime forecastedTime={row.original.forecastedEndDate} />
            </>
          )}
        </Stack>
      ),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('duration.time', {
      header: () => t('durationColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { startDate, endDate, duration, emptyRunsMetrics } = row.original;
        return (
          <Stack gap={1}>
            <ToursDurationCell
              startTime={startDate}
              endTime={endDate}
              distance={duration.distance}
            />
            {row.original.confirmedStartDate &&
            row.original.confirmedEndDate ? (
              <ConfirmedDuration
                confirmedStartTime={row.original.confirmedStartDate}
                confirmedEndTime={row.original.confirmedEndDate}
                plannedDuration={Math.abs(
                  differenceInSeconds(
                    new Date(row.original.startDate),
                    new Date(row.original.endDate)
                  )
                )}
              />
            ) : null}
            {emptyRunsMetrics.emptyRunsTotalNumber ? (
              <TourDurationCellEmptyRuns
                emptyRunsCount={emptyRunsMetrics.emptyRunsTotalNumber}
                totalEmptyRunsDurationInSeconds={
                  emptyRunsMetrics.emptyRunsTotalDurationSeconds
                }
              />
            ) : null}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('totalJobs', {
      id: 'jobs',
      header: () => t('jobsColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => row.original.totalJobs,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('quantity', {
      header: () => t('quantityColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => <ToursQuantityCell quantity={row.original.quantity} />,
      sortingFn: sortByQuantity<TourModel>,
    }),
    columnHelper.accessor('jobsCountPerStatus', {
      id: 'progress',
      header: () => t('progressColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const doneJobs = info.getValue().DONE;
        const totalJobs = info.row.original.totalJobs;
        return (
          <Stack direction="row" rowGap={2} alignItems="center">
            <CircularProgress
              value={100}
              variant="determinate"
              size={20}
              thickness={5.5}
              sx={{
                position: 'absolute',
                color: doneJobs ? 'success.light' : 'grey.300',
              }}
            />
            <CircularProgress
              value={(doneJobs * 100) / totalJobs}
              variant="determinate"
              size={20}
              thickness={5.5}
              sx={{
                color: 'success.dark',
                mr: 3,
              }}
            />
            <Typography variant="inherit" fontWeight={400}>
              {`${doneJobs}/${totalJobs}`}
            </Typography>
          </Stack>
        );
      },
      sortingFn: sortByProgress,
    }),
    columnHelper.accessor('isViolatingContaminationRules', {
      id: 'isViolatingContaminationRules',
      header: () => null,
      size: 0,
      enableHiding: false,
    }),
    columnHelper.accessor('note', {
      id: 'note',
      header: () => t('noteColumnHeader'),
      cell: (info) => (
        <ESPopover content={info.getValue()} breakWord>
          <Typography>{info.getValue()}</Typography>
        </ESPopover>
      ),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actionsColumnLabel'),
      enableResizing: false,
      meta: { isActionable: true },
      cell: ({ row }) => <ToursTableMoreOptionsMenu tour={row.original} />,
    }),
  ];

  const { instance, rows, globalFilter, onGlobalFilterChange } =
    useESTableBasic(tours, columns, {
      tableId,
      fitRowHeight: 74,
      manualPagination: true,
      enableColumnResizing: true,
      persistSorting: true,
      persistFilters: true,
      initialColumnFilters: [{ id: 'date', value: dateRangeFilter }],
      hiddenColumnsIds: ['isViolatingContaminationRules'],
      initialGlobalFilter: searchParams.get('search') ?? '',
      initialColumnVisibility: { tourId: false },
    });

  const scrollCache = useElementScrollRestoration({
    id: TABLE_SCROLL_RESTORATION_ID,
  });

  const handleRowClick = useCallback(
    (row: Row<TourModel>) =>
      navigate(`${FleetRoutes.Tours}/${row.original.tourId}`, {
        state: { from: FleetRoutes.Tours },
      }),
    [navigate]
  );

  const handleGlobalFilterChange = useCallback(
    (value: string) => {
      onGlobalFilterChange(value);
      setSearchParams(
        (params) => {
          params.set('search', value.trim());
          return params;
        },
        { replace: true }
      );
    },
    [onGlobalFilterChange, setSearchParams]
  );

  const toursWithViolationsCount = rows.filter(
    (row) => row.original.isViolatingContaminationRules
  ).length;

  return (
    <>
      {toursWithViolationsCount > 0 && (
        <Box marginBottom={8}>
          <ContaminationGlobalAlert
            count={toursWithViolationsCount}
            isSwitchOn={
              !!instance
                .getColumn('isViolatingContaminationRules')
                ?.getFilterValue()
            }
            onSwitchChange={(value) => {
              instance
                .getColumn('isViolatingContaminationRules')
                ?.setFilterValue(value ? value : undefined);
            }}
          />
        </Box>
      )}
      {isFilterable ? (
        <ESTableFilters>
          <ESTooltip title={t('searchToursPlaceholder')}>
            <TableSearchField
              placeholder={t('searchToursPlaceholder')}
              value={globalFilter}
              onChange={handleGlobalFilterChange}
              tableInstance={instance}
            />
          </ESTooltip>
          <TableDateRangeFilter
            testId="tours"
            title={tShared('timeRange')}
            weekStartsOn={1}
            isActive={
              instance.getColumn('date')?.getFilterValue() !== undefined
            }
            dateRange={dateRangeFilter}
            onDateRangeApplied={onDateRangeChange}
            onDateRangeCleared={() => {
              onDateRangeChange?.(undefined);
            }}
            column={instance.getColumn('date')}
          />
          <TableColumnFilter
            column={instance.getColumn('status')}
            columnLabel={t('statusColumnHeader')}
            options={tourStatuses.map((s) => ({
              label: t(`tourStatuses.${s}`),
              value: s,
            }))}
            isClearable
          />
          <ToursTableVehicleFilter column={instance.getColumn('vehicle')} />
          <ClearFiltersButton
            tableInstance={instance}
            clearFilters={() => {
              onDateRangeChange?.(undefined);
              instance.setColumnFilters([]);
            }}
          />
          {enableColumnSelection ? (
            <Box sx={{ marginLeft: 'auto' }}>
              <TableColumnSelect instance={instance} />
            </Box>
          ) : null}
        </ESTableFilters>
      ) : null}
      <ESTableWrapper>
        <ESVirtualizedTable
          instance={instance}
          rows={rows}
          onRowClick={handleRowClick}
          scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
          initialScrollTop={scrollCache.scrollY}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('noToursTableMessage')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
    </>
  );
};
