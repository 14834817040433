import { toPayloadDate } from '@energy-stacks/shared';
import { TransitionModel } from './transitionModel';
import { TransitionDto } from './transitionMetricsDto';

export const transitionsToPayload = (
  transitions: TransitionModel[]
): TransitionDto[] | undefined => {
  if (!transitions.length) {
    return;
  }

  return transitions.map((transition) => ({
    distance: transition.distance,
    endDate: toPayloadDate(new Date(transition.endDate)),
    startDate: toPayloadDate(new Date(transition.startDate)),
    vehicleIsEmpty: transition.vehicleIsEmpty,
    waitDuration: transition.waitDuration,
    polyline: transition.polyline,
  }));
};
