import {
  ExceptionalHours,
  RegularWorkingHours,
  VehicleDetailsModel,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { weekdays } from '@energy-stacks/fleet/shared';
import { utcToZonedTime } from 'date-fns-tz';

export const getEditVehicleDefaultOperatingHours = (
  vehicle: VehicleDetailsModel | undefined,
  timeZoneId?: string
) => {
  return {
    workingHours: {
      regular: getDefaultRegularHours(vehicle?.workingHours.regular ?? []),
      exceptional: getDefaultExceptionalHours(
        vehicle?.workingHours.exceptional ?? [],
        timeZoneId
      ),
    },
  };
};

const getDefaultRegularHours = (
  regularHours: VehicleDetailsModel['workingHours']['regular']
) => {
  const workingHours: RegularWorkingHours = weekdays.reduce((acc, day) => {
    return {
      ...acc,
      [day]: {
        startTime: null,
        endTime: null,
        isWorkingDay: false,
      },
    };
  }, {} as RegularWorkingHours);

  regularHours.forEach((day) => {
    const [startHours, startMinutes] = day.startTime.split(':');
    const [endHours, endMinutes] = day.endTime.split(':');

    const workingHoursStart = startHours && startMinutes ? new Date() : null;
    const workingHoursEnd = endHours && endMinutes ? new Date() : null;

    if (workingHoursStart) {
      workingHoursStart.setHours(Number(startHours));
      workingHoursStart.setMinutes(Number(startMinutes));
    }
    if (workingHoursEnd) {
      workingHoursEnd.setHours(Number(endHours));
      workingHoursEnd.setMinutes(Number(endMinutes));
    }

    workingHours[day.weekday].startTime = workingHoursStart;
    workingHours[day.weekday].endTime = workingHoursEnd;
    workingHours[day.weekday].isWorkingDay =
      workingHoursStart !== null && workingHoursEnd !== null;
  });

  return workingHours;
};

const getDefaultExceptionalHours = (
  exceptionalHours: ExceptionalHours[],
  timeZoneId?: string
) => {
  return exceptionalHours
    .map((exceptionalHour) => ({
      isOperating: exceptionalHour.isOperating,
      date: utcToZonedTime(exceptionalHour.startDate, timeZoneId ?? ''),
      startTime: utcToZonedTime(exceptionalHour.startDate, timeZoneId ?? ''),
      endTime: utcToZonedTime(exceptionalHour.endDate, timeZoneId ?? ''),
    }))
    .sort((a, b) => b.startTime.getTime() - a.startTime.getTime());
};
