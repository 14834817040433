import {
  ChargingStationModel,
  ConnectorModel,
  selectAllStations,
  useChargingStations,
} from '@energy-stacks/broker/feature-charging-stations-data';
import {
  ESPage,
  RefetchOnError,
  toPayloadDate,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import { IconCalendar } from '@tabler/icons-react';
import { groupBy } from 'lodash';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetChargingReservationsQuery } from '@energy-stacks/fleet-is/feature-charging-schedule-data';
import {
  addDays,
  endOfDay,
  format,
  isSameDay,
  parseISO,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import { flatMap } from 'lodash-es';
import { useSelector } from 'react-redux';
import {
  ChargingScheduleGantChart,
  Data,
} from './charts/ChargingScheduleGantChart';
import { getSlots } from './charts/chargingScheduleGantHours';
import './charts/chart.css';
import { IconButton } from '@mui/material';
import { ReactComponent as LeftArrow } from './leftArrow.svg';
import { ReactComponent as RightArrow } from './rightArrow.svg';

export const ChargingSchedulePage = () => {
  const { t } = useTranslation('chargingScheduleResult');
  useDocumentTitle(t('title'));

  // const [showDiagram, setShowDiagram] = useState(true);
  const [day, setDay] = useState(startOfDay(new Date()));
  const [chartContainer, setChartContainer] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (chartContainerRef.current) {
      setChartContainer({
        width: chartContainerRef.current.getBoundingClientRect().width,
        height: chartContainerRef.current.getBoundingClientRect().height,
      });
    }
    const resizeObserver = new ResizeObserver((entries) => {
      setChartContainer({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <ESPage title={t('title')}>
      <Stack
        direction="column"
        gap={6}
        overflow="hidden"
        position="relative"
        flex={1}
      >
        <WeekCalendar
          selectedDay={day}
          onDayChange={(day: Date) => setDay(day)}
        />
        <Stack
          direction="column"
          borderRadius={3}
          sx={(theme) => ({
            height: '89%',
            width: '100%',
            padding: '16px 0px 0px 16px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey[200]}`,
          })}
        >
          {/* <Box alignSelf="end" marginRight={3}>
            <ESButton
              onClick={() => setShowDiagram((show) => !show)}
              variant="text"
              color="primary"
            >
              Show diagram
            </ESButton>
          </Box> */}
          <Stack
            flex={1}
            ref={chartContainerRef}
            direction="column"
            overflow="auto"
          >
            <Stack>
              {/* <Collapse in={showDiagram}>
                <Box width="100%">
                  <RenewablePriceDiagram
                    width={chartContainerWidth - 12}
                    height={300}
                  />
                </Box>
              </Collapse> */}
              <Box width="100%">
                {chartContainer && (
                  <ChargingScheduleViz
                    selectedDay={day}
                    height={chartContainer.height}
                    width={chartContainer.width - 12}
                  />
                )}
              </Box>
              {/* <Box width="100%" marginBottom={4}>
                <PowerConsumptionChart />
              </Box> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ESPage>
  );
};

const WeekCalendar = ({
  selectedDay,
  onDayChange,
}: {
  selectedDay: Date;
  onDayChange: (day: Date) => void;
}) => {
  const [startOfCurrentWeek, setStartOfCurrentWeek] = useState(
    startOfWeek(selectedDay, { weekStartsOn: 1 })
  );
  return (
    <Stack direction="row" minHeight={50} overflow="scroll" spacing={1.5}>
      <IconButton
        style={{ width: 50 }}
        onClick={() => {
          setStartOfCurrentWeek((value) =>
            startOfWeek(subDays(value, 7), { weekStartsOn: 1 })
          );
        }}
      >
        <LeftArrow />
      </IconButton>
      {Array.from({ length: 7 }).map((_, index) => {
        const day = addDays(startOfCurrentWeek, index);
        return (
          <Box
            flexGrow={1}
            textAlign={'left'}
            key={day.toISOString()}
            onClick={() => {
              onDayChange(day);
            }}
            style={{ cursor: 'pointer' }}
            bgcolor={
              isSameDay(selectedDay, day)
                ? 'primary.light'
                : isSameDay(new Date(), day)
                ? 'primary.main'
                : 'background.paper'
            }
            border={`1px solid ${
              isSameDay(selectedDay, day) ? '#90CAF9' : 'initial'
            }`}
            borderRadius={1}
            color={isSameDay(new Date(), day) ? 'white' : 'grey.500'}
            px={4}
            py={1.5}
          >
            <Stack direction="column" spacing={2.5 / 4}>
              <Typography
                variant="h5"
                fontWeight={700}
                color={
                  isSameDay(selectedDay, day)
                    ? 'dark.900'
                    : isSameDay(new Date(), day)
                    ? 'white'
                    : 'initial'
                }
              >
                {format(day, 'EEEE')}
              </Typography>
              <Stack direction="row" spacing={5 / 4}>
                <IconCalendar width={16} height={16} />
                <Typography fontWeight={400} fontSize={12}>
                  {format(day, 'dd.MM.yyyy')}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        );
      })}
      <IconButton
        style={{ width: 50 }}
        onClick={() => {
          setStartOfCurrentWeek((value) =>
            startOfWeek(addDays(value, 7), { weekStartsOn: 1 })
          );
        }}
      >
        <RightArrow />
      </IconButton>
    </Stack>
  );
};

const ChargingScheduleViz = ({
  selectedDay,
  width,
  height,
}: {
  selectedDay: Date;
  width: number;
  height: number;
}) => {
  // NOTE: We do not support schedules which span over multiple days for now
  // const { currentData: data } = useGetChargingReservationsQuery({
  //   // This returns empty result even though there are reservations on the first of June
  //   dateTimeFrom: toPayloadDate(
  //     // Double check to not show the conflicting reservations, give priority to the current day
  //     addHours(startOfDay(subDays(selectedDay, 1)), 18)
  //   ),
  //   dateTimeTo: toPayloadDate(addHours(startOfDay(addDays(selectedDay, 1)), 6)),
  // });
  useChargingStations();
  const chargingStations = useSelector(selectAllStations);

  const startDateFrom = startOfDay(selectedDay);
  const endDateTo = endOfDay(selectedDay);

  const {
    data: cachedReservations,
    currentData: chargingReservations,
    isFetching: isReservationFetching,
    isLoading: isReservationLoading,
    isError: isReservationError,
    refetch: refetchReservation,
  } = useGetChargingReservationsQuery({
    dateTimeFrom: toPayloadDate(startDateFrom),
    dateTimeTo: toPayloadDate(endDateTo),
  });

  const slottedData: Data | undefined = useMemo(() => {
    const reservations = (chargingReservations || cachedReservations) ?? [];

    const timeSlots = Object.entries(
      groupBy(
        [
          ...reservations.map((reservation) => ({
            ...reservation,
            powerType: (chargingStations ?? [])
              .map((station) =>
                station.connectors.find(
                  (connector) =>
                    reservation.chargingStationConnector.connectorId ===
                      connector.connectorId &&
                    station.identityKey ===
                      reservation.chargingStationConnector.identityKey
                )
              )
              .filter((connector) => !!connector)
              .map((connector) =>
                connector?.powerType?.startsWith('AC') ? 'AC' : 'DC'
              )[0],
          })),
          ...flatMap(
            chargingStations,
            (entity: ChargingStationModel) => entity.connectors
          ).map((connector: ConnectorModel) => ({
            // Empty reservation in order to show empty connector row in the vizualization
            // Empty reservation will be ignored by the Gantt chart
            chargingStationConnector: {
              identityKey: chargingStations.find((station) =>
                station.connectors.find(
                  (currentConnector) =>
                    connector.evseId === currentConnector.evseId
                )
              )?.identityKey,
              connectorId: connector.connectorId,
            },
            vehicleDetails: {
              vehicleIdentificationNumber: '',
              vehicleName: '',
            },
            startDate: '',
            endDate: '',
            socStart: 0,
            socEnd: 0,
            chargedKwh: connector.currentPower,
            powerType: connector.powerType?.startsWith('AC') ? 'AC' : 'DC',
          })),
        ],
        (data) =>
          `${data.chargingStationConnector.identityKey}-${data.chargingStationConnector.connectorId}`
      )
    ).reduce((acc, [_, reservations], index) => {
      return acc.concat({
        index: `${index + 1}`,
        // We are sure that we have at least one reservation
        power: `${reservations[0].chargedKwh} KW`,
        powerType: reservations[0].powerType,
        schedule: !reservations[0].startDate
          ? []
          : reservations.map((reservation) => {
              const { startSlot, endSlot } = getSlots(
                parseISO(reservation.startDate),
                parseISO(reservation.endDate),
                selectedDay
              );
              return {
                startSlot,
                endSlot,
                startDate: parseISO(reservation.startDate),
                endDate: parseISO(reservation.endDate),
                socStart: reservation.socStart,
                socEnd: reservation.socEnd,
                vehicleId:
                  reservation.vehicleDetails.vehicleIdentificationNumber,
                vehicleName: reservation.vehicleDetails.vehicleName,
              };
            }),
      });
    }, [] as Data);
    return timeSlots.map((slot) => ({
      ...slot,
      // // Filter out empty slots
      schedule: slot.schedule.filter(
        (s) => s.startSlot !== -1 && s.endSlot !== -1
      ),
    }));
  }, [chargingReservations, selectedDay, chargingStations, cachedReservations]);

  if (
    !chargingStations.length &&
    !isReservationLoading &&
    !isReservationFetching
  ) {
    return <RefetchOnError onRefetch={refetchReservation} />;
  }
  return (
    <>
      <ChargingScheduleGantChart
        loading={isReservationLoading || isReservationFetching}
        data={slottedData}
        dataError={isReservationError}
        onDataErrorRefetch={refetchReservation}
        height={height}
        width={Math.max(width, 1100)}
      />
    </>
  );
};
