import { SxProps, Theme } from '@mui/material';

export const ELLIPSIS_TEXT_SX: SxProps<Theme> = {
  // NOTE: for now only supporting p tags. If this should be applied to, for example,
  // span or some other element, selector should be added here
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
