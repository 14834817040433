import { SidebarSelect } from '@energy-stacks/core/sidebar';
import { IconLanguage } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const langSwitcherOptions = ['en'];

export const FleetLanguageSwitcher = () => {
  const { t } = useTranslation('shared');
  return (
    <SidebarSelect
      icon={<IconLanguage />}
      onSelectionChange={() => {}}
      selectedValue={langSwitcherOptions[0].toUpperCase()}
      options={langSwitcherOptions.map((value) => ({ label: t(value), value }))}
      testId="LanguageSwitcher"
    />
  );
};
