import { VehicleModel } from '@energy-stacks/fleet/feature-vehicles-data';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useVehiclesSorted } from './useVehiclesSorted';
import { VehiclesTable } from './VehiclesTable';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { DEFAULT_VEHICLE_GROUP_FILTER } from './defaultVehicleGroupTableFilter';

interface VehiclesProps {
  onVehiclesSelected: (vehicleIds: VehicleModel['licencePlate'][]) => void;
  selectedVehicleIds: VehicleModel['vehicleId'][] | undefined;
  onDateRangeChange?: (dateRange: DateRange) => void;
  defaultDateRange: DateRange;
  dateRange: DateRange;
}

const isVehicleInitiallySelected = (vehicle: VehicleModel) => {
  return (
    vehicle.status === 'AVAILABLE' &&
    vehicle.group === DEFAULT_VEHICLE_GROUP_FILTER.value[0]
  );
};

export const Vehicles: React.FC<VehiclesProps> = ({
  onVehiclesSelected,
  dateRange,
  defaultDateRange,
  onDateRangeChange,
  selectedVehicleIds,
}) => {
  const { data, isError, isLoading, refetch } = useVehiclesSorted({
    dateFrom: dateRange?.startDate,
    dateTo: dateRange?.endDate,
  });
  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? (
        <VehiclesTable
          tableId="create-tour-wizard-vehicles-table"
          vehicles={data ?? []}
          hiddenColumnsIds={['actions']}
          enableColumnSelection
          enableTimeRangeFilter
          defaultRowSelection={(vehicle) =>
            selectedVehicleIds
              ? selectedVehicleIds.includes(vehicle.vehicleId)
              : isVehicleInitiallySelected(vehicle)
          }
          onSelectionChange={(vehiclesRowSelection) => {
            onVehiclesSelected(Object.keys(vehiclesRowSelection));
          }}
          enableRowSelection
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
      ) : null}
    </>
  );
};
