import { ESMenuItem, useESMenuContext } from '@energy-stacks/core/ui';
import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import {
  MoreOptionsMenu,
  useAppLocation,
  useDialog,
} from '@energy-stacks/shared';
import React from 'react';
import { EditTourMenuItem } from './edit-tour/EditTourMenuItem';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { EditTourNoteDialog } from './EditTourNoteDialog';

type ToursTableMoreOptionsMenuProps = {
  tour: TourModel;
};

export const ToursTableMoreOptionsMenu: React.FC<
  ToursTableMoreOptionsMenuProps
> = ({ tour }) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const [t] = useTranslation('tours');
  const {
    open: openEditTourNoteDialog,
    dialogProps: { isOpen, onClose },
  } = useDialog();

  return (
    <MoreOptionsMenu testId={tour.tourId} minWidth={100}>
      <EditTourNoteMenuItem
        testId={tour.tourId}
        onClick={() => {
          openEditTourNoteDialog();
        }}
      />
      <EditTourMenuItem
        status={tour.status}
        testId={tour.tourId}
        onEnterEditMode={() => {
          navigate(`${FleetRoutes.Tours}/${tour.tourId}`, {
            state: { from: location, shouldEdit: true },
          });
        }}
      />
      <ESMenuItem
        testId={`${tour.tourId}DeleteMenuItem`}
        onClick={() =>
          navigate(`${tour.tourId}/delete`, {
            state: { background: location },
          })
        }
      >
        {t('deleteTourCTA')}
      </ESMenuItem>
      <EditTourNoteDialog
        isOpen={isOpen}
        onClose={onClose}
        initialNote={tour.note}
        tourId={tour.tourId}
      />
    </MoreOptionsMenu>
  );
};

const EditTourNoteMenuItem = ({
  testId,
  onClick,
}: {
  testId: string;
  onClick: () => void;
}) => {
  const { closeESMenu } = useESMenuContext();
  const [t] = useTranslation('tours');
  return (
    <ESMenuItem
      testId={`${testId}AddNoteMenuItem`}
      onClick={() => {
        onClick();
        closeESMenu();
      }}
    >
      {t('addTourNoteCTA')}
    </ESMenuItem>
  );
};
