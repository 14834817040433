import { TourDetailsJobsTable } from './TourDetailsJobsTable';
import React from 'react';
import { TourDetailsMap } from '../tour-details-map/TourDetailsMap';
import {
  OptimizedTourModel,
  TourJobs,
  TourStatus,
  EmptyRunsMetrics,
} from '@energy-stacks/fleet/feature-tours-data';
import { TourDetailsViewMode } from './TourDetailsViewModeButton';

export type TourInfo = Pick<
  OptimizedTourModel,
  | 'startDate'
  | 'endDate'
  | 'vehicleName'
  | 'distance'
  | 'totalJobs'
  | 'isVehicleDeleted'
> & {
  tourIndex: number;
  polyline: string;
  tourJobs: TourJobs;
  tourStatus?: TourStatus;
  emptyRunsMetrics?: EmptyRunsMetrics;
  timeZoneId?: string;
};

interface TourDetailsProps {
  tourInfo: TourInfo;
  viewMode?: TourDetailsViewMode;
}

export const TourDetails: React.FC<TourDetailsProps> = ({
  tourInfo,
  viewMode = 'table',
}) => {
  if (viewMode === 'table') {
    return (
      <TourDetailsJobsTable
        tourJobs={tourInfo.tourJobs || []}
        hiddenColumnsIds={!tourInfo.tourJobs.jobs[0]?.status ? ['status'] : []}
      />
    );
  }

  return <TourDetailsMap tours={[tourInfo]} />;
};
