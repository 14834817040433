import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '@energy-stacks/shared';
import { environment } from '@energy-stacks/feature-config';
import { TourStatus } from '@energy-stacks/fleet/feature-tours-data';
import { dashboardToursNormalizer } from './normalizers/dashboardToursNormalizer';
import { DashboardTours } from './dashboardTourModel';
import {
  DashboardTableBackendSortOrderModel,
  mapSortIdToPayload,
} from './mapSortIdToPayload';

export type GetDashboardToursRequestParams =
  | {
      startDateFrom?: string;
      endDateTo?: string;
      vehicleIdentificationNumbers?: string[];
      depotIds?: string[];
      statuses?: TourStatus[];
      searchValue?: string;
      sort?: DashboardTableBackendSortOrderModel;
      page: number;
      size: number;
    }
  | undefined;

export const dashboardApi = createApi({
  reducerPath: 'dashboard',
  tagTypes: ['DashboardTours'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/dashboard/`),
  endpoints: (builder) => ({
    getDashboardTours: builder.query<
      DashboardTours,
      GetDashboardToursRequestParams
    >({
      query: (searchParams) => ({
        url: 'tours',
        method: 'GET',
        params: {
          ...searchParams,
          sort: searchParams?.sort
            ? mapSortIdToPayload(searchParams.sort)
            : undefined,
        },
      }),
      transformResponse: dashboardToursNormalizer,
      providesTags: ['DashboardTours'],
    }),
  }),
});

export const { useGetDashboardToursQuery } = dashboardApi;
