import { Stack } from '@mui/material';

export const TourDndTableHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Stack
      id="tourJobsDndTableHeaderRef"
      direction="row"
      gap={4}
      sx={{
        padding: 4,
        borderRadius: 3,
        margin: 0,
        backgroundColor: 'background.paper',
        minHeight: '80px',
        alignItems: 'center',
      }}
    >
      {children}
    </Stack>
  );
};
