import {
  Box,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, Fragment, useState } from 'react';
import { BrokerLocationChargingStation } from '@energy-stacks/broker/feature-locations-data';
import { ESButton, ESMenu, ESMenuItem } from '@energy-stacks/core/ui';
import { IconCirclePlus } from '@tabler/icons-react';
import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { BrokerRoutes } from '@energy-stacks/broker/shared';
import { LocationDrawerUnassignDialog } from './LocationDrawerUnassignDialog';
import { useGetUiBrandingQuery } from '@energy-stacks/broker/feature-settings-data';
import { chargingStationStatusDisplay } from '@energy-stacks/broker/feature-charging-stations';

type LocationDetailsChargingStationsProps = {
  chargingStations: BrokerLocationChargingStation[];
  locationId: string;
};

export const LocationDetailsChargingStations: FC<
  LocationDetailsChargingStationsProps
> = ({ chargingStations, locationId }) => {
  const [t] = useTranslation('locations');
  const theme = useTheme();
  const spacing = theme.spacing;
  const navigate = useNavigate();
  const [isDialogUnassignOpen, setIsDialogUnassignOpen] = useState(false);
  const [identityKey, setIdentityKey] = useState<string>('');
  const { data: uiBranding } = useGetUiBrandingQuery();
  const hasBorderRadius =
    uiBranding?.hasBorderRadius || uiBranding?.hasBorderRadius === undefined;

  return (
    <Box sx={{ pb: 6 }}>
      <Box
        borderRadius={hasBorderRadius ? 3 : 0}
        sx={{
          backgroundColor: 'background.paper',
          p: 4,
          pt: 3,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            pb: 4.25,
          }}
        >
          <Typography variant="h4" color="grey.800" fontWeight={500}>
            {t('locationDetailsChargingStationsTitle')}
          </Typography>
          {chargingStations.length > 0 && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => navigate(`${locationId}/assign`)}
            >
              <IconCirclePlus />
            </IconButton>
          )}
        </Box>
        {chargingStations.length === 0 ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ maxWidth: spacing(75), textAlign: 'center' }}>
              {t('locationDetailsNoStationsMessage')}
            </Typography>
            <ESButton
              size="small"
              variant="outlined"
              startIcon={<IconPlus />}
              sx={{
                mt: 7.5,
              }}
              onClick={() => navigate(`${locationId}/assign`)}
            >
              {t('locationDetailsAssignStationButtonLabel')}
            </ESButton>
          </Box>
        ) : (
          <List
            disablePadding
            sx={{
              backgroundColor: 'grey.50',
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: hasBorderRadius ? 3 : 0,
            }}
          >
            {chargingStations.map((cs, i, arr) => {
              const isLast = arr.length - 1 === i;
              const { bgColor, color } =
                chargingStationStatusDisplay(theme)[cs.shadow];
              return (
                <Fragment key={cs.identityKey}>
                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      px: 4,
                      py: 3.4,
                    }}
                  >
                    <Typography variant="h5" noWrap color="grey.900">
                      <Link
                        onClick={() =>
                          navigate(
                            `${BrokerRoutes.ChargingStations}/${cs.identityKey}`
                          )
                        }
                        sx={{
                          color: 'grey.900',
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {cs.chargingStationName}
                      </Link>
                    </Typography>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Chip
                        size="small"
                        label={t(cs.shadow, { context: 'status' })}
                        sx={{
                          backgroundColor: bgColor,
                          color,
                          fontSize: spacing(3),
                          fontWeight: 500,
                          ml: 2,
                          mr: 4,
                          py: 1,
                          px: 3,
                        }}
                      />
                      <ESMenu
                        testId={`${locationId}MenuItem`}
                        disableMenu={isDialogUnassignOpen}
                      >
                        <ESMenuItem
                          onClick={() => {
                            setIsDialogUnassignOpen(true);
                            setIdentityKey(cs.identityKey);
                          }}
                        >
                          {t('rowActionsUnassignItemLabel')}
                        </ESMenuItem>
                      </ESMenu>
                    </Box>
                  </ListItem>

                  {!isLast && <Divider />}
                </Fragment>
              );
            })}
            <LocationDrawerUnassignDialog
              locationId={locationId}
              chargingStations={[identityKey]}
              testId={'unassignMenuItem'}
              isOpen={isDialogUnassignOpen}
              onClose={() => setIsDialogUnassignOpen(false)}
            />
          </List>
        )}
      </Box>
    </Box>
  );
};
