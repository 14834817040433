import { jobsApi } from '@energy-stacks/fleet/feature-jobs-data';
import { useAppDispatch } from '@energy-stacks/store';
import { useCallback } from 'react';

export const useInvalidateJobPool = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(jobsApi.util.invalidateTags(['JobsPool']));
  }, [dispatch]);
};
