import { ESButton } from '@energy-stacks/core/ui';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { ExceptionalOperatingHoursInputs } from './ExceptionalOperatingHoursInputs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  EditVehicleOperatingHoursFormData,
  RegularWorkingHoursInfo,
} from '@energy-stacks/fleet/feature-vehicles-data';
import {
  addDays,
  differenceInCalendarDays,
  isBefore,
  isValid,
  startOfDay,
} from 'date-fns';
import { FC, useCallback } from 'react';

type EditVehicleExceptionalOperatingHoursProps = {
  defaultOperatingTime: Pick<RegularWorkingHoursInfo, 'startTime' | 'endTime'>;
};

export const EditVehicleExceptionalOperatingHours: FC<
  EditVehicleExceptionalOperatingHoursProps
> = ({ defaultOperatingTime }) => {
  const [t] = useTranslation('vehicles');
  const { control, getValues } =
    useFormContext<EditVehicleOperatingHoursFormData>();
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'workingHours.exceptional',
  });
  const handleAddException = useCallback(() => {
    const nextAvailableExceptionDate = getNextAvailableExceptionDate(
      getValues().workingHours.exceptional.map((e) => e.date)
    );

    const startDate = new Date(nextAvailableExceptionDate);
    startDate.setHours(0);
    startDate.setMinutes(0);

    const endDate = new Date(nextAvailableExceptionDate);
    endDate.setHours(23);
    endDate.setMinutes(59);

    prepend({
      isOperating: false,
      date: nextAvailableExceptionDate,
      startTime: startDate,
      endTime: endDate,
    });
  }, [getValues, prepend]);

  return (
    <Box sx={{ mx: 5, my: 6, mr: 'unset' }}>
      <ESButton
        data-testid="addExceptionalHoursBtn"
        variant="outlined"
        onClick={handleAddException}
        startIcon={<IconPlus />}
        sx={{
          mb: 1.5,
          '&.MuiButton-outlined': {
            borderColor: 'primary.main',
            borderRadius: 1,
            px: 7,
          },
          '&.MuiButton-outlined:hover': {
            borderColor: 'primary.main',
          },
          '& .MuiButton-startIcon': {
            mr: 3,
          },
        }}
      >
        {t('addExceptionalHoursBtnLabel')}
      </ESButton>
      {fields.map((f, i) => {
        return (
          <ExceptionalOperatingHoursInputs
            key={f.id}
            fieldIndex={i}
            disabled={
              isValid(f.date) && isBefore(f.date, startOfDay(new Date()))
            }
            onRemove={remove}
            defaultOperatingTime={defaultOperatingTime}
          />
        );
      })}
    </Box>
  );
};

const getNextAvailableExceptionDate = (currentExceptionDates: Date[]) => {
  const sortedExceptionDates = currentExceptionDates
    .filter((date) => isValid(date))
    .sort((a, b) => a.getTime() - b.getTime());
  const today = new Date();
  const todayIndex = sortedExceptionDates.findIndex(
    (e) => e.toDateString() === today.toDateString()
  );

  if (todayIndex === -1) {
    return today;
  } else {
    let nextAvailableDate: Date | undefined;
    for (let i = todayIndex; i < sortedExceptionDates.length - 1; i++) {
      const diff = differenceInCalendarDays(
        sortedExceptionDates[i + 1],
        sortedExceptionDates[i]
      );
      if (diff > 1) {
        nextAvailableDate = addDays(sortedExceptionDates[i], 1);
      }
    }
    if (!nextAvailableDate) {
      const lastExceptionDate =
        sortedExceptionDates[sortedExceptionDates.length - 1];
      nextAvailableDate = addDays(lastExceptionDate, 1);
    }
    return nextAvailableDate;
  }
};
