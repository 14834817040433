import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type ColumnWidthsState<T> = Record<keyof T | string, number>;

export const usePersistTableColumnWidths = <T extends object>(
  tableId?: string
) => {
  const [persistedColumnWidths, setPersistedColumnWidths] = useLocalStorage<
    ColumnWidthsState<T>
  >(`table-column-widths-${tableId}`, {} as ColumnWidthsState<T>);

  const handlePersistTableColumnWidth = useCallback(
    (columnId: string, columnSize: number) => {
      setPersistedColumnWidths((prev) => {
        return {
          ...prev,
          [columnId]: columnSize,
        };
      });
    },
    [setPersistedColumnWidths]
  );

  return { persistedColumnWidths, handlePersistTableColumnWidth };
};
