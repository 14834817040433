import {
  ContainerIcon,
  TimeWindowWarningPopover,
} from '@energy-stacks/fleet/shared';
import { useTheme } from '@mui/material';
import { IconClockExclamation, IconClockHour5 } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type JobTimeIconProps = {
  showWarning?: boolean;
};

export const JobTimeIcon: React.FC<JobTimeIconProps> = ({ showWarning }) => {
  const theme = useTheme();
  const [t] = useTranslation('jobs');

  if (!showWarning) {
    return (
      <ContainerIcon>
        <IconClockHour5 size={20} />
      </ContainerIcon>
    );
  }

  return (
    <TimeWindowWarningPopover message={t('timeWindowWarningMessage')}>
      <ContainerIcon
        backgroundColor={theme.palette.error.dark}
        borderColor={theme.palette.error.dark}
      >
        <IconClockExclamation
          size={20}
          color={theme.palette.background.paper}
        />
      </ContainerIcon>
    </TimeWindowWarningPopover>
  );
};
