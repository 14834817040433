import {
  VehicleModel,
  useGetVehicleBrandImagesQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { Box } from '@mui/material';
import { IconTruck } from '@tabler/icons-react';
import { FC } from 'react';

type VehicleLogoProps = {
  brandId?: VehicleModel['brandId'];
};
export const VehicleLogo: FC<VehicleLogoProps> = ({ brandId }) => {
  const { logo, isLoading } = useGetVehicleBrandImagesQuery(
    brandId ? [brandId] : [],
    {
      selectFromResult: ({ data, isLoading }) => ({
        logo: data?.find((brand) => brand.id === brandId)?.image,
        isLoading,
      }),
      skip: !brandId,
    }
  );

  return isLoading || !logo ? (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'primary.light',
        borderRadius: 5,
        color: 'primary.dark',
        display: 'flex',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <IconTruck />
    </Box>
  ) : (
    <img
      alt="vehicle_manufacturer"
      src={logo}
      style={{
        width: 40,
        height: 40,
      }}
    />
  );
};
