import {
  DndRowsOptions,
  ESDnDVirtualizedTable,
  ESTableWrapper,
  PortalTarget,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDateTimeCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import {
  NoTableData,
  TableColumnSelect,
  TableRowsCount,
} from '@energy-stacks/shared';
import { Box, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TourJobIdCell } from '../tour-details/TourJobIdCell';
import { EditingTourJob } from './editingTourJobModel';
import { ProductTypeChip } from '@energy-stacks/fleet/shared';
import { isWithinInterval } from 'date-fns';
import { useEditTourDetailsContext } from './useEditTourDetailsContext';
import { checkRawJobSequence } from '../shared/checkRawJobSequence';
import { TourJobsTableJobStatusChip } from './TourJobsDndTableJobStatusChip';
import { SmartRecommendationTableCell } from './SmartRecommendationTableCell';
import { SmartRecommendationDialog } from './SmartRecommendationDialog';
import { useCallback, useMemo, useState } from 'react';

type TourJobsDndTableProps = {
  tourJobs: EditingTourJob[];
  dndRowsOptions: DndRowsOptions<EditingTourJob>;
};

const ROW_HEIGHT = 65;

const jobSideActivities = ['BREAK'];

const JobTypeGuard: React.FC<{
  jobType: EditingTourJob['jobType'] | 'BREAK';
  children: React.ReactNode;
}> = ({ jobType, children }) => {
  if (jobSideActivities.includes(jobType)) {
    return null;
  }

  return <>{children}</>;
};

export const TourJobsDndTable: React.FC<TourJobsDndTableProps> = ({
  tourJobs,
  dndRowsOptions,
}) => {
  const [t] = useTranslation('jobs');
  const columnHelper = createColumnHelper<EditingTourJob>();
  const removePlantIdPrefix = useRemovePlantIdPrefix();
  const theme = useTheme();
  const { isTourChanged, tourDetails } = useEditTourDetailsContext();

  const [smartRecommendationJob, setSmartRecommendationJob] = useState<
    string | null
  >(null);

  const handleCloseSmartRecommendationDialog = useCallback(() => {
    setSmartRecommendationJob(null);
  }, []);

  const columns = [
    columnHelper.accessor((row) => row.origin?.id, {
      id: 'smartRecommendation',
      header: () => null,
      footer: (props) => props.column.id,
      size: 10,
      cell: ({ row }) => {
        const initialTourJobs = tourDetails?.initialTour.tourJobs.jobs ?? [];
        // Don't show smart recommendation option for jobs that are newly added to the tour
        // since smart recommendation request will always fail for them
        if (!initialTourJobs.find((job) => job.jobId === row.original.jobId)) {
          return null;
        }

        return (
          <SmartRecommendationTableCell
            onCTA={() => {
              setSmartRecommendationJob(row.original.jobId);
            }}
            shouldEnable={dndRowsOptions.accept?.(row) !== 'none'}
          />
        );
      },
      enableSorting: false,
      enableResizing: false,
      meta: {
        canDisable: true,
        isActionable: true,
      },
      minSize: 0,
    }),
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <TourJobIdCell
          jobType={info.row.original.jobType}
          jobId={removePlantIdPrefix(info.getValue())}
          contaminationViolation={info.row.original.contaminationViolation}
        />
      ),
      size: 100,
      enableSorting: false,
      meta: {
        canDisable: true,
      },
    }),
    columnHelper.accessor((row) => row.origin?.id, {
      id: 'origin',
      header: () => t('origin'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row }) => (
        <JobLocationCell
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="origin"
          location={row.original.origin}
        />
      ),
      enableSorting: false,
      meta: {
        canDisable: true,
      },
    }),
    columnHelper.accessor((row) => row.destination?.id, {
      id: 'destination',
      header: () => t('destination'),
      footer: (props) => props.column.id,
      size: 250,
      cell: ({ row }) => (
        <JobLocationCell
          location={row.original.destination}
          supplierName={row.original.supplier.supplierName}
          jobType={row.original.jobType}
          locationType="destination"
        />
      ),
      enableSorting: false,
      meta: {
        canDisable: true,
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => t('status'),
      footer: (props) => props.column.id,
      size: 130,
      enableSorting: false,
      cell: (info) => (
        <TourJobsTableJobStatusChip
          jobId={info.row.original.jobId}
          status={info.getValue()}
        />
      ),
      enableGlobalFilter: false,
      meta: {
        canDisable: false,
      },
    }),
    columnHelper.accessor('product.name', {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 125,
      enableSorting: false,
      cell: ({ row, table }) => (
        <JobTypeGuard jobType={row.original.jobType}>
          {row.original.product && (
            <ProductTypeChip
              productName={row.original.product.name}
              isSequentialRaw={checkRawJobSequence(
                table.getCoreRowModel().rows,
                row.index
              )}
            />
          )}
        </JobTypeGuard>
      ),
      enableGlobalFilter: false,
      meta: {
        canDisable: true,
      },
    }),
    columnHelper.accessor('startTime', {
      header: () => t('plannedStart'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const {
          startTime,
          datePickUpFrom,
          datePickUpTill,
          confirmedStartTime,
          forecastedStartTime,
          timeZoneId,
        } = row.original;
        return (
          <JobDateTimeCell
            showWarning={
              !!datePickUpFrom &&
              !!datePickUpTill &&
              !isWithinInterval(
                new Date(
                  confirmedStartTime
                    ? confirmedStartTime
                    : forecastedStartTime
                    ? forecastedStartTime
                    : startTime
                ),
                {
                  start: new Date(datePickUpFrom),
                  end: new Date(datePickUpTill),
                }
              ) &&
              !isTourChanged
            }
            timeZoneId={timeZoneId}
            date={startTime}
          />
        );
      },
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('endTime', {
      header: () => t('plannedEnd'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const {
          endTime,
          dateDropOffFrom,
          dateDropOffTill,
          confirmedEndTime,
          forecastedEndTime,
          timeZoneId,
        } = row.original;
        return (
          <JobDateTimeCell
            showWarning={
              !!dateDropOffFrom &&
              !!dateDropOffTill &&
              !isWithinInterval(
                new Date(
                  confirmedEndTime
                    ? confirmedEndTime
                    : forecastedEndTime
                    ? forecastedEndTime
                    : endTime
                ),
                {
                  start: new Date(dateDropOffFrom),
                  end: new Date(dateDropOffTill),
                }
              ) &&
              !isTourChanged
            }
            timeZoneId={timeZoneId}
            date={endTime}
          />
        );
      },
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('datePickUpFrom', {
      header: () => t('pickupFrom'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.datePickUpFrom}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('datePickUpTill', {
      header: () => t('pickupTill'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.datePickUpTill}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('dateDropOffFrom', {
      header: () => t('dropOffFrom'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.dateDropOffFrom}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      enableSorting: false,
      size: 180,
    }),
    columnHelper.accessor('dateDropOffTill', {
      header: () => t('dropOffTill'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <JobDateTimeCell
          date={row.original.dateDropOffTill}
          timeZoneId={row.original.timeZoneId}
        />
      ),
      enableSorting: false,
      size: 180,
    }),
  ];

  const initialColumnVisibility = useMemo(() => {
    if (
      tourJobs.some(
        (job) => job.status === 'FAILED' || job.status === 'CANCELLED'
      )
    ) {
      return { smartRecommendation: false };
    }
  }, [tourJobs]);

  const { instance, rows } = useESTableBasic(tourJobs, columns, {
    tableId: 'tour-jobs-dnd-table',
    fitRowHeight: ROW_HEIGHT,
    manualPagination: true,
    enableColumnResizing: true,
    initialColumnVisibility,
    hiddenColumnsIds: isTourChanged
      ? ['smartRecommendation' as keyof EditingTourJob]
      : undefined,
    getRowId: (row) => row.jobId,
  });

  return (
    <>
      <ESTableWrapper>
        <PortalTarget targetId="tourJobsDndTableHeaderRef">
          <Box sx={{ marginLeft: 'auto' }}>
            <TableColumnSelect instance={instance} />
          </Box>
        </PortalTarget>
        <ESDnDVirtualizedTable
          instance={instance}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          dndRowsOptions={dndRowsOptions}
          applyRowStyles={(row) => {
            if (
              row.original.status === 'CANCELLED' &&
              !dndRowsOptions.selectedItemIds?.includes(row.original.jobId)
            ) {
              return {
                backgroundColor: theme.palette.warning.light,
                '&:hover': {
                  backgroundColor: theme.palette.warning.light,
                },
              };
            }
          }}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
      <SmartRecommendationDialog
        isOpen={Boolean(smartRecommendationJob)}
        tourJobId={smartRecommendationJob}
        onAction={(selectedJob) => {
          if (!smartRecommendationJob) {
            return;
          }
          dndRowsOptions?.onDrop?.([selectedJob.jobId], smartRecommendationJob);
        }}
        onCancel={handleCloseSmartRecommendationDialog}
      />
    </>
  );
};
