import {
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  ESPopover,
  ESTable,
  ESTableBody,
  ESTableFilters,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useESTableServer,
  useElementScrollRestoration,
} from '@energy-stacks/core/ui';
import {
  TourModel,
  TourPageModel,
  tourStatuses,
} from '@energy-stacks/fleet/feature-tours-data';
import {
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnFilter,
  ClearFiltersButton,
  TableColumnSelect,
  TableDateRangeFilter,
  useAppLocation,
  formatDateInTimezone,
} from '@energy-stacks/shared';
import { Typography, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { Row, createColumnHelper } from '@tanstack/react-table';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TourStatusChip } from './TourStatusChip';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { differenceInSeconds } from 'date-fns';
import { ToursDurationCell } from './shared/ToursDurationCell';
import { ToursQuantityCell } from './shared/ToursQuantityCell';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmedTime,
  ConfirmedDuration,
  ForecastedTime,
  FleetRoutes,
  ServerSideTableProps,
} from '@energy-stacks/fleet/shared';
import { TableVehicleFilter } from '@energy-stacks/fleet/feature-vehicles';
import { TourIdCell } from './TourIdCell';
import { ContaminationGlobalAlert } from './create-tour-steps/ContaminationGlobalAlert';
import { TourDurationCellEmptyRuns } from './shared/TourDurationCellEmptyRuns';
import { ToursTableMoreOptionsMenu } from './ToursTableMoreOptionsMenu';
import { ToursTableVehicleCell } from './shared/ToursTableVehicleCell';
import { ToursTableProgressCell } from './shared/ToursTableProgressCell';

const TABLE_SCROLL_RESTORATION_ID = 'toursTable';

interface ToursTableProps extends ServerSideTableProps<TourPageModel> {
  tours: TourPageModel;
  enableColumnSelection?: boolean;
}

export const ToursTable: FC<ToursTableProps> = ({
  tableId = 'tours',
  tours,
  enableColumnSelection,
  onPaginationChange,
  onSortingChange,
  sorting,
  search,
  setSearch,
  pagination,
  setFilters,
  isFetching: isFetchingTours,
  tableColumnFilters,
}) => {
  const [t] = useTranslation('tours');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TourModel>();

  const columns = [
    columnHelper.accessor('vehicle.id', {
      id: 'vehicle',
      header: () => t('vehicleColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { brandId, name, isDeleted } = row.original.vehicle;

        return (
          <ToursTableVehicleCell
            brandId={brandId}
            vehicleName={name}
            isVehicleDeleted={isDeleted}
            isViolatingContaminationRules={
              row.original.isViolatingContaminationRules
            }
            isViolatingTimeWindow={row.original.isViolatingTimeWindow}
            hasCancelledJobs={row.original.jobsCountPerStatus['CANCELLED'] > 0}
          />
        );
      },
      enableGlobalFilter: true,
      size: 270,
      enableSorting: false,
    }),
    columnHelper.accessor('tourId', {
      header: () => t('tourIdColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourIdCell tourId={info.getValue()} />,
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('status', {
      header: () => t('statusColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourStatusChip status={info.getValue()} />,
      size: 150,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('date', {
      header: () => t('dateColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        return (
          <Typography variant="inherit">
            {formatDateInTimezone(
              row.original.date,
              undefined,
              row.original.timeZoneId
            )}
          </Typography>
        );
      },
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('startDate', {
      header: () => t('startTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { timeZoneId } = row.original;
        return (
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="inherit">
              {formatDateInTimezone(
                row.original.startDate,
                'HH:mm',
                timeZoneId
              )}
            </Typography>
            {row.original.confirmedStartDate ? (
              <>
                <Box>•</Box>
                <ConfirmedTime
                  confirmedTime={row.original.confirmedStartDate}
                  plannedTime={row.original.startDate}
                  timeZoneId={timeZoneId}
                />
              </>
            ) : null}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('endDate', {
      header: () => t('endTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { timeZoneId } = row.original;
        return (
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="inherit">
              {formatDateInTimezone(row.original.endDate, 'HH:mm', timeZoneId)}
            </Typography>
            {row.original.confirmedEndDate ? (
              <>
                <Box>•</Box>
                <ConfirmedTime
                  confirmedTime={row.original.confirmedEndDate}
                  plannedTime={row.original.endDate}
                  timeZoneId={timeZoneId}
                />
              </>
            ) : null}
            {row.original.forecastedEndDate &&
              !row.original.confirmedEndDate && (
                <>
                  <Box>•</Box>
                  <ForecastedTime
                    forecastedTime={row.original.forecastedEndDate}
                    timeZoneId={timeZoneId}
                  />
                </>
              )}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('duration.time', {
      header: () => t('durationColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { startDate, endDate, duration, emptyRunsMetrics } = row.original;
        return (
          <Stack gap={1}>
            <ToursDurationCell
              startTime={startDate}
              endTime={endDate}
              distance={duration.distance}
            />
            {row.original.confirmedStartDate &&
            row.original.confirmedEndDate ? (
              <ConfirmedDuration
                confirmedStartTime={row.original.confirmedStartDate}
                confirmedEndTime={row.original.confirmedEndDate}
                plannedDuration={Math.abs(
                  differenceInSeconds(
                    new Date(row.original.startDate),
                    new Date(row.original.endDate)
                  )
                )}
              />
            ) : null}
            {emptyRunsMetrics.emptyRunsTotalNumber ? (
              <TourDurationCellEmptyRuns
                emptyRunsCount={emptyRunsMetrics.emptyRunsTotalNumber}
                totalEmptyRunsDurationInSeconds={
                  emptyRunsMetrics.emptyRunsTotalDurationSeconds
                }
              />
            ) : null}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('totalJobs', {
      id: 'jobs',
      header: () => t('jobsColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => row.original.totalJobs,
      enableGlobalFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('quantity', {
      header: () => t('quantityColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => <ToursQuantityCell quantity={row.original.quantity} />,
      enableSorting: false,
    }),
    columnHelper.accessor('jobsCountPerStatus', {
      id: 'progress',
      header: () => t('progressColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const doneJobsCount = info.getValue().DONE;
        const totalJobs = info.row.original.totalJobs;
        return (
          <ToursTableProgressCell
            doneJobsCount={doneJobsCount}
            totalJobsCount={totalJobs}
          />
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('isViolatingContaminationRules', {
      id: 'isViolatingContaminationRules',
      header: () => null,
      size: 0,
      enableHiding: false,
    }),
    columnHelper.accessor('note', {
      id: 'note',
      header: () => t('noteColumnHeader'),
      cell: (info) => (
        <ESPopover content={info.getValue()} breakWord>
          <Typography>{info.getValue()}</Typography>
        </ESPopover>
      ),
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actionsColumnLabel'),
      enableResizing: false,
      meta: { isActionable: true },
      cell: ({ row }) => <ToursTableMoreOptionsMenu tour={row.original} />,
    }),
  ];

  const { instance, rows } = useESTableServer(tours.tours ?? [], columns, {
    tableId,
    state: {
      pagination,
      sorting,
      columnFilters: tableColumnFilters,
      globalFilter: search,
    },
    // fitRowHeight: 74,
    enableColumnResizing: true,
    hiddenColumnsIds: ['isViolatingContaminationRules'],
    initialColumnVisibility: { tourId: false },
    onSortingChange,
    onPaginationChange,
    rowCount: tours?.totalElements,
    onColumnFiltersChange: setFilters,
  });

  const scrollCache = useElementScrollRestoration({
    id: TABLE_SCROLL_RESTORATION_ID,
  });

  const location = useAppLocation();
  const handleRowClick = useCallback(
    (row: Row<TourModel>) =>
      navigate(`${FleetRoutes.Tours}/${row.original.tourId}`, {
        state: { from: location },
      }),
    [location, navigate]
  );

  const toursWithViolationsCount = rows.filter(
    (row) => row.original.isViolatingContaminationRules
  ).length;

  const dateRange = instance.getColumn('date')?.getFilterValue() as
    | DateRange
    | undefined;

  return (
    <>
      {toursWithViolationsCount > 0 && (
        <Box marginBottom={8}>
          <ContaminationGlobalAlert
            count={toursWithViolationsCount}
            isSwitchOn={
              !!instance
                .getColumn('isViolatingContaminationRules')
                ?.getFilterValue()
            }
            onSwitchChange={(value) => {
              instance
                .getColumn('isViolatingContaminationRules')
                ?.setFilterValue(value ? value : undefined);
            }}
          />
        </Box>
      )}
      <ESTableFilters>
        <ESTooltip title={t('searchToursPlaceholder')}>
          <TableSearchField
            placeholder={t('searchToursPlaceholder')}
            value={search}
            onChange={setSearch}
            tableInstance={instance}
          />
        </ESTooltip>
        <TableDateRangeFilter
          testId="tours"
          title={tShared('timeRange')}
          weekStartsOn={1}
          selectablePassive
          isActive={dateRange !== undefined}
          dateRange={dateRange}
          column={instance.getColumn('date')}
        />
        <TableColumnFilter
          column={instance.getColumn('status')}
          columnLabel={t('statusColumnHeader')}
          options={tourStatuses.map((s) => ({
            label: t(`tourStatuses.${s}`),
            value: s,
          }))}
          isClearable
        />
        <TableVehicleFilter column={instance.getColumn('vehicle')} />
        <ClearFiltersButton tableInstance={instance} />
        {enableColumnSelection ? (
          <Box sx={{ marginLeft: 'auto' }}>
            <TableColumnSelect instance={instance} />
          </Box>
        ) : null}
      </ESTableFilters>
      <ESTableWrapper>
        <ESTable
          instance={instance}
          scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
          initialScrollTop={scrollCache.scrollY}
        >
          <ESTableHead showProgress={isFetchingTours} instance={instance} />
          <ESTableBody
            table={instance}
            rows={rows}
            onRowClick={handleRowClick}
          />
        </ESTable>
        {rows.length === 0 ? (
          <NoTableData message={t('noToursTableMessage')} />
        ) : null}
        <ESTablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          instance={instance}
          count={tours?.totalElements || rows.length}
        />
      </ESTableWrapper>
    </>
  );
};
