import {
  appendZToDateString,
  zeroOutSecondsAndMilliseconds,
} from '@energy-stacks/shared';
import { TourDto } from '../tourDto';
import { TourModel } from '../tourModel';
import { jobStatusMap, jobStatusesDto } from '@energy-stacks/fleet/shared';
import {
  emptyRunsNormalizer,
  emptyRunsMetricsNormalizer,
} from './emptyRunsNormalizer';
import { transitionsNormalizer } from './transitionsNormalizer';
import { addSeconds, differenceInSeconds } from 'date-fns';

export const toursNormalizer = (toursDto: TourDto[]): TourModel[] => {
  return toursDto.map(tourNormalizer);
};

export const tourNormalizer = (tourDto: TourDto): TourModel => {
  const confirmedStartDateDto = tourDto.confirmedStartDate;
  const confirmedEndDateDto = tourDto.confirmedEndDate;

  const confirmedStartDate = appendZToDateString(confirmedStartDateDto);
  const confirmedEndDate = appendZToDateString(confirmedEndDateDto);

  // Needed in order for rounding to work.
  // If displayed duration is computed from startDate and andDate there could be mismatch with totalTime BE field
  const durationWithoutSeconds = Math.abs(
    differenceInSeconds(
      zeroOutSecondsAndMilliseconds(new Date(tourDto.startDate)),
      zeroOutSecondsAndMilliseconds(new Date(tourDto.endDate))
    )
  );

  return {
    tourId: tourDto.tourUid,
    plantId: tourDto.depotId,
    status: tourDto.status || 'UNKNOWN',
    // NOTE: If polyline doesn't exist BE will return "UNAVAILABLE", so we don't show anything on the map
    polyline: tourDto.polyline === 'UNAVAILABLE' ? '' : tourDto.polyline,
    date: appendZToDateString(tourDto.startDate),
    startDate: appendZToDateString(tourDto.startDate),
    endDate: appendZToDateString(tourDto.endDate),
    confirmedStartDate,
    confirmedEndDate,
    forecastedEndDate:
      confirmedStartDateDto && !confirmedEndDateDto
        ? zeroOutSecondsAndMilliseconds(
            addSeconds(new Date(confirmedStartDate), durationWithoutSeconds)
          ).toISOString()
        : null,
    duration: {
      time: durationWithoutSeconds,
      distance: tourDto.totalDistance,
    },
    totalJobs: tourDto.numberOfJobs,
    quantity: tourDto.productQuantities,
    vehicle: {
      brandId: tourDto.vehicle.brandUuid,
      name: tourDto.vehicle.name,
      id: tourDto.vehicle.vehicleIdentificationNumber,
    },
    isViolatingContaminationRules: tourDto.hasPenaltyRuleViolation,
    jobsCountPerStatus: jobsStatusProgressNormalizer(
      tourDto.jobsStatusProgress
    ),
    emptyRunsMetrics: emptyRunsMetricsNormalizer(tourDto.transitionMetrics),
    emptyRuns: tourDto.transitionMetrics?.transitions
      ? emptyRunsNormalizer(
          tourDto.transitionMetrics.transitions.filter((t) => t.vehicleIsEmpty)
        )
      : [],
    transitions: tourDto.transitionMetrics
      ? transitionsNormalizer(tourDto.transitionMetrics.transitions)
      : [],
    note: tourDto.notes || '',
  };
};

const jobsStatusProgressNormalizer = (
  jobsStatusProgress: TourDto['jobsStatusProgress']
): TourModel['jobsCountPerStatus'] => {
  const jobsCountPerStatus = {} as TourModel['jobsCountPerStatus'];
  jobStatusesDto.forEach((statusDto) => {
    jobsCountPerStatus[jobStatusMap[statusDto]] =
      jobsStatusProgress[statusDto]?.length ?? 0;
  });
  return jobsCountPerStatus;
};
