import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { EditingTourJob } from './editingTourJobModel';

export const mapJobModelToEditingTourJob = (job: JobModel): EditingTourJob => {
  return {
    jobId: job.jobId,
    plantId: job.plantId,
    supplier: job.supplier,
    jobType: job.jobType || 'INBOUND',
    startTime: '', // No value since job will always be in status TO_DO and not have planned start time
    endTime: '', // No value since job will always be in status TO_DO and not have planned end time
    timeZoneId: job.timeZoneId,
    visitOrder: 0,
    duration: 0,
    product: job.product,
    destination: job.destination,
    origin: job.origin,
    contaminationViolation: null,
    status: job.status,
    estimatedDuration: job.estimatedDuration,
    dueDate: job.dueDate,
    confirmedEndTime: null,
    confirmedStartTime: null,
    businessAccountUid: job.businessAccountUid,
    dateDropOffFrom: job.dateDropOffFrom,
    datePickUpFrom: job.datePickUpFrom,
    dateDropOffTill: job.dateDropOffTill,
    datePickUpTill: job.datePickUpTill,
  };
};
