export const BrokerRoutes = {
  Dashboard: '/dashboard',
  AlertManagement: 'alert-management',
  ChargingStations: '/charging-stations',
  ChargingSchedule: '/charging-schedule',
  AddChargingStation: 'add',
  DeleteChargingStation: ':identityKey/delete',
  ChargingStationDetails: ':identityKey',
  ChargingStationDetailsConfigurationTab: '16/configuration',
  ChargingStationDetailsConfigurationTabEdit: 'edit',
  EditChargingStation: 'edit',
  EditChargingStationFromTable: ':identityKey/edit',
  Locations: '/charging-sites',
  LocationDetails: ':locationId/details',
  AssignCSToLocation: ':locationUuid/assign',
  AddLocation: 'add',
  EditLocation: ':locationUuid/edit',
  DeleteLocation: ':locationUuid/delete',
  Transactions: '/transactions',
  Users: '/users',
  UserAccessRequests: 'requests',
  EditUser: ':userId/edit',
  DeleteUser: ':userId/delete',
  ChargingStationDetailsGeneralTab: '16/general',
  ChargingStationDetailsLogsTab: '16/logs',
  ChargingStationDetailsNetworkLogsTab: '16/network-logs',
  ChargingStationDetailsTransactionsTab: '16/transactions',
  ChargingStationDetailsGeneralTabV201: '201/general',
  ChargingStationDetailsConnectorsTabV201: '201/connectors',
  ChargingStationDetailsConfigurationTabV201: '201/configuration',
  ChargingStationDetailsTransactionsTabV201: '201/transactions',
  ChargingStationDetailsLogsTabV201: '201/logs',
  ChargingStationDetailsSecurityEventsTabV201: '201/security-events',
  Settings: '/settings',
  CentralSystems: '/central-systems',
  DeleteCentralSystem: ':centralSystemId/delete',
  EditCentralSystem: ':centralSystemId/edit',
  AddCentralSystem: 'add',
  SettingsProfileTab: 'profile',
  SettingsSystemConfigurationTab: 'system-configuration',
  SettingsApiKeysTab: 'api-keys',
  DeleteApiKey: ':apiKeyId/delete',
  AddApiKey: 'add',
  Tariffs: '/tariffs',
  AddTariff: 'add',
  EditTariff: ':tariffId/edit',
  ChargingStationDetailsAlertsTab: '16/alerts',
};
