import { TableColumnFilter } from '@energy-stacks/shared';
import { Column } from '@tanstack/react-table';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useVehicles } from './useVehicles';
import { VehicleModel } from '@energy-stacks/fleet/feature-vehicles-data';
import { useUpdateEffect } from 'usehooks-ts';

type TableVehicleFilterProps<T> = {
  column: Column<T, unknown> | undefined;
  onApplyFilter?: (value: string[]) => void;
  vehicles?: VehicleModel[];
};

export const ToursTableVehicleFilter = <T,>({
  column,
  onApplyFilter,
  vehicles,
}: TableVehicleFilterProps<T>) => {
  const { t } = useTranslation('vehicles');
  const { data } = useVehicles({
    skip: !!vehicles,
  });
  const vehicleOptions = useMemo(() => {
    return vehicles ? vehicles : data ? data : [];
  }, [data, vehicles]);

  const filterOptions = useMemo(() => {
    const optionsArray = vehicleOptions.map((v) => ({
      label: v.name,
      value: v.name,
    }));
    return uniqBy(optionsArray, (el) => el.value);
  }, [vehicleOptions]);

  useUpdateEffect(() => {
    if (!column) {
      return;
    }
    if (column.getIsFiltered()) {
      column.setFilterValue((previousFilters: string[]) => {
        const newFilters = previousFilters.filter(
          (value) => !!vehicleOptions.find((vehicle) => vehicle.name === value)
        );
        onApplyFilter?.(newFilters);
        return newFilters.length ? newFilters : undefined;
      });
    }
  }, [vehicleOptions]);

  return (
    <TableColumnFilter
      column={column}
      columnLabel={t('tableVehicleFilterLabel')}
      onApplyFilter={onApplyFilter}
      options={filterOptions}
      isClearable
    />
  );
};
