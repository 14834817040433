import { ESTableFooter, PortalTarget } from '@energy-stacks/core/ui';
import { Stack, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface TableRowsCountProps<T> {
  instance: Table<T>;
  totalCountLabel?: string;
  isTablePaginated?: boolean;
}

const getIsRowSelectionEnabled = <T,>(instance: Table<T>) => {
  return !!instance.options.enableRowSelection;
};

export const TableRowsCount = <T,>({
  instance,
  totalCountLabel,
  isTablePaginated = false,
}: TableRowsCountProps<T>) => {
  const { t } = useTranslation('shared');

  const isRowSelectionEnabled = getIsRowSelectionEnabled(instance);

  const rowSelectionState = instance.getState().rowSelection;
  const selectedRowsCount = useMemo(
    () => Object.keys(rowSelectionState).length,
    [rowSelectionState]
  );

  if (isTablePaginated && !isRowSelectionEnabled) {
    return null;
  }

  if (isTablePaginated && isRowSelectionEnabled) {
    return (
      <PortalTarget targetId="paginatorRef">
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ position: 'absolute', top: 16, left: 16 }}
        >
          {t('rowsSelected', {
            count: selectedRowsCount,
          })}
        </Typography>
      </PortalTarget>
    );
  }

  return (
    <ESTableFooter>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {isRowSelectionEnabled ? (
          <Typography variant="h6" color="textSecondary">
            {t('rowsSelected', {
              count: selectedRowsCount,
            })}
          </Typography>
        ) : null}
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          marginLeft={!isRowSelectionEnabled ? 'auto' : 0}
        >
          <Typography variant="h6" fontWeight="400" color="grey.600">
            {totalCountLabel ? totalCountLabel : t('totalRows')}
          </Typography>
          <Typography variant="h6" fontWeight="400" color="grey.900">
            {instance.getFilteredRowModel().rows.length}
          </Typography>
        </Stack>
      </Stack>
    </ESTableFooter>
  );
};
