import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { Box, Typography } from '@mui/material';
import { isInteger } from 'lodash-es';
import { FC } from 'react';

type JobDurationCellProps = {
  duration: JobModel['estimatedDuration'];
};

export const JobDurationCell: FC<JobDurationCellProps> = ({ duration }) => {
  return duration ? (
    <Box>
      <Typography variant="inherit" fontWeight={400}>
        {`${duration.timeMinutes}min`}
        <Box component="span" sx={{ mx: 1 }}>
          •
        </Box>
        {`${
          isInteger(duration.distanceKilometers)
            ? duration.distanceKilometers
            : duration.distanceKilometers.toFixed(1)
        }km`}
      </Typography>
    </Box>
  ) : (
    <Typography>---</Typography>
  );
};
