import {
  useAppDispatch,
  useAppSelector,
  setSelectedPlantId,
} from '@energy-stacks/fleet/store';
import { ReactComponent as BiogassFactoryIcon } from './icons/biogassFactoryIcon.svg';
import { SidebarSelect } from '@energy-stacks/core/sidebar';
import { useNavigate } from 'react-router-dom';
import { ESRoutes } from '@energy-stacks/shared';

export const PlantIdSelect = () => {
  const dispatch = useAppDispatch();
  const plantIds = useAppSelector((state) => state.plant.plantIds);
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );
  const navigate = useNavigate();

  if (!plantIds || !selectedPlantId) {
    return null;
  }

  return (
    <SidebarSelect
      icon={<BiogassFactoryIcon />}
      onSelectionChange={(value) => {
        if (value === selectedPlantId) {
          return;
        }
        dispatch(setSelectedPlantId(value));
        setTimeout(() => {
          navigate(ESRoutes.Root);
        }, 0);
      }}
      selectedValue={selectedPlantId}
      options={plantIds.map((plantId) => ({ value: plantId, label: plantId }))}
      sidebarClosedDisplayComponent="value"
      testId="PlantIdSelect"
    />
  );
};
