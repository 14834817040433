import { LONGITUDE_REGEX } from '@energy-stacks/shared';
import { LATITUDE_REGEX } from '@energy-stacks/shared';
import { LATITUDE_OCPI_REGEX } from '@energy-stacks/shared';
import { LONGITUDE_OCPI_REGEX } from '@energy-stacks/shared';
import { IDENTITY_KEY_REGEX } from '@energy-stacks/shared';
import * as yup from 'yup';
import {
  EVSE_ID_SPECIAL_CHARACTERS_REGEX,
  numberValidityTest,
} from '@energy-stacks/shared';

const generalDetailsFormValidationSchema = yup.object().shape({
  identityKey: yup
    .string()
    .trim()
    .required('identityKeyRequired')
    .max(32, 'identityKeyTooLong')
    .matches(IDENTITY_KEY_REGEX, {
      message: 'identityKeySpecialCharactersError',
      excludeEmptyString: true,
    }),
  chargePointName: yup.string().trim().max(255, 'chargePointNameTooLong'),
  centralSystem: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required('centralSystemRequired'),
  }),
  coordinates: yup.object().shape({
    longitude: yup
      .string()
      .matches(LONGITUDE_REGEX, {
        message: 'longitudeValidation',
        excludeEmptyString: true,
      })
      .matches(LONGITUDE_OCPI_REGEX, {
        message: 'coordinateDecimals',
        excludeEmptyString: true,
      })
      .when('latitude', {
        is: (value: string) => !!value,
        then: yup.string().required('coordinatesDependencyErrorMessage'),
        otherwise: yup.string().nullable(),
      }),
    latitude: yup.lazy(() =>
      yup
        .string()
        .matches(LATITUDE_REGEX, {
          message: 'latitudeValidation',
          excludeEmptyString: true,
        })
        .matches(LATITUDE_OCPI_REGEX, {
          message: 'coordinateDecimals',
          excludeEmptyString: true,
        })
        .when('longitude', {
          is: (value: string) => !!value,
          then: yup.string().required('coordinatesDependencyErrorMessage'),
          otherwise: yup.string().nullable(),
        })
    ),
  }),
});

const connector = yup.object().shape({
  evseId: yup
    .string()
    .nullable()
    .max(48, 'evseIdTooLong')
    .matches(EVSE_ID_SPECIAL_CHARACTERS_REGEX, {
      message: 'evseIdPatternFormatError',
      excludeEmptyString: true,
    }),
  type: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  connectorFormat: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  powerType: yup.object({
    label: yup.string(),
    value: yup.string(),
  }),
  maxVoltage: numberValidityTest.optional(),
  maxAmperage: numberValidityTest.optional(),
  maxPower: numberValidityTest.optional(),
});

export const connectorsFormValidationSchema = yup.object({
  connectors: yup.array().of(connector),
});

export const brokerAddChargePointFormValidationSchema = {
  generalDetails: generalDetailsFormValidationSchema,
  addConnectors: connectorsFormValidationSchema,
  overview: yup.object().shape({}),
};
