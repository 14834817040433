import { SearchTextField, TableFilterButton } from '@energy-stacks/shared';
import {
  Box,
  colors,
  ListItemText,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TourDetailsVehicleFilterProps {
  vehicleFilter: string;
  options: string[];
  onVehicleFilterChange: (vehicle: string) => void;
  disabled?: boolean;
}

export const TourDetailsVehicleFilter: React.FC<
  TourDetailsVehicleFilterProps
> = ({ vehicleFilter, options, disabled, onVehicleFilterChange }) => {
  const [tShared] = useTranslation('shared');
  const [inputValue, setInputValue] = useState<string>('');

  const searchedOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );
  const handleFilterChange = useCallback(
    (filter: string) => {
      onVehicleFilterChange(filter);
    },
    [onVehicleFilterChange]
  );

  return (
    <TableFilterButton
      title={vehicleFilter || ''}
      disabled={disabled}
      renderPopover={(closePopover) => {
        return (
          <>
            <Box
              p={3}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: colors.common.white,
                zIndex: 2,
                '& .MuiInput-root': {
                  paddingY: 1,
                },
              }}
            >
              <SearchTextField
                value={inputValue}
                autoFocus
                onChange={setInputValue}
              />
            </Box>

            <Box
              py={3}
              sx={{
                maxHeight: 450,
                minWidth: 176,
              }}
            >
              {searchedOptions.length < 1 ? (
                <Box p={3}>
                  <ListItemText primary={tShared('noSearchOptions')} />
                </Box>
              ) : (
                <Box>
                  {searchedOptions.map((vehicleName) => {
                    return (
                      <MenuItem
                        dense
                        selected={vehicleName === vehicleFilter}
                        key={vehicleName}
                        onClick={() => {
                          handleFilterChange(vehicleName);
                          closePopover();
                        }}
                      >
                        <Radio
                          checked={vehicleName === vehicleFilter}
                          value={vehicleName}
                        />
                        <Typography>{vehicleName}</Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
            </Box>
          </>
        );
      }}
      isActive={Boolean(vehicleFilter)}
    />
  );
};
