import {
  ES_DATE_TIME_FORMAT_BE,
  fromZonedTimeToUtc,
  toPayloadDate,
} from '@energy-stacks/shared';
import { OptimizedTourJob, OptimizedTourModel } from './optimizedTourModel';
import { EditVirtualTourDetailsPayload } from './editVirtualTourDetailsPayload';
import { endOfDay, format, startOfDay } from 'date-fns';

export const editVirtualTourDetailsDataToPayload = ({
  tourDetails,
  previousTourJobs,
  nextTourJobs,
}: {
  tourDetails: OptimizedTourModel;
  previousTourJobs?: OptimizedTourJob[];
  nextTourJobs?: OptimizedTourJob[];
}): EditVirtualTourDetailsPayload => {
  return {
    startRouteTime: format(
      fromZonedTimeToUtc(
        startOfDay(new Date(tourDetails.startDate)),
        tourDetails.timeZoneId
      ),
      ES_DATE_TIME_FORMAT_BE
    ),
    endRouteTime: format(
      fromZonedTimeToUtc(
        endOfDay(new Date(tourDetails.endDate)),
        tourDetails.timeZoneId
      ),
      ES_DATE_TIME_FORMAT_BE
    ),
    vehicleIdentificationNumber: tourDetails.vehicleId,
    jobs: tourDetails.tourJobs.jobs.map((job) => ({
      jobUID: job.jobId,
      jobPrecedence: job.visitOrder,
    })),
    precedenceTourRuleRequests:
      previousTourJobs || nextTourJobs
        ? {
            previousVirtualTourRuleRequests: previousTourJobs
              ? previousTourJobs.map((job) => {
                  return {
                    destinationLocationUid: job.destination?.id || '',
                    originLocationUid: job.origin?.id || '',
                    jobUid: job.jobId,
                    jobType: job.jobType,
                    jobStartDate: toPayloadDate(new Date(job.startTime)),
                    productUid: job.product?.id || '',
                    businessAccountUid: job.businessAccountUid,
                  };
                })
              : undefined,
            nextVirtualTourRuleRequests: nextTourJobs
              ? nextTourJobs.map((job) => {
                  return {
                    destinationLocationUid: job.destination?.id || '',
                    originLocationUid: job.origin?.id || '',
                    jobUid: job.jobId,
                    jobType: job.jobType,
                    jobStartDate: toPayloadDate(new Date(job.startTime)),
                    productUid: job.product?.id || '',
                    businessAccountUid: job.businessAccountUid,
                  };
                })
              : undefined,
          }
        : undefined,
  };
};
