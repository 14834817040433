import { createSearchParams, useNavigate } from 'react-router-dom';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { format } from 'date-fns';
import { IconCalendarDue } from '@tabler/icons-react';
import { ButtonBase, Typography } from '@mui/material';
import {
  ESTooltip,
  formatDate,
  useAppLocation,
  usePromptDisplay,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { TourInfoHeaderItemLayout } from '../shared/TourInfoHeaderItemLayout';

export const TourInfoStartDateButton: React.FC<{
  startDate: string;
  shouldPromptBeforeNavigate?: boolean;
}> = ({ startDate, shouldPromptBeforeNavigate = false }) => {
  const [t] = useTranslation('tours');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const location = useAppLocation();

  const isEnabled = location.pathname !== FleetRoutes.CreateTour;
  const isFromDashboard =
    location.state?.from &&
    location.state.from.pathname.includes(FleetRoutes.Dashboard);

  const handleNavigateToTours = usePromptDisplay({
    onAction: () => {
      if (!isEnabled) {
        return;
      }
      navigate({
        pathname: isFromDashboard ? FleetRoutes.Dashboard : FleetRoutes.Tours,
        search: createSearchParams({
          startDateFilter: format(new Date(startDate), 'dd-MM-yyyy'),
        }).toString(),
      });
    },
    promptMessage: tShared('closeConfirmContent'),
    shouldPrompt: shouldPromptBeforeNavigate,
  });

  return (
    <ESTooltip
      title={
        isEnabled
          ? isFromDashboard
            ? t('backToDashboardOverview')
            : t('backToTourOverview')
          : ''
      }
    >
      <ButtonBase disabled={!isEnabled} onClick={handleNavigateToTours}>
        <TourInfoHeaderItemLayout Icon={IconCalendarDue}>
          <Typography variant="body1" color="grey.500">
            {formatDate(startDate)}
          </Typography>
        </TourInfoHeaderItemLayout>
      </ButtonBase>
    </ESTooltip>
  );
};
