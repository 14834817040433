import { ESTooltip } from '@energy-stacks/shared';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SmartRecommendationTableCellProps = {
  onCTA: () => void;
  shouldEnable?: boolean;
};

export const SmartRecommendationTableCell: React.FC<
  SmartRecommendationTableCellProps
> = ({ onCTA, shouldEnable }) => {
  const [t] = useTranslation('tours');

  return (
    <ESTooltip title={t('smartRecommendation')} placement="bottom">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onCTA();
        }}
        sx={shouldEnable ? { zIndex: 11, pointerEvents: 'auto' } : undefined}
      >
        <SmartRecommendationIcon />
      </IconButton>
    </ESTooltip>
  );
};

const SmartRecommendationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9242_60571)">
        <path
          d="M16 18C16.5304 18 17.0391 18.2107 17.4142 18.5858C17.7893 18.9609 18 19.4696 18 20C18 19.4696 18.2107 18.9609 18.5858 18.5858C18.9609 18.2107 19.4696 18 20 18C19.4696 18 18.9609 17.7893 18.5858 17.4142C18.2107 17.0391 18 16.5304 18 16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18ZM16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8C18 7.46957 18.2107 6.96086 18.5858 6.58579C18.9609 6.21071 19.4696 6 20 6C19.4696 6 18.9609 5.78929 18.5858 5.41421C18.2107 5.03914 18 4.53043 18 4C18 4.53043 17.7893 5.03914 17.4142 5.41421C17.0391 5.78929 16.5304 6 16 6ZM9 18C9 16.4087 9.63214 14.8826 10.7574 13.7574C11.8826 12.6321 13.4087 12 15 12C13.4087 12 11.8826 11.3679 10.7574 10.2426C9.63214 9.11742 9 7.5913 9 6C9 7.5913 8.36786 9.11742 7.24264 10.2426C6.11742 11.3679 4.5913 12 3 12C4.5913 12 6.11742 12.6321 7.24264 13.7574C8.36786 14.8826 9 16.4087 9 18Z"
          stroke="#697586"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9242_60571">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
