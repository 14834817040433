import {
  ESMenuItem,
  ESTableBasicConfig,
  ESTableFilters,
  ESTableWrapper,
  ESVirtualizedTable,
  useESTableBasic,
  useElementScrollRestoration,
} from '@energy-stacks/core/ui';
import {
  TourModel,
  tourStatuses,
} from '@energy-stacks/fleet/feature-tours-data';
import {
  ESTooltip,
  TableSearchField,
  TableColumnFilter,
  formatDate,
  ClearFiltersButton,
  TableColumnSelect,
  MoreOptionsMenu,
  NoTableData,
  TableRowsCount,
  TableDateRangeFilter,
} from '@energy-stacks/shared';
import { Typography, Box, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  endOfDay,
  format,
  isAfter,
  isEqual,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import {
  ToursTableVehicleFilter,
  VehicleLogo,
} from '@energy-stacks/fleet/feature-vehicles';
import { NoTours } from './NoTours';
import {
  ContaminationGlobalAlert,
  EditTourMenuItem,
  TourDurationCellEmptyRuns,
  TourIdCell,
  TourStatusChip,
  ToursDurationCell,
  ToursQuantityCell,
  sortByQuantity,
} from '@energy-stacks/fleet/feature-tours';
import { ImportToursButton } from '@energy-stacks/fleet-is/shared';

const DATE_QUERY_PARAM_FORMAT = 'dd-MM-yyyy';
const TABLE_SCROLL_RESTORATION_ID = 'toursTable';

interface ToursTableProps extends ESTableBasicConfig<TourModel> {
  tours: TourModel[];
  enableColumnSelection?: boolean;
  isFilterable?: boolean;
}

export const ToursTable: FC<ToursTableProps> = ({
  tableId = 'tours',
  tours,
  enableColumnSelection,
  isFilterable = true,
}) => {
  const [t] = useTranslation('tours');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper<TourModel>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isContaminationFilterActive, setIsContaminationFilterActive] =
    useState(searchParams.get('contamination') === 'true' ? true : false);
  const toursWithViolations = useMemo(
    () => tours.filter((tour) => tour.isViolatingContaminationRules),
    [tours]
  );
  if (!toursWithViolations.length && isContaminationFilterActive) {
    setIsContaminationFilterActive(false);
  }
  const tableTours = isContaminationFilterActive ? toursWithViolations : tours;

  const sortByProgress = useCallback(
    (rowA: Row<TourModel>, rowB: Row<TourModel>) => {
      const progressA =
        (rowA.original.jobsCountPerStatus.DONE * 100) / rowA.original.totalJobs;
      const progressB =
        (rowB.original.jobsCountPerStatus.DONE * 100) / rowB.original.totalJobs;
      return progressA - progressB;
    },
    []
  );

  const columns = [
    columnHelper.accessor('tourId', {
      sortingFn: 'alphanumeric',
      header: () => t('tourIdColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <TourIdCell
          tourId={info.getValue()}
          hasViolation={info.row.original.isViolatingContaminationRules}
        />
      ),
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('status', {
      header: () => t('statusColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => <TourStatusChip status={info.getValue()} />,
      filterFn: 'arrIncludesSome',
      size: 150,
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('date', {
      header: () => t('dateColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">{formatDate(info.getValue())}</Typography>
      ),
      enableColumnFilter: true,
      filterFn: (row, _, filterValue: DateRange | undefined) => {
        if (!filterValue) {
          return true;
        }

        return isWithinInterval(parseISO(row.original.date), {
          start: filterValue.startDate,
          end: filterValue.endDate,
        });
      },
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('startDate', {
      header: () => t('startTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {formatDate(info.getValue(), 'hh:mm a').toLowerCase()}
        </Typography>
      ),
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('endDate', {
      header: () => t('endTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {formatDate(info.getValue(), 'hh:mm a').toLowerCase()}
        </Typography>
      ),
      enableResizing: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('duration.time', {
      header: () => t('durationColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { startDate, endDate, duration, emptyRunsMetrics } = row.original;
        return (
          <Stack>
            <ToursDurationCell
              startTime={startDate}
              endTime={endDate}
              distance={duration.distance}
            />
            {emptyRunsMetrics.emptyRunsTotalNumber ? (
              <TourDurationCellEmptyRuns
                emptyRunsCount={emptyRunsMetrics.emptyRunsTotalNumber}
                totalEmptyRunsDurationInSeconds={
                  emptyRunsMetrics.emptyRunsTotalDurationSeconds
                }
              />
            ) : null}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('totalJobs', {
      id: 'jobs',
      header: () => t('jobsColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => row.original.totalJobs,
      enableResizing: false,
      size: 80,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('quantity', {
      header: () => t('quantityColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => <ToursQuantityCell quantity={row.original.quantity} />,
      sortingFn: sortByQuantity<TourModel>,
      size: 180,
    }),
    columnHelper.accessor('jobsCountPerStatus', {
      id: 'progress',
      header: () => t('progressColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const doneJobs = info.getValue().DONE;
        const totalJobs = info.row.original.totalJobs;
        return (
          <Stack direction="row" rowGap={2} alignItems="center">
            <CircularProgress
              value={100}
              variant="determinate"
              size={20}
              thickness={5.5}
              sx={{
                position: 'absolute',
                color: doneJobs ? 'success.light' : 'grey.300',
              }}
            />
            <CircularProgress
              value={(doneJobs * 100) / totalJobs}
              variant="determinate"
              size={20}
              thickness={5.5}
              sx={{
                color: 'success.dark',
                mr: 3,
              }}
            />
            <Typography variant="inherit" fontWeight={400}>
              {`${doneJobs}/${totalJobs}`}
            </Typography>
          </Stack>
        );
      },
      sortingFn: sortByProgress,
      enableResizing: false,
    }),
    columnHelper.accessor('vehicle.name', {
      id: 'vehicle',
      header: () => t('vehicleColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { brandId, name } = row.original.vehicle;
        return (
          <Stack direction="row" gap={3} alignItems="center">
            <VehicleLogo brandId={brandId} />
            <Typography variant="inherit">{name}</Typography>
          </Stack>
        );
      },
      enableGlobalFilter: true,
      filterFn: 'arrIncludesSome',
      size: 200,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actionsColumnLabel'),
      meta: { isActionable: true },
      enableResizing: false,
      cell: ({ row }) => (
        <MoreOptionsMenu testId={row.original.tourId}>
          <EditTourMenuItem
            status={row.original.status}
            testId={row.original.tourId}
            onEnterEditMode={() => {
              navigate(`${FleetRoutes.Tours}/${row.original.tourId}`, {
                state: { from: location },
              });
            }}
          />
          <ESMenuItem
            testId={`${row.original.tourId}DeleteMenuItem`}
            onClick={() =>
              navigate(`${row.original.tourId}/delete`, {
                state: { background: location },
              })
            }
          >
            {t('deleteTourCTA')}
          </ESMenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const initalDateRangeFilterValue = useMemo(() => {
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    if (!startDateParam || !endDateParam) {
      return;
    }

    const startDate = startOfDay(
      parse(startDateParam, 'dd-MM-yyyy', new Date())
    );
    const endDate = endOfDay(parse(endDateParam, 'dd-MM-yyyy', new Date()));

    if (
      isValid(startDate) &&
      isValid(endDate) &&
      (isAfter(endDate, startDate) || isEqual(startDate, endDate))
    ) {
      return {
        id: 'date',
        value: {
          startDate,
          endDate,
        },
      };
    }
  }, [searchParams]);

  const { instance, rows, globalFilter, onGlobalFilterChange } =
    useESTableBasic(tableTours, columns, {
      tableId,
      fitRowHeight: 74,
      initialColumnFilters: initalDateRangeFilterValue
        ? [initalDateRangeFilterValue]
        : undefined,
      manualPagination: true,
      enableColumnResizing: true,
      persistSorting: true,
    });

  const removeDateRangeSearchParams = useCallback(() => {
    setSearchParams(
      (params) => {
        params.delete('startDate');
        params.delete('endDate');
        return params;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      setSearchParams(
        (params) => {
          if (dateRange) {
            params.set(
              'startDate',
              format(dateRange.startDate, DATE_QUERY_PARAM_FORMAT)
            );
            params.set(
              'endDate',
              format(dateRange.endDate, DATE_QUERY_PARAM_FORMAT)
            );
          }
          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const scrollCache = useElementScrollRestoration({
    id: TABLE_SCROLL_RESTORATION_ID,
  });

  const handleRowClick = useCallback(
    (row: Row<TourModel>) =>
      navigate(`${FleetRoutes.Tours}/${row.original.tourId}`, {
        state: { from: FleetRoutes.Tours },
      }),
    [navigate]
  );

  return (
    <>
      {toursWithViolations.length > 0 && (
        <Box marginBottom={8}>
          <ContaminationGlobalAlert
            count={toursWithViolations.length}
            isSwitchOn={isContaminationFilterActive}
            onSwitchChange={(value) => {
              setSearchParams(
                (params) => {
                  params.set('contamination', String(value));
                  return params;
                },
                { replace: true }
              );
              setIsContaminationFilterActive(value);
            }}
          />
        </Box>
      )}
      {isFilterable ? (
        <ESTableFilters>
          <ESTooltip title={t('searchToursPlaceholder')}>
            <TableSearchField
              placeholder={t('searchToursPlaceholder')}
              value={globalFilter}
              onChange={onGlobalFilterChange}
              tableInstance={instance}
            />
          </ESTooltip>
          <TableDateRangeFilter
            title={tShared('timeRange')}
            column={instance.getColumn('date')}
            onDateRangeApplied={handleDateRangeChange}
            onDateRangeCleared={removeDateRangeSearchParams}
          />
          <TableColumnFilter
            column={instance.getColumn('status')}
            columnLabel={t('statusColumnHeader')}
            options={tourStatuses.map((s) => ({
              label: t(`tourStatuses.${s}`),
              value: s,
            }))}
            isClearable
          />
          <ToursTableVehicleFilter column={instance.getColumn('vehicle')} />
          <ClearFiltersButton
            tableInstance={instance}
            clearFilters={() => {
              instance.setColumnFilters([]);
              removeDateRangeSearchParams();
            }}
          />
          {enableColumnSelection ? (
            <Box sx={{ marginLeft: 'auto', display: 'flex', marginRight: 0 }}>
              <TableColumnSelect instance={instance} />
              <ImportToursButton />
            </Box>
          ) : null}
        </ESTableFilters>
      ) : null}
      <ESTableWrapper>
        {tours.length ? (
          <>
            <ESVirtualizedTable
              instance={instance}
              rows={rows}
              onRowClick={handleRowClick}
              scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
              initialScrollTop={scrollCache.scrollY}
            />
            {rows.length === 0 ? (
              <NoTableData message={t('thereAreNoTours')} />
            ) : null}
            <TableRowsCount
              instance={instance}
              totalCountLabel={t('totalRowsCount')}
            />
          </>
        ) : (
          <NoTours />
        )}
      </ESTableWrapper>
    </>
  );
};
