import { BackendSortingOrderModel } from '@energy-stacks/core/ui';
import { DashboardTourDto } from './dashboardTourDto';
import { DashboardTourModel } from './dashboardTourModel';

type DashboardTableSortColumnsDto = keyof Pick<
  DashboardTourDto,
  'tourUid' | 'status' | 'startDate' | 'endDate' | 'totalTime' | 'notes'
>;

type DashboardTableSortColumns =
  | keyof Pick<
      DashboardTourModel,
      'tourId' | 'status' | 'startDate' | 'endDate' | 'note' | 'date'
    >
  | 'duration_time';

const sortColumnsMap: Record<
  DashboardTableSortColumns,
  DashboardTableSortColumnsDto
> = {
  duration_time: 'totalTime',
  startDate: 'startDate',
  endDate: 'endDate',
  note: 'notes',
  status: 'status',
  tourId: 'tourUid',
  date: 'startDate',
};

export type DashboardTableBackendSortOrderModel = BackendSortingOrderModel & {
  id: DashboardTableSortColumns;
};

export type DashboardTableSortOrderModel = BackendSortingOrderModel & {
  id: DashboardTableSortColumns;
};

export const mapSortIdToPayload = (
  sort: DashboardTableBackendSortOrderModel
): [string] => {
  return [`${sortColumnsMap[sort.id]},${sort.order}`];
};
