import { ColumnFiltersState, OnChangeFn, Updater } from '@tanstack/react-table';
import {
  endOfDay,
  isAfter,
  isValid as isValidDate,
  isEqual,
  parse,
  startOfDay,
  format,
} from 'date-fns';
import { isObject, uniq } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

const parseValue = (value: unknown[]) => {
  if (value.length !== 1) {
    return uniq(value);
  }

  if (value[0] === 'true') {
    return true;
  }

  if (value[0] === 'false') {
    return false;
  }

  return value;
};

export const parseDateRangeFromSearchParams = (
  searchParams: URLSearchParams
) => {
  const startDateString = searchParams.get('startDateFilter');
  const endDateString = searchParams.get('endDateFilter');

  if (!startDateString) {
    return null;
  }

  const startDate = startOfDay(
    parse(startDateString, 'dd-MM-yyyy', new Date())
  );

  const endDate = endDateString
    ? endOfDay(parse(endDateString, 'dd-MM-yyyy', new Date()))
    : null;

  if (isValidDate(startDate) && (!endDate || !isValidDate(endDate))) {
    return {
      startDate,
      endDate: endOfDay(startDate),
    };
  }

  if (endDate) {
    if (
      isValidDate(startDate) &&
      isValidDate(endDate) &&
      (isAfter(endDate, startDate) || isEqual(startDate, endDate))
    ) {
      return {
        startDate,
        endDate,
      };
    }
  }
  return null;
};

interface ColumnFilter<T extends object> {
  id: keyof T;
  value: unknown;
}

export const parseFiltersFromSearchParams = <T extends object>(
  searchParams: URLSearchParams
) => {
  const persistedColumnFilters: ColumnFilter<T>[] = [];
  const filterParamsKeys = Array.from(searchParams.keys()).filter((key) =>
    key.includes('Filter')
  );

  for (const key of filterParamsKeys) {
    const value = searchParams.getAll(key);

    // If filter is already present in persistedColumnFilters skip iteration
    if (
      persistedColumnFilters.find((filter) => {
        if (key === 'startDateFilter' || key === 'endDateFilter') {
          return filter.id === 'date';
        }

        return filter.id === key;
      })
    ) {
      continue;
    }
    // Handle date range. Needs special handling because it's a composite value
    if (key === 'startDateFilter' || key === 'endDateFilter') {
      const parsedDateRange = parseDateRangeFromSearchParams(searchParams);

      if (parsedDateRange) {
        persistedColumnFilters.push({
          id: 'date' as keyof T,
          value: parsedDateRange,
        });
      }
    } else {
      const parsedValue = parseValue(value);

      if (Array.isArray(parsedValue) && parsedValue[0] !== 'undefined') {
        persistedColumnFilters.push({
          id: key.replace('Filter', '') as keyof T,
          value: parsedValue,
        });
      }
    }
  }

  return persistedColumnFilters;
};

const removeFilterParams = (params: URLSearchParams) => {
  Array.from(params.keys())
    .filter((key) => key.includes('Filter'))
    .forEach((key) => params.delete(key));
};

export const useSyncTableFiltersWithSearchParams = (
  shouldPersist: boolean,
  initialFilters: ColumnFiltersState
): [ColumnFiltersState, OnChangeFn<ColumnFiltersState>] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialFiltersState = useMemo(() => {
    if (!shouldPersist) {
      return initialFilters;
    }
    // Default filters cleared manually by user
    if (searchParams.get('display') === 'all') {
      return [];
    }

    // Read initial state from params
    const persistedColumnFilters = parseFiltersFromSearchParams(searchParams);

    if (persistedColumnFilters.length) {
      return persistedColumnFilters;
    }

    return initialFilters;
  }, [initialFilters, searchParams, shouldPersist]);

  const setColumnFilterSearchParams = useCallback(
    (filters: ColumnFiltersState) => {
      // Handle "clear all filters" button
      if (!filters.length) {
        setSearchParams(
          (params) => {
            removeFilterParams(params);
            params.set('display', 'all');
            return params;
          },
          { replace: true }
        );
        return;
      }

      setSearchParams(
        (params) => {
          params.delete('display');
          removeFilterParams(params);
          filters.forEach((filter) => {
            const { id, value } = filter;
            if (isObject(value)) {
              if (Array.isArray(value)) {
                // Handle arrays
                value.forEach((val) => {
                  const existingParamValue = parseValue(
                    // If value is an array there is a possibility that multiple filters were selected,
                    // therefore we call params.getAll
                    params.getAll(`${id}Filter`)
                  );
                  if (
                    Array.isArray(existingParamValue)
                      ? !existingParamValue.includes(val)
                      : existingParamValue !== val
                  ) {
                    params.append(`${id}Filter`, val);
                  }
                });
              } else if ('startDate' in value && 'endDate' in value) {
                // Handle objects (only DateRange supported)
                params.set(
                  'startDateFilter',
                  format(value.startDate as Date, 'dd-MM-yyyy')
                );
                params.set(
                  'endDateFilter',
                  format(value.endDate as Date, 'dd-MM-yyyy')
                );
              }
            } else {
              if (parseValue([params.get(`${id}Filter`)]) !== value) {
                // Primitive
                params.append(`${id}Filter`, String(value));
              }
            }
          });
          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(initialFiltersState);

  const handleColumnFiltersChange = useCallback(
    (updater: Updater<ColumnFiltersState>) => {
      setColumnFilters(updater);
      if (shouldPersist) {
        const newColumnFilters =
          typeof updater === 'function' ? updater(columnFilters) : updater;
        setColumnFilterSearchParams(newColumnFilters);
      }
    },
    [columnFilters, setColumnFilterSearchParams, shouldPersist]
  );

  useEffectOnce(() => {
    if (shouldPersist) {
      setColumnFilterSearchParams(initialFiltersState);
    }
  });

  return [columnFilters, handleColumnFiltersChange];
};
