import { ESTextField, ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { useGetCoreVehiclesQuery } from '@energy-stacks/fleet/feature-vehicles-data';
import { Grid, TextField } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@energy-stacks/fleet/store';

type SelectOption = { label: string; value: string };

type EditVehicleGeneralInfoFields = {
  isVehicleOperating?: boolean;
};

export const EditVehicleGeneralInfoFields: React.FC<
  EditVehicleGeneralInfoFields
> = ({ isVehicleOperating = false }) => {
  const [t] = useTranslation('vehicles');
  const { control, formState, setValue } = useFormContext();
  const plantIds = useAppSelector((state) => state.plant.plantIds);

  const {
    data: coreVehicles,
    isLoading: isVehiclesLoading,
    isError: isVehiclesError,
  } = useGetCoreVehiclesQuery();

  const brandValue = useWatch({
    control,
    name: 'brand',
  });
  const modelValue = useWatch({
    control,
    name: 'model',
  });

  const brandOptions = useMemo(() => {
    const uniqueOptions: SelectOption[] = [];
    coreVehicles?.forEach((v) => {
      if (!uniqueOptions.find((o) => o.value === v.brandName))
        uniqueOptions.push({
          label: v.brandName,
          value: v.brandName,
        });
    });
    return uniqueOptions;
  }, [coreVehicles]);

  const modelOptions = useMemo(() => {
    const filteredVehicles = coreVehicles?.filter(
      (v) => v.brandName === brandValue?.value
    );
    const uniqueOptions: SelectOption[] = [];
    filteredVehicles?.forEach((v) => {
      if (!uniqueOptions.find((o) => o.value === v.model))
        uniqueOptions.push({
          label: v.model,
          value: v.model,
        });
    });
    return uniqueOptions;
  }, [coreVehicles, brandValue]);

  const variantOptions = useMemo(() => {
    const filteredVehicles = coreVehicles?.filter(
      (v) => v.model === modelValue?.value
    );
    const uniqueOptions: SelectOption[] = [];
    filteredVehicles?.forEach((v) => {
      if (!uniqueOptions.find((o) => o.value === v.variant))
        uniqueOptions.push({
          label: v.variant,
          value: v.variant,
        });
    });
    return uniqueOptions;
  }, [coreVehicles, modelValue]);

  const { errors } = formState;

  return (
    <>
      <Grid item xs={12}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ESVirtualizedAutocomplete
                required
                label={t('editVehiclePlantIdLabel')}
                disabled={!plantIds || isVehicleOperating}
                value={{
                  label: value?.label ?? '',
                  value: value?.value ?? '',
                }}
                options={(plantIds ?? []).map((v) => ({
                  label: v,
                  value: v,
                }))}
                noOptionsText={t('editVehicleNoPlantIds')}
                onBlur={onBlur}
                onChange={(_, value) => {
                  if (Array.isArray(value)) {
                    return;
                  }
                  if (value) {
                    onChange({
                      label: value.label,
                      value: value.value,
                    });
                  }
                }}
                autoComplete={false}
                error={Boolean(errors.plantId)}
                helperText={
                  Boolean(errors.plantId) &&
                  t(`${errors.plantId?.label?.message}`)
                }
              />
            );
          }}
          name="plantId"
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESTextField
                required
                label={t('editVehicleNameLabel')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors.name)}
                helperText={
                  Boolean(errors.name) && t(`${errors.name?.message}`)
                }
              />
            );
          }}
          name="name"
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESVirtualizedAutocomplete
                required
                loading={isVehiclesLoading}
                disabled={isVehiclesError || isVehicleOperating}
                label={t('editVehicleBrandLabel')}
                value={{
                  label: value?.label ?? '',
                  value: value?.value ?? '',
                }}
                options={brandOptions}
                noOptionsText={t('editVehicleNoBrands')}
                onBlur={onBlur}
                onChange={(_, value) => {
                  if (Array.isArray(value)) {
                    return;
                  }
                  if (value) {
                    onChange({
                      label: value.label,
                      value: value.value,
                    });
                    setValue('model', { label: '', value: '' });
                    setValue('variant', { label: '', value: '' });
                  }
                }}
                autoComplete={false}
                error={Boolean(errors.brand)}
                helperText={
                  Boolean(errors.brand) && t(`${errors.brand?.label?.message}`)
                }
              />
            );
          }}
          name="brand"
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESVirtualizedAutocomplete
                required
                loading={isVehiclesLoading}
                disabled={
                  isVehiclesError || !brandValue.value || isVehicleOperating
                }
                label={t('editVehicleModelLabel')}
                value={{
                  label: value?.label ?? '',
                  value: value?.value ?? '',
                }}
                options={modelOptions}
                noOptionsText={t('editVehicleNoModels')}
                onBlur={onBlur}
                onChange={(_, value) => {
                  if (Array.isArray(value)) {
                    return;
                  }
                  if (value) {
                    onChange({
                      label: value.label,
                      value: value.value,
                    });
                    setValue('variant', { label: '', value: '' });
                  }
                }}
                autoComplete={false}
                error={Boolean(errors.model)}
                helperText={
                  Boolean(errors.model) && t(`${errors.model?.label?.message}`)
                }
              />
            );
          }}
          name="model"
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESVirtualizedAutocomplete
                required
                loading={isVehiclesLoading}
                disabled={
                  isVehiclesError || !modelValue.value || isVehicleOperating
                }
                label={t('editVehicleVariantLabel')}
                value={{
                  label: value?.label ?? '',
                  value: value?.value ?? '',
                }}
                options={variantOptions}
                noOptionsText={t('editVehicleNoVariants')}
                onBlur={onBlur}
                onChange={(_, value) => {
                  if (Array.isArray(value)) {
                    return;
                  }
                  if (value) {
                    onChange({
                      label: value.label,
                      value: value.value,
                    });
                  }
                }}
                autoComplete={false}
                error={Boolean(errors.variant)}
                helperText={
                  Boolean(errors.variant) &&
                  t(`${errors.variant?.label?.message}`)
                }
              />
            );
          }}
          name="variant"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESTextField
                disabled
                required
                label={t('editVehicleVehicleIdLabel')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors.vehicleId)}
                helperText={
                  Boolean(errors.vehicleId) && t(`${errors.vehicleId?.message}`)
                }
              />
            );
          }}
          name="vehicleId"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          render={({ field: { value, onBlur, onChange } }) => {
            return (
              <ESTextField
                label={t('editVehicleLicencePlateLabel')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors.licencePlate)}
                helperText={
                  Boolean(errors.licencePlate) &&
                  t(`${errors.licencePlate?.message}`)
                }
              />
            );
          }}
          name="licencePlate"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextField
                value={value}
                placeholder={t('editVehicleNoteLabel')}
                onChange={onChange}
                onBlur={onBlur}
                multiline
                minRows={4}
                fullWidth
                error={Boolean(errors.note)}
                helperText={
                  errors.note && errors.note.message
                    ? t(`${errors.note.message}`)
                    : undefined
                }
              />
            );
          }}
          name={'note'}
        />
      </Grid>
    </>
  );
};
