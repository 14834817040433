import {
  FALLBACK_TIMEZONE_ID,
  jobStatusMap,
  productNormalizer,
  routePointNormalizer,
} from '@energy-stacks/fleet/shared';
import { SmartRecommendationJob } from '../smartRecommendationJob';
import { SmartRecommendationJobDto } from '../smartRecommendationJobDto';
import { appendZToDateString } from '@energy-stacks/shared';

export const smartRecommendationJobsNormalizer = (smartRecommendationJobsDto: {
  jobs: SmartRecommendationJobDto[];
}): SmartRecommendationJob[] => {
  const smartRecommendationJobs = smartRecommendationJobsDto.jobs ?? [];

  return smartRecommendationJobs.map((smartRecommendationJob) => ({
    businessAccountUid: smartRecommendationJob.businessAccountUid || '',
    contaminationViolation: null,
    duration: 0,
    startTime: '',
    endTime: '',
    timeZoneId: smartRecommendationJob.timeZoneId ?? FALLBACK_TIMEZONE_ID,
    jobId: smartRecommendationJob.jobUid,
    plantId: smartRecommendationJob.depotId,
    jobType: smartRecommendationJob.jobType,
    confirmedEndTime: null,
    confirmedStartTime: null,
    product: productNormalizer(smartRecommendationJob.product),
    status: smartRecommendationJob.status
      ? jobStatusMap[smartRecommendationJob.status]
      : undefined,
    supplier: {
      supplierId: smartRecommendationJob.businessAccountUid || '',
      supplierName: smartRecommendationJob.companyName || '',
    },
    visitOrder: 0,
    datePickUpTill: smartRecommendationJob.datePickUpTill
      ? appendZToDateString(smartRecommendationJob.datePickUpTill)
      : null,
    dateDropOffTill: smartRecommendationJob.dateDropOffTill
      ? appendZToDateString(smartRecommendationJob.dateDropOffTill)
      : null,
    datePickUpFrom: smartRecommendationJob.datePickUpFrom
      ? appendZToDateString(smartRecommendationJob.datePickUpFrom)
      : null,
    dateDropOffFrom: smartRecommendationJob.dateDropOffFrom
      ? appendZToDateString(smartRecommendationJob.dateDropOffFrom)
      : null,
    dueDate:
      smartRecommendationJob.jobType === 'OUTBOUND'
        ? appendZToDateString(smartRecommendationJob.dateDropOffTill)
        : appendZToDateString(smartRecommendationJob.datePickUpTill),
    origin: routePointNormalizer(smartRecommendationJob.origin ?? undefined),
    destination: routePointNormalizer(
      smartRecommendationJob.destination ?? undefined
    ),
  }));
};
