import {
  IDENTITY_KEY_REGEX,
  LONGITUDE_REGEX,
  LATITUDE_REGEX,
  LONGITUDE_OCPI_REGEX,
  LATITUDE_OCPI_REGEX,
} from '@energy-stacks/shared';
import * as yup from 'yup';

export const brokerEditChargePointFormValidationSchema = yup.object().shape({
  identityKey: yup
    .string()
    .trim()
    .required('identityKeyRequired')
    .max(32, 'identityKeyTooLong')
    .matches(IDENTITY_KEY_REGEX, {
      message: 'identityKeySpecialCharactersError',
      excludeEmptyString: true,
    }),
  chargePointName: yup.string().trim().max(255, 'chargePointNameTooLong'),
  centralSystem: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required('centralSystemRequired'),
  }),
  coordinates: yup.object().shape({
    longitude: yup
      .string()
      .matches(LONGITUDE_REGEX, {
        message: 'longitudeValidation',
        excludeEmptyString: true,
      })
      .matches(LONGITUDE_OCPI_REGEX, {
        message: 'coordinateDecimals',
        excludeEmptyString: true,
      })
      .when('latitude', {
        is: (value: string) => !!value,
        then: yup.string().required('coordinatesDependencyErrorMessage'),
        otherwise: yup.string().nullable(),
      }),
    latitude: yup.lazy(() =>
      yup
        .string()
        .matches(LATITUDE_REGEX, {
          message: 'latitudeValidation',
          excludeEmptyString: true,
        })
        .matches(LATITUDE_OCPI_REGEX, {
          message: 'coordinateDecimals',
          excludeEmptyString: true,
        })
        .when('longitude', {
          is: (value: string) => !!value,
          then: yup.string().required('coordinatesDependencyErrorMessage'),
          otherwise: yup.string().nullable(),
        })
    ),
  }),
  location: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
    description: yup.string(),
  }),
});
