import {
  jobStatusMap,
  routePointNormalizer,
} from '@energy-stacks/fleet/shared';
import { JobDto } from '../jobDto';
import { JobModel } from '../jobModel';
import { appendZToDateString } from '@energy-stacks/shared';

export const jobsNormalizer = (jobsDto: JobDto[]): JobModel[] => {
  return jobsDto.map(jobNormalizer);
};

export const jobNormalizer = (jobDto: JobDto): JobModel => {
  return {
    jobId: jobDto.jobUid,
    plantId: jobDto.depotId,
    supplier: {
      supplierId: jobDto.businessAccountUid,
      supplierName: jobDto.companyName,
    },
    origin: routePointNormalizer(jobDto.origin),
    destination: routePointNormalizer(jobDto.destination),
    jobType: jobDto.jobType,
    product: {
      id: jobDto.product.uid,
      name: jobDto.product.name,
      risk: jobDto.product.risk,
      quantity: jobDto.product.quantity
        ? Number(jobDto.product.quantity.toFixed(2))
        : 0,
    },
    estimatedDuration: jobDto.metrics
      ? {
          timeMinutes: jobDto.metrics.totalDuration,
          distanceKilometres: jobDto.metrics.drivingDistance,
        }
      : null,
    dueDate:
      jobDto.jobType === 'OUTBOUND'
        ? appendZToDateString(jobDto.dateDropOffTill)
        : appendZToDateString(jobDto.datePickUpTill),
    earliestStartDate:
      jobDto.jobType === 'OUTBOUND'
        ? appendZToDateString(jobDto.dateDropOffFrom)
        : appendZToDateString(jobDto.datePickUpFrom),
    datePickUpTill: jobDto.datePickUpTill
      ? appendZToDateString(jobDto.datePickUpTill)
      : null,
    dateDropOffTill: jobDto.dateDropOffTill
      ? appendZToDateString(jobDto.dateDropOffTill)
      : null,
    datePickUpFrom: jobDto.datePickUpFrom
      ? appendZToDateString(jobDto.datePickUpFrom)
      : null,
    dateDropOffFrom: jobDto.dateDropOffFrom
      ? appendZToDateString(jobDto.dateDropOffFrom)
      : null,
    startDate: appendZToDateString(jobDto.startDate),
    endDate: appendZToDateString(jobDto.endDate),
    status: jobStatusMap[jobDto.status],
    tourId: jobDto.tour?.tourUid,
    vehicle: jobVehicleNormalizer(jobDto.tour),
    businessAccountUid: jobDto.businessAccountUid,
  };
};

const jobVehicleNormalizer = (
  jobTourDto: JobDto['tour']
): JobModel['vehicle'] => {
  if (!jobTourDto || !jobTourDto.vehicle) {
    return undefined;
  }

  return {
    vehicleId: jobTourDto.vehicle.vehicleIdentificationNumber || '',
    vehicleName: jobTourDto.vehicle.vehicleName || '',
  };
};
