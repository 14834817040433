import { environment } from '@energy-stacks/feature-config';
import {
  BusinessAccountLocation,
  BusinessAccountLocationType,
} from '@energy-stacks/fleet/feature-business-accounts-data';
import {
  Map,
  MapProps,
  MapWrapper,
  PlantMarker,
  TankMarker,
  mapDefaultProps,
  offsetPoints,
} from '@energy-stacks/fleet/shared';
import { useState, useRef, useEffect, useMemo } from 'react';
import { TankPopupCard } from './TankPopupCard';
import useSupercluster from 'use-supercluster';
import { TankClusterMarker } from './TankClusterMarker';
import { useSelectedPlant } from '../../useSelectedPlant';

export interface BusinessAccountDetailsMapLocation
  extends BusinessAccountLocation {
  tankType: BusinessAccountLocationType;
}

type BusinessAccountDetailsMapProps = {
  locations: BusinessAccountDetailsMapLocation[];
  mapProps?: MapProps;
};

export const BusinessAccountDetailsMap: React.FC<
  BusinessAccountDetailsMapProps
> = ({
  locations,
  mapProps = {
    defaultCenter: mapDefaultProps.center,
    defaultZoom: mapDefaultProps.zoom,
    maxZoom: mapDefaultProps.maxZoom,
  },
}) => {
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [selectedLocation, setSelectedLocation] =
    useState<BusinessAccountDetailsMapLocation>();
  const [zoom, setZoom] = useState(mapDefaultProps.zoom);
  const [bounds, setBounds] = useState<
    [number, number, number, number] | undefined
  >();

  const selectedPlant = useSelectedPlant();

  const points = useMemo(() => {
    const pointTanks: {
      properties: BusinessAccountDetailsMapLocation;
      coords: [string, string];
    }[] = locations.map((location) => ({
      properties: location,
      coords: [
        location.address.geoLocation.latitude,
        location.address.geoLocation.longitude,
      ],
    }));

    return offsetPoints(pointTanks, {
      coords: [
        selectedPlant?.geoLocation.latitude ?? '0',
        selectedPlant?.geoLocation.longitude ?? '0',
      ],
    });
  }, [locations, selectedPlant?.geoLocation]);

  const { clusters, supercluster } = useSupercluster({
    points: points.map((point) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        locationId: `${point.coords[1]}_${point.coords[0]}`,
        category: 'locations',
        location: point.properties,
      },
      geometry: {
        type: 'Point',
        coordinates: [point.coords[1], point.coords[0]],
      },
    })),
    bounds,
    zoom,
    options: { radius: 40, maxZoom: mapDefaultProps.maxZoom },
  });

  useEffect(() => {
    if (!mapRef.current || !mapReady || !window.google || !selectedPlant)
      return;

    const mapBounds = new google.maps.LatLngBounds();
    points.forEach((point) => {
      mapBounds.extend(
        new google.maps.LatLng(
          parseFloat(point.coords[0]),
          parseFloat(point.coords[1])
        )
      );
    });

    mapBounds.extend(
      new google.maps.LatLng(
        parseFloat(selectedPlant.geoLocation.latitude),
        parseFloat(selectedPlant.geoLocation.longitude)
      )
    );

    if (points.length > 0) {
      //@ts-expect-error No types
      mapRef.current.fitBounds(mapBounds, 100);
    } else {
      // If the the plant is the only marker zoom level will be too high if we set the bounds to the plant only
      //@ts-expect-error No types
      mapRef.current.panTo({
        lat: parseFloat(selectedPlant.geoLocation.latitude),
        lng: parseFloat(selectedPlant.geoLocation.longitude),
      });
    }
  }, [points, mapReady, selectedPlant]);

  return (
    <MapWrapper>
      <Map
        onChange={({ zoom, bounds }: { zoom: number; bounds: any }) => {
          setZoom(zoom);
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
        }}
        apiKey={environment.googleApiKey}
        onMapLoaded={({ map }) => {
          mapRef.current = map;
          setMapReady(true);
        }}
        defaultCenter={mapProps.defaultCenter}
        resetBoundsOnResize
        defaultZoom={mapProps.defaultZoom}
        mapOptions={{
          zoomControl: true,
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            cluster_id: clusterId,
            point_count: pointCount,
          } = cluster.properties;

          // We render plant globally, so don't render it as marker
          if (
            latitude === selectedPlant?.geoLocation?.latitude &&
            longitude === selectedPlant?.geoLocation?.longitude
          ) {
            return null;
          }

          if (isCluster) {
            return (
              <TankClusterMarker
                key={clusterId}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  //@ts-expect-error No types
                  mapRef.current?.setZoom(expansionZoom + 1);
                  //@ts-expect-error No types
                  mapRef.current?.panTo({
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  });
                }}
                pointCount={pointCount}
                lat={parseFloat(latitude)}
                lng={parseFloat(longitude)}
              />
            );
          }

          const locationPoint = points.find(
            (point) =>
              point.coords[0] === latitude && point.coords[1] === longitude
          );

          if (!locationPoint) {
            return null;
          }

          return (
            <TankMarker
              key={locationPoint.properties.id}
              tankType={locationPoint.properties.tankType}
              lat={parseFloat(latitude)}
              lng={parseFloat(longitude)}
              onClick={() => {
                if (!mapRef.current || !mapReady) {
                  return;
                }
                // @ts-expect-error No types
                mapRef.current.panTo({
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude),
                });
                setSelectedLocation(locationPoint.properties);
              }}
            />
          );
        })}
        {selectedLocation ? (
          <TankPopupCard
            lat={parseFloat(selectedLocation.address.geoLocation.latitude)}
            lng={parseFloat(selectedLocation.address.geoLocation.longitude)}
            location={selectedLocation}
            onClose={() => setSelectedLocation(undefined)}
          />
        ) : null}
        {selectedPlant?.geoLocation && (
          <PlantMarker
            onClick={() => {
              if (!mapReady) return;
              //@ts-expect-error No types
              mapRef.current?.setZoom(mapDefaultProps.maxZoom - 2);
              //@ts-expect-error No types
              mapRef.current?.panTo({
                lat: parseFloat(selectedPlant.geoLocation.latitude),
                lng: parseFloat(selectedPlant.geoLocation.longitude),
              });
            }}
            lat={parseFloat(selectedPlant.geoLocation.latitude)}
            lng={parseFloat(selectedPlant.geoLocation.longitude)}
          />
        )}
      </Map>
    </MapWrapper>
  );
};
