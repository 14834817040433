import { environment } from '@energy-stacks/feature-config';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';
import { Box, BoxProps } from '@mui/material';

import { LocationMarker } from './LocationMarker';
import { TourLabel } from './TourLabel';
import { TourInfo } from '../tour-details/TourDetails';
import { SIDE_LIST_WIDTH, ToursMapSideList } from './ToursMapSideList';
import { vehicleColors } from '../vehicleColors';
import { decode } from '@mapbox/polyline';
import { compact } from 'lodash-es';
import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import {
  Map as AppMap,
  FleetProduct,
  MapProps,
  MapWrapper,
  OffsetPoint,
  PlantMarker,
  TankMarker,
  mapDefaultProps as defaultProps,
  offsetPoints,
} from '@energy-stacks/fleet/shared';
import { JobMapPopupCard } from './JobMapPopupCard';
import { usePrevious } from '@energy-stacks/core/ui';
import { useSelectedPlant } from '@energy-stacks/fleet/feature-business-accounts';

import './tourDetailsMap.css';
import { tourDetailsMapControlIcon } from './tourDetailsMapControlIcon';
import { useTranslation } from 'react-i18next';

type PointJob = OffsetPoint<{
  tourIndex: number;
  visitOrder: number;
  jobId: string;
  jobProduct: FleetProduct;
}>;

const getBounds = (polyline: google.maps.Polyline) => {
  const bounds = new google.maps.LatLngBounds();
  polyline.getPath().forEach((item, _index) => {
    bounds.extend(new google.maps.LatLng(item.lat(), item.lng()));
  });
  return bounds;
};

const LEAVES_POINT_LIMIT = 30;

export const TourDetailsMap: React.FC<{
  tours: TourInfo[];
  onAddToTour?: (job: OptimizedTourJob) => void;
  jobPool?: OptimizedTourJob[];
  mapProps?: MapProps;
}> = ({ tours, mapProps, jobPool = [], onAddToTour }) => {
  const mapRef = useRef(null);
  const [t] = useTranslation('tours');
  const [hoveredRouteInfo, setRouteInfo] = useState<{
    lat: number;
    lng: number;
    name: string;
    tourIndex: number;
  }>();

  const selectedPlant = useSelectedPlant();

  const [focusedTour, setFocusedTour] = useState<(typeof tours)[0] | undefined>(
    tours.length === 1 ? tours[0] : undefined
  );

  const polylinesRef = useRef<
    {
      name: string;
      strokeColor: string;
      polyline: google.maps.Polyline;
      zIndex: number;
    }[]
  >([]);

  const [zoom, setZoom] = useState(defaultProps.zoom);
  const [isMouseOverPopup, setIsMouseOverPopup] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<
    | {
        coords: [string, string];
        visitOrder: number;
        tourIndex: number;
        clusterId?: number;
        jobId: string;
      }[]
    | undefined
  >(undefined);
  const [markerDetails, setMarkerDetails] = useState<
    | {
        coords: [string, string];
        content: {
          details: {
            properties:
              | {
                  tourIndex: number;
                  visitOrder: number;
                  jobId: string;
                  jobProduct: FleetProduct | null;
                }
              | undefined;
          };
          clusterId: number;
        }[];
      }
    | undefined
  >(undefined);
  const [mapReady, setMapReady] = useState(false);
  const [bounds, setBounds] = useState<
    [number, number, number, number] | undefined
  >();
  const handleGoogleApiLoaded = ({ map }: any) => {
    mapRef.current = map;
    setMapReady(true);
  };
  const previousZoom = usePrevious(zoom);

  const points = useMemo(() => {
    const pointJobs: PointJob[] = tours
      .filter((tour) =>
        !focusedTour ? true : tour.tourIndex === focusedTour?.tourIndex
      )
      .flatMap((tour) =>
        tour.tourJobs.jobs
          .filter((job) => job.origin && job.destination)
          .map((job) => ({ ...job, tourIndex: tour.tourIndex }))
      )
      .map((job: OptimizedTourJob & { tourIndex: number }) => [
        {
          properties: {
            tourIndex: job.tourIndex,
            // Add 1 to job visit order not to have 0 based visit orders displayed on the UI
            visitOrder: job.visitOrder + 1,
            jobId: job.jobId,
            jobProduct: job.product,
          },
          coords: [
            job.origin?.geoLocation.latitude,
            job.origin?.geoLocation.longitude,
          ],
        },
        {
          properties: {
            tourIndex: job.tourIndex,
            // Add 1 to job visit order not to have 0 based visit orders displayed on the UI
            visitOrder: job.visitOrder + 1,
            jobId: job.jobId,
            jobProduct: job.product,
          },
          coords: [
            job.destination?.geoLocation.latitude,
            job.destination?.geoLocation.longitude,
          ],
        },
      ])
      .flat()
      .concat(
        jobPool.map((job) => ({
          coords:
            job.jobType === 'INBOUND'
              ? [
                  job.origin?.geoLocation.latitude,
                  job.origin?.geoLocation.longitude,
                ]
              : [
                  job.destination?.geoLocation.latitude,
                  job.destination?.geoLocation.longitude,
                ],
          properties: {
            jobId: job.jobId,
            jobProduct: job.product,
            tourIndex: -1,
            visitOrder: -1,
          },
        }))
      ) as PointJob[];

    // Handle the case when there are multiple jobs with the same location
    // Plant is always rendered globally, so we need to offset points that are not plant
    return offsetPoints(pointJobs, {
      coords: [
        selectedPlant?.geoLocation.latitude ?? '0',
        selectedPlant?.geoLocation.longitude ?? '0',
      ],
    });
  }, [tours, focusedTour, selectedPlant, jobPool]);

  const { clusters, supercluster } = useSupercluster({
    points: points.map((point) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        crimeId: `${point.coords[1]}_${point.coords[0]}`,
        category: 'jobs',
      },
      geometry: {
        type: 'Point',
        coordinates: [point.coords[1], point.coords[0]],
      },
    })),
    bounds,
    zoom,
    options: { radius: 40, maxZoom: defaultProps.maxZoom },
  });

  useEffect(() => {
    if (!mapRef.current || !mapReady || !window.google) return;

    const listeners: google.maps.MapsEventListener[] = [];

    const polylines = tours
      .filter((tour) => tour.polyline !== '')
      .map(({ polyline, vehicleName: name, tourIndex }) => {
        const stroke = vehicleColors[tourIndex % vehicleColors.length];
        const path = decode(polyline).map((point) => ({
          lat: point[0],
          lng: point[1],
        }));

        const tourPath = new google.maps.Polyline({
          path,
          geodesic: true,
          strokeColor: 'white',
          strokeOpacity: 1.0,
          zIndex: 2,
          strokeWeight: 2,
        });

        const tourPathDouble = new google.maps.Polyline({
          path,
          geodesic: true,
          strokeColor: stroke,
          zIndex: 1,
          strokeOpacity: 1.0,
          strokeWeight: 8,
        });

        const mouseOverListener = google.maps.event.addListener(
          tourPath,
          'mouseover',
          function (e: any) {
            setRouteInfo({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
              name,
              tourIndex,
            });
          }
        );

        const mouseOverListenerDouble = google.maps.event.addListener(
          tourPathDouble,
          'mouseover',
          function (e: any) {
            setRouteInfo({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
              name,
              tourIndex,
            });
          }
        );

        const mouseOutListener = google.maps.event.addListener(
          tourPath,
          'mouseout',
          function () {
            setRouteInfo(undefined);
          }
        );

        const mouseOutListenerDouble = google.maps.event.addListener(
          tourPathDouble,
          'mouseout',
          function () {
            setRouteInfo(undefined);
          }
        );

        listeners.push(
          mouseOverListener,
          mouseOutListener,
          mouseOverListenerDouble,
          mouseOutListenerDouble
        );

        return [
          { stroke, name: tourIndex.toString(), path: tourPath, zIndex: 2 },
          {
            stroke,
            name: tourIndex.toString(),
            path: tourPathDouble,
            zIndex: 1,
          },
        ];
      })
      .flat();

    polylines.forEach(({ name, path: polyline, zIndex, stroke }) => {
      polyline.setMap(mapRef.current);
      polylinesRef.current.push({
        name,
        polyline,
        zIndex,
        strokeColor: stroke,
      });
    });

    return () => {
      polylines.forEach(({ path: polyline }) => {
        polyline.setMap(null);
        polylinesRef.current = [];
      });
      listeners.forEach((listener) => {
        google.maps.event.removeListener(listener);
      });
    };
  }, [mapRef, mapReady, tours]);

  useEffect(() => {
    if (!mapRef.current || !mapReady || !window.google || !focusedTour) return;

    // Fit polyline bounds
    const focusedPolyline = polylinesRef.current.find(
      ({ name }) => name === focusedTour.tourIndex.toString()
    )?.polyline;

    if (focusedPolyline) {
      const focusedBounds = getBounds(focusedPolyline);

      //@ts-expect-error No types
      mapRef.current.fitBounds(focusedBounds, {
        left: tours.length > 1 ? SIDE_LIST_WIDTH : 0,
        right: 0,
        bottom: 0,
        top: 0,
      });

      polylinesRef.current.forEach(({ polyline, name }) => {
        if (name === focusedTour.tourIndex.toString()) return;
        polyline.setOptions({
          strokeOpacity: 0.2,
        });
      });
    }

    return () => {
      polylinesRef.current.forEach(({ polyline }) => {
        polyline.setOptions({
          strokeOpacity: 1.0,
        });
      });
    };
  }, [tours, focusedTour, mapReady]);

  useEffect(() => {
    if (!mapRef.current || !mapReady || !window.google || !focusedTour) return;

    polylinesRef.current.forEach((drawing) => {
      const { polyline, name, zIndex } = drawing;
      if (name === focusedTour.tourIndex.toString()) {
        drawing.zIndex = zIndex + 2;
        polyline.setOptions({
          zIndex: drawing.zIndex,
        });
      }
    });

    return () => {
      polylinesRef.current.forEach((drawing) => {
        const { polyline, zIndex } = drawing;
        if (zIndex === 3) {
          drawing.zIndex = 1;
          polyline.setOptions({
            zIndex: drawing.zIndex,
          });
        } else if (zIndex === 4) {
          drawing.zIndex = 2;
          polyline.setOptions({
            zIndex: drawing.zIndex,
          });
        }
      });
    };
  }, [focusedTour, mapReady]);

  const fitToursToMapBounds = useCallback(() => {
    const toursBounds = compact(
      tours.map((tour) => {
        const polyline = polylinesRef.current.find(
          (polyline) => polyline.name === tour.tourIndex.toString()
        )?.polyline;
        return polyline && getBounds(polyline);
      })
    );

    const mapBounds = toursBounds.reduce(
      (bounds, tour) =>
        bounds.extend(tour.getNorthEast()).extend(tour.getSouthWest()),
      new google.maps.LatLngBounds()
    );
    //@ts-expect-error No types
    mapRef.current.fitBounds(mapBounds, {
      left: SIDE_LIST_WIDTH,
      right: 0,
      bottom: 0,
      top: 0,
    });
  }, [tours]);

  useEffect(() => {
    // We need to wait on map ready here since we need to check what polylines are currently visible on the map
    if (!mapRef.current || !mapReady || !window.google) return;

    // No need to fit bounds if there is only one tour since it will be focused and fitted to the map by default
    if (tours.length > 1) {
      // There should always be a polyline for each tour but compact just in case
      fitToursToMapBounds();
    }
  }, [fitToursToMapBounds, mapReady, tours]);

  useEffect(() => {
    if (
      !mapRef.current ||
      !mapReady ||
      !window.google ||
      !selectedJobs ||
      !previousZoom
    )
      return;

    if (zoom !== previousZoom) {
      setSelectedJobs(undefined);
    }
  }, [selectedJobs, mapReady, zoom, previousZoom]);

  const showOnMap = (clusterId: number, lat: number, lng: number) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(clusterId),
      20
    );
    //@ts-expect-error No types
    mapRef.current?.setZoom(expansionZoom + 1);
    //@ts-expect-error No types
    mapRef.current?.panTo({ lat, lng });
  };
  const selectedJobInfo =
    selectedJobs &&
    tours
      .filter((tour) =>
        selectedJobs.find((job) => job.tourIndex === tour.tourIndex)
      )
      ?.map((tour) =>
        tour.tourJobs.jobs.filter((tourJobs) =>
          selectedJobs.find((job) => job.jobId === tourJobs.jobId)
        )
      )
      .concat(
        jobPool.filter((job) =>
          selectedJobs.find((selectedJob) => job.jobId === selectedJob.jobId)
        )
      )
      .flat()
      .map((job) => ({
        ...job,
        clusterId: selectedJobs[0].clusterId,
      }));

  useEffect(() => {
    if (!mapRef.current || !mapReady) return;
    if (selectedJobInfo) {
      //@ts-expect-error No types
      mapRef.current.setOptions({
        disableDoubleClickZoom: true,
      });
    } else {
      //@ts-expect-error No types
      mapRef.current.setOptions({
        disableDoubleClickZoom: false,
      });
    }
  }, [selectedJobInfo, mapReady]);

  useEffect(() => {
    if (!mapRef.current || !mapReady) return;

    const controlDiv = document.createElement('div');
    const controlButton = document.createElement('button');
    controlButton.classList.add('map-control-zoom-out-button');

    controlButton.innerHTML = tourDetailsMapControlIcon;
    controlButton.title = t('mapZoomOutButtonTooltip');
    controlButton.type = 'button';

    controlButton.addEventListener('click', () => {
      fitToursToMapBounds();
    });

    controlDiv.appendChild(controlButton);

    //@ts-expect-error No types
    mapRef.current.controls[
      google.maps.ControlPosition.INLINE_END_BLOCK_END
    ].push(controlDiv);

    return () => {
      //@ts-expect-error No types
      mapRef?.current?.controls[
        google.maps.ControlPosition.INLINE_END_BLOCK_END
      ].pop();
    };
  }, [mapReady, t, fitToursToMapBounds]);

  return (
    <MapWrapper>
      {tours.length > 1 && (
        <ToursMapSideList
          tours={tours}
          focusedTour={focusedTour}
          onTourFocus={(tour) => {
            if (focusedTour && focusedTour.tourIndex === tour.tourIndex) {
              setFocusedTour(undefined);
            } else {
              setFocusedTour(tour);
            }
          }}
        />
      )}
      <AppMap
        onChange={({ zoom, bounds }: { zoom: number; bounds: any }) => {
          setZoom(zoom);
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
        }}
        apiKey={environment.googleApiKey}
        onMapLoaded={handleGoogleApiLoaded}
        defaultCenter={{
          lat:
            parseFloat(selectedPlant?.geoLocation.latitude ?? '') ||
            defaultProps.center.lat,
          lng:
            parseFloat(selectedPlant?.geoLocation.longitude ?? '') ||
            defaultProps.center.lng,
        }}
        resetBoundsOnResize
        defaultZoom={defaultProps.zoom}
        {...mapProps}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster } = cluster.properties;

          // We render plant globally, so don't render it as marker
          if (
            longitude === selectedPlant?.geoLocation?.longitude &&
            latitude === selectedPlant?.geoLocation?.latitude
          ) {
            return null;
          }

          const jobPoint = !isCluster
            ? points.find(
                (point) =>
                  point.coords[0] === latitude && point.coords[1] === longitude
              )
            : undefined;
          const focusedPolyline = polylinesRef.current.find(
            ({ name }) => name === jobPoint?.properties.tourIndex?.toString()
          );
          const isPoolJob = jobPoint?.properties.visitOrder === -1;
          if (isPoolJob && jobPoint) {
            return (
              <TankMarker
                key={index}
                tankType={
                  jobPoint.properties.jobProduct.name
                    .toLowerCase()
                    .includes('degassed')
                    ? 'degassed'
                    : 'raw'
                }
                lat={parseFloat(latitude)}
                lng={parseFloat(longitude)}
                onClick={() => {
                  if (!mapRef.current || !mapReady) {
                    return;
                  }
                  // @ts-expect-error No types
                  mapRef.current.panTo({
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  });
                  setSelectedJobs(
                    jobPoint
                      ? [
                          {
                            ...jobPoint.properties,
                            coords: jobPoint.coords,
                          },
                        ]
                      : undefined
                  );
                }}
              />
            );
          }
          return (
            <LocationMarker
              isActive={
                (selectedJobs && selectedJobs[0].coords === jobPoint?.coords) ||
                (selectedJobs?.every((job) => job.clusterId === cluster.id) &&
                  !jobPoint)
              }
              strokeColor={
                focusedPolyline &&
                jobPoint &&
                focusedPolyline.name ===
                  jobPoint.properties.tourIndex.toString()
                  ? focusedPolyline.strokeColor
                  : undefined
              }
              key={`cluster-${index}`}
              onMouseEnter={() => {
                if (isCluster) {
                  const clusterProperties: {
                    details:
                      | OffsetPoint<{
                          tourIndex: number;
                          visitOrder: number;
                          jobId: string;
                          jobProduct: FleetProduct | null;
                        }>
                      | undefined;
                    clusterId: number;
                  }[] = supercluster
                    .getLeaves(cluster.id, LEAVES_POINT_LIMIT)
                    .map((point: any) => ({
                      details: points.find(
                        (p) =>
                          point.geometry.coordinates[0] === p.coords[1] &&
                          point.geometry.coordinates[1] === p.coords[0]
                      ),
                      clusterId: cluster.id,
                    }))
                    .filter(
                      ({
                        details,
                      }: {
                        details:
                          | OffsetPoint<{
                              tourIndex: number;
                              visitOrder: number;
                              jobId: string;
                              jobProduct: FleetProduct | null;
                            }>
                          | undefined;
                      }) => details?.properties.visitOrder !== -1
                    );
                  if (clusterProperties.length > 0) {
                    setMarkerDetails({
                      coords: [latitude, longitude],
                      content: clusterProperties.map((elem) => ({
                        clusterId: elem.clusterId,
                        details: { properties: elem.details?.properties },
                      })),
                    });
                  }
                }
              }}
              onMouseLeave={() => {
                setMarkerDetails(undefined);
              }}
              onClick={() => {
                if (!mapReady) return;

                if (isCluster) {
                  const clusterProperties = supercluster
                    .getLeaves(cluster.id, LEAVES_POINT_LIMIT)
                    .map((point: any) => ({
                      details: points.find(
                        (p) =>
                          point.geometry.coordinates[0] === p.coords[1] &&
                          point.geometry.coordinates[1] === p.coords[0]
                      ),
                      clusterId: cluster.id,
                    }));

                  if (clusterProperties.length === LEAVES_POINT_LIMIT) {
                    return showOnMap(
                      cluster.id,
                      parseFloat(latitude),
                      parseFloat(longitude)
                    );
                  }

                  setSelectedJobs(
                    clusterProperties.map((props: any) => ({
                      visitOrder: props.details?.properties.visitOrder,
                      jobId: props.details?.properties.jobId,
                      tourIndex: props.details?.properties.tourIndex,
                      coords: props.details?.coords,
                      clusterId: cluster.id,
                    }))
                  );
                } else {
                  setSelectedJobs(
                    jobPoint
                      ? [
                          {
                            ...jobPoint.properties,
                            coords: jobPoint.coords,
                          },
                        ]
                      : undefined
                  );
                }
              }}
              style={{
                zIndex: 1,
                width: 30,
                height: 34,
              }}
              lat={parseFloat(latitude)}
              lng={parseFloat(longitude)}
              text={!jobPoint ? '+' : jobPoint.properties.visitOrder.toString()}
            />
          );
        })}
        {selectedPlant?.geoLocation && (
          <PlantMarker
            onClick={() => {
              if (!mapReady) return;
              //@ts-expect-error No types
              mapRef.current?.setZoom(defaultProps.maxZoom - 2);
              //@ts-expect-error No types
              mapRef.current?.panTo({
                lat: parseFloat(selectedPlant.geoLocation.latitude),
                lng: parseFloat(selectedPlant.geoLocation.longitude),
              });
            }}
            lat={parseFloat(selectedPlant.geoLocation.latitude)}
            lng={parseFloat(selectedPlant.geoLocation.longitude)}
          />
        )}
        {hoveredRouteInfo && !isMouseOverPopup && (
          <TourLabel
            style={{ zIndex: 100 }}
            title={hoveredRouteInfo.name}
            lat={hoveredRouteInfo.lat}
            lng={hoveredRouteInfo.lng}
            colorIndex={hoveredRouteInfo.tourIndex}
          />
        )}
        {selectedJobInfo && selectedJobInfo.length > 0 && (
          <JobMapPopupCard
            jobPool={jobPool}
            onAddToTour={(job) => onAddToTour?.(job)}
            onClose={() => {
              setSelectedJobs(undefined);
            }}
            onMouseOver={() => {
              if (isMouseOverPopup) return;
              setIsMouseOverPopup(true);
            }}
            onMouseLeave={() => {
              setIsMouseOverPopup(false);
            }}
            onShowOnMap={(clusterId) => {
              showOnMap(
                clusterId,
                parseFloat(selectedJobs[0].coords[0]),
                parseFloat(selectedJobs[0].coords[1])
              );
              setSelectedJobs(undefined);
            }}
            lat={parseFloat(selectedJobs[0].coords[0]) - 0.00001}
            lng={parseFloat(selectedJobs[0].coords[1])}
            jobs={selectedJobInfo}
            clusterId={selectedJobs[0].clusterId}
          />
        )}
        {/* Shown on cluster hover */}
        {markerDetails && (
          <MapBox
            zIndex={10}
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
            display="flex"
            width={70}
            flexWrap={'wrap'}
            boxShadow="1px 3px 6px rgba(56, 67, 107, 0.25)"
            padding="6px 12px 6px 12px"
            borderRadius={(theme) => theme.spacing(1.5)}
            lat={parseFloat(markerDetails.coords[0]) - 0.00001}
            lng={parseFloat(markerDetails.coords[1])}
          >
            {markerDetails.content.length >= LEAVES_POINT_LIMIT && (
              <span>Click to zoom</span>
            )}
            {markerDetails.content.length < LEAVES_POINT_LIMIT &&
              markerDetails.content.map((clusterProperties, index) => {
                const focusedPolyline = polylinesRef.current.find(
                  ({ name }) =>
                    name ===
                    clusterProperties.details?.properties?.tourIndex.toString()
                );
                const strokeColor =
                  focusedPolyline &&
                  focusedPolyline.name ===
                    clusterProperties.details?.properties?.tourIndex.toString()
                    ? focusedPolyline.strokeColor
                    : undefined;

                return (
                  <Box key={index}>
                    {
                      <span
                        style={{
                          color: strokeColor,
                          fontWeight: 800,
                          fontSize: 12,
                        }}
                      >
                        {clusterProperties.details?.properties?.visitOrder}
                      </span>
                    }
                    {index < markerDetails.content.length - 1 ? (
                      <span style={{ color: strokeColor, fontWeight: 800 }}>
                        ,{' '}
                      </span>
                    ) : (
                      ''
                    )}
                  </Box>
                );
              })}
          </MapBox>
        )}
      </AppMap>
    </MapWrapper>
  );
};

const MapBox = (props: { lat: number; lng: number } & BoxProps) => {
  return <Box {...props} />;
};
