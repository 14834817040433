import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { EditingTourJob } from './editingTourJobModel';

export const mapJobModelToEditingTourJob = (job: JobModel): EditingTourJob => {
  return {
    jobId: job.jobId,
    plantId: job.plantId,
    supplier: job.supplier,
    jobType: job.jobType || 'INBOUND',
    startTime: '',
    endTime: '',
    visitOrder: 0,
    duration: 0,
    product: job.product,
    destination: job.destination,
    origin: job.origin,
    contaminationViolation: null,
    status: job.status,
    estimatedDuration: job.estimatedDuration,
    dueDate: job.dueDate,
    confirmedEndTime: null,
    confirmedStartTime: null,
    businessAccountUid: job.businessAccountUid,
  };
};
