import { appendZToDateString } from '@energy-stacks/shared';
import { VehicleDto } from '../vehicleDto';
import { VehicleModel } from '../vehicleModel';

export const vehiclesNormalizer = (
  vehiclesDto: VehicleDto[]
): VehicleModel[] => {
  return vehiclesDto.map(vehicleNormalizer);
};

const vehicleNormalizer = (vehicleDto: VehicleDto): VehicleModel => ({
  plantId: vehicleDto.depotId,
  vehicleId: vehicleDto.vehicleIdentificationNumber,
  brandId: vehicleDto.brandUuid || '',
  brandAndModel: `${vehicleDto.brandName} ${vehicleDto.model}`,
  name: vehicleDto.name,
  licencePlate: vehicleDto.licensePlateNumber || '',
  weight: vehicleDto.maxLoadingWeight || 0,
  volume: vehicleDto.maxLoadingVolume || 0,
  status: vehicleDto.status,
  isPartiallyPlanned: vehicleDto.partiallyPlanned,
  startTour: appendZToDateString(vehicleDto.startTour),
  endTour: appendZToDateString(vehicleDto.endTour),
  note: vehicleDto.notes || '',
});
