import { Table } from '@tanstack/react-table';
import { useEffect, useRef } from 'react';

export const useTableColumnResizeObserver = <T>(
  instance: Table<T>,
  onResizeEnd: (columnId: string, columnSize: number) => void
) => {
  const tableState = instance.getState();
  // This Ref will contain the id of the column being resized or undefined
  const columnIdRef = useRef<string | false>();
  useEffect(() => {
    // We are interested in calling the resize event only when "state.columnResizingInfo?.isResizingColumn" changes from
    // a string to false, because it indicates that it WAS resizing but it no longer is.
    if (
      tableState.columnSizingInfo &&
      !tableState.columnSizingInfo?.isResizingColumn &&
      columnIdRef.current
    ) {
      // Trigger resize event
      onResizeEnd(
        columnIdRef.current,
        tableState.columnSizing[columnIdRef.current]
      );
    }
    columnIdRef.current = tableState.columnSizingInfo?.isResizingColumn;
  }, [tableState.columnSizingInfo, tableState.columnSizing, onResizeEnd]);
};
