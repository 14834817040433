import { useJobs } from '@energy-stacks/fleet/feature-jobs';
import { SkippedJob } from '@energy-stacks/fleet/feature-tours-data';
import { useMemo } from 'react';

export const useSkippedJobsDetails = (skippedJobs: SkippedJob[]) => {
  const { data: jobs, isLoading, isError, refetch } = useJobs();

  const skippedJobsDetails = useMemo(() => {
    return (
      jobs?.filter((job) =>
        skippedJobs.find((skippedJob) => skippedJob.jobId === job.jobId)
      ) ?? []
    );
  }, [skippedJobs, jobs]);

  return { skippedJobsDetails, isLoading, isError, refetch };
};
