import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { StartOfWeek } from './date-range';
import { DateRangeClasses } from './dateRangeClasses';
import { CalendarWrapper } from './CalendarWrapper';
import { useCallback } from 'react';
import { format, isSameDay } from 'date-fns';
import { Box } from '@mui/material';

interface DatePickerProps {
  date: Date;
  onDateChange: (date: Date) => void;
  weekStartsOn?: StartOfWeek;
  scheduledDays?: string[] | Date[];
  disabledDay?: (date: Date) => boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  onDateChange,
  weekStartsOn,
  scheduledDays = [],
  disabledDay,
}) => {
  const dayContentRenderer = useCallback(
    (day: Date) => {
      let scheduleIndicator = null;
      if (
        scheduledDays.find(
          (scheduledDay) =>
            isSameDay(day, new Date(scheduledDay)) &&
            !isSameDay(new Date(day), date)
        )
      ) {
        scheduleIndicator = (
          <Box
            sx={(theme) => ({
              height: '5px',
              width: '5px',
              borderRadius: '100%',
              background: theme.palette.primary.dark,
              position: 'absolute',
              top: 22,
              right: 20,
            })}
          />
        );
      }

      return (
        <Box>
          {scheduleIndicator}
          <Box component="span">{format(day, 'd')}</Box>
        </Box>
      );
    },
    [date, scheduledDays]
  );

  return (
    <CalendarWrapper>
      <Calendar
        date={date}
        onChange={onDateChange}
        weekStartsOn={weekStartsOn}
        className={DateRangeClasses.Wrapper}
        dayContentRenderer={dayContentRenderer}
        disabledDay={disabledDay}
        classNames={{
          nextPrevButton: DateRangeClasses.NextPrevButton,
          inRange: DateRangeClasses.InRange,
          startEdge: DateRangeClasses.Edge,
          endEdge: DateRangeClasses.Edge,
          dayNumber: DateRangeClasses.DayNumber,
          dayPassive: DateRangeClasses.DayPassive,
          monthAndYearWrapper: DateRangeClasses.MonthAndYearWrapper,
        }}
      />
    </CalendarWrapper>
  );
};
