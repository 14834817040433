import { Stack } from '@mui/material';
import { TourDetailsJobsTableViewModeToggle } from './TourDetailsJobsTableViewModeToggle';
import {
  TourDetailsViewMode,
  TourDetailsViewModeButton,
} from './TourDetailsViewModeButton';
import { TourDetailsFilters } from './TourDetailsFilters';

type TourDetailsActionsProps = {
  shouldEnableFilters?: boolean;
  vehicleName?: string;
  tourStartDate?: string;
  tourDetailsViewMode: TourDetailsViewMode;
  onTourDetailsViewModeChange: (viewMode: TourDetailsViewMode) => void;
};

export const TourDetailsActions: React.FC<TourDetailsActionsProps> = ({
  shouldEnableFilters = false,
  vehicleName,
  tourStartDate,
  tourDetailsViewMode,
  onTourDetailsViewModeChange,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      {shouldEnableFilters ? (
        <TourDetailsFilters
          tourStartDate={tourStartDate || ''}
          vehicleName={vehicleName || ''}
        />
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        sx={{ marginLeft: 'auto' }}
        gap={4}
      >
        {tourDetailsViewMode === 'table' ? (
          <TourDetailsJobsTableViewModeToggle />
        ) : null}
        <TourDetailsViewModeButton
          onViewModeChange={onTourDetailsViewModeChange}
        />
      </Stack>
    </Stack>
  );
};
