import { BerryThemeProvider } from '@energy-stacks/core/berry-mui';
import { usePopover } from '@energy-stacks/core/ui';
import { useAppSelector } from '@energy-stacks/store';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { MouseEventHandler, useCallback } from 'react';

interface SidebarSelectProps {
  icon: React.ReactElement;
  testId?: string;
  options: { label: string; value: string }[];
  selectedValue: string;
  onSelectionChange: (value: string) => void;
  sidebarClosedDisplayComponent?: 'icon' | 'value';
}

export const SidebarSelect: React.FC<SidebarSelectProps> = ({
  icon,
  testId,
  options,
  selectedValue,
  onSelectionChange,
  sidebarClosedDisplayComponent = 'icon',
}) => {
  const { anchorEl, closePopover, openPopover, popoverOpen } = usePopover();
  const sidebarOpened = useAppSelector((state) => state.sidebar.sidebarOpened);

  const handleOpenPopover: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      openPopover(e);
    },
    [openPopover]
  );

  return (
    <>
      {sidebarOpened ? (
        <ListItemButton
          data-testid={testId}
          sx={() => ({
            width: '100%',
            mt: 2,
            height: 52,
            display: 'flex',
            alignItems: 'center',
          })}
          onClick={handleOpenPopover}
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText
            sx={{ ml: 2 }}
            primary={<Typography color="inherit">{selectedValue}</Typography>}
          />
          {popoverOpen ? (
            <IconChevronUp stroke={1.5} size="16px" />
          ) : (
            <IconChevronDown stroke={1.5} size="16px" />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          data-testid={testId}
          sx={() => ({
            mt: 2,
            width: 52,
            height: 52,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
          onClick={handleOpenPopover}
        >
          {sidebarClosedDisplayComponent === 'icon' ? (
            <ListItemIcon
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {icon}
            </ListItemIcon>
          ) : (
            <Typography>{selectedValue}</Typography>
          )}
        </ListItemButton>
      )}
      <BerryThemeProvider mode="light">
        <Menu
          id="menu-lang"
          anchorEl={anchorEl}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: -2,
            '& .MuiMenu-list': {
              paddingY: 0,
              minWidth: 240,
              width: '100%',
              maxHeight: 240,
            },
          }}
          open={popoverOpen}
          onClose={closePopover}
        >
          <Box>
            {options.map((option) => {
              return (
                <MenuItem
                  dense
                  selected={
                    option.value.toLowerCase() === selectedValue.toLowerCase()
                  }
                  key={option.value}
                  onClick={() => {
                    onSelectionChange(option.value);
                    closePopover();
                  }}
                  data-testid={`${option.value}${testId}`}
                >
                  <Radio
                    checked={
                      option.value.toLowerCase() === selectedValue.toLowerCase()
                    }
                    value={option.value}
                    sx={{ p: 0, mr: 2.5 }}
                  />
                  <Typography>{option.label}</Typography>
                </MenuItem>
              );
            })}
          </Box>
        </Menu>
      </BerryThemeProvider>
    </>
  );
};
