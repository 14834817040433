import { Grid } from '@mui/material';
import { TourJobsDndTable } from './TourJobsDndTable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { JobsPoolDndTable } from './JobsPoolDndTable';
import { ESTableRowDragLayer } from '@energy-stacks/core/ui';
import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { mapEditingTourJobToJobModel } from './mapEditingTourJobToJobModel';
import { mapJobModelToEditingTourJob } from './mapJobModelToEditingTourJob';
import { ESLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useEditTourDetailsContext } from './useEditTourDetailsContext';
import { useCallback, useState } from 'react';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { EditingTourJob } from './editingTourJobModel';

type TourDetailsEditModeProps = {
  TourJobsTableHeader: React.ReactNode;
};

interface BaseJob {
  jobId: JobModel['jobId'];
}

let lastSelectedIndex = -1;

const reorderSourceArray = <T extends BaseJob>(
  sourceArray: T[],
  draggedIds: T['jobId'][],
  dropIndex: number
): T[] => {
  lastSelectedIndex = -1;
  if (draggedIds.length === 0 || dropIndex === -1) {
    return sourceArray;
  }

  const draggedItems = sourceArray.filter((item) =>
    draggedIds.includes(item.jobId)
  );

  const filteredArray = sourceArray.filter(
    (item) => !draggedIds.includes(item.jobId)
  );

  return [
    ...filteredArray.slice(0, dropIndex),
    ...draggedItems,
    ...filteredArray.slice(dropIndex),
  ];
};

const insertItemsIntoDropTarget = <T extends BaseJob, R extends BaseJob>(
  items: T[],
  targetArray: T[],
  sourceArray: R[],
  dropIndex: number
): [T[], R[]] => {
  lastSelectedIndex = -1;
  const newTargetArray: T[] =
    dropIndex !== -1
      ? [
          ...targetArray.slice(0, dropIndex),
          targetArray[dropIndex],
          ...items,
          ...targetArray.slice(dropIndex + 1),
        ]
      : [...targetArray, ...items];

  const selectedItemIds = items.map((item) => item.jobId);
  const newSourceArray = sourceArray.filter(
    (item) => !selectedItemIds.includes(item.jobId)
  );

  return [newTargetArray, newSourceArray];
};

const handleMultiselect = <T extends BaseJob>(
  event: React.MouseEvent,
  item: T,
  source: T[],
  onSelectItems: (items: T[]) => void,
  selectedItems: T[],
  ignoreItem?: (item: T) => boolean
) => {
  const isMetaKeyActive = event.metaKey || event.ctrlKey;
  const isShiftKeyActive = event.shiftKey;

  if (!isMetaKeyActive && !isShiftKeyActive) {
    lastSelectedIndex = -1;
    onSelectItems([]);
    return;
  }

  const itemIndex = source.findIndex((i) => i.jobId === item.jobId);

  if (isShiftKeyActive) {
    // Multiple rows
    if (lastSelectedIndex >= itemIndex) {
      onSelectItems(
        [
          ...selectedItems,
          ...source.slice(itemIndex, lastSelectedIndex),
        ].filter((item) => !ignoreItem?.(item))
      );
    } else {
      onSelectItems(
        [
          ...selectedItems,
          ...source.slice(lastSelectedIndex + 1, itemIndex + 1),
        ].filter((item) => !ignoreItem?.(item))
      );
    }
  } else if (isMetaKeyActive) {
    // One row at the time
    const foundIndex = selectedItems.findIndex(
      (job) => job.jobId === item.jobId
    );

    if (foundIndex >= 0) {
      // Already selected. Deselect it.
      onSelectItems(selectedItems.filter((job) => job.jobId !== item.jobId));
    } else {
      const selectedJob = source.find((job) => job.jobId === item.jobId);

      if (!selectedJob) {
        return;
      }

      onSelectItems([...selectedItems, selectedJob]);
    }
    lastSelectedIndex = itemIndex;
  }
};

const isLastDisabledRow = (
  dropIndex: number,
  tourJobs?: OptimizedTourJob[]
) => {
  return (
    dropIndex ===
    (tourJobs ?? []).findIndex(
      (job) =>
        job.status === 'CANCELLED' ||
        job.status === 'PLANNED' ||
        job.status === 'TO_DO'
    ) -
      1
  );
};

export const TourDetailsEditMode: React.FC<TourDetailsEditModeProps> = ({
  TourJobsTableHeader,
}) => {
  const {
    jobPool,
    handleTourJobsChange,
    setJobPool,
    isLoadingJobPool,
    isGetJobPoolError,
    refetchJobPool,
    tourDetails,
  } = useEditTourDetailsContext();
  const [selectedPoolJobs, setSelectedPoolJobs] = useState<JobModel[]>([]);
  const [selectedTourJobs, setSelectedTourJobs] = useState<EditingTourJob[]>(
    []
  );

  const tourJobs = tourDetails?.tour.tourJobs.jobs;

  const handleDropItemsToTour = useCallback(
    (draggedIds: string[], dropId: string) => {
      if (!tourJobs || !jobPool) {
        return;
      }
      const dropIndex = tourJobs?.findIndex((item) => item.jobId === dropId);
      let dragStartIndex = jobPool?.findIndex(
        (item) => item.jobId === draggedIds[0]
      );

      if (dragStartIndex === -1) {
        dragStartIndex = tourJobs?.findIndex(
          (item) => item.jobId === draggedIds[0]
        );
      }

      if (dragStartIndex === -1) {
        return;
      }

      const jobsFromPool = draggedIds
        .map((jobId) => {
          return jobPool.find((job) => job.jobId === jobId);
        })
        .filter(Boolean) as JobModel[];

      if (jobsFromPool.length) {
        const jobsToInsert = jobsFromPool.map(mapJobModelToEditingTourJob);

        const [newTourJobs, newPool] = insertItemsIntoDropTarget(
          jobsToInsert,
          tourJobs,
          jobPool,
          dropIndex
        );

        handleTourJobsChange(
          newTourJobs.map((job, index) => ({
            ...job,
            visitOrder: index,
          }))
        );
        setJobPool(newPool);
      } else {
        if (isLastDisabledRow(dropIndex, tourJobs)) {
          return;
        }

        const newTourJobs = reorderSourceArray(tourJobs, draggedIds, dropIndex);
        handleTourJobsChange(
          newTourJobs.map((job, index) => ({
            ...job,
            visitOrder: index,
          }))
        );
      }
      setSelectedPoolJobs([]);
      setSelectedTourJobs([]);
    },
    [handleTourJobsChange, jobPool, setJobPool, tourJobs]
  );

  const handleDropItemsToJobPool = useCallback(
    (draggedIds: string[], dropId: string) => {
      if (!jobPool || !tourJobs) {
        return;
      }

      const dropIndex = jobPool?.findIndex((item) => item.jobId === dropId);
      let dragStartIndex = tourJobs?.findIndex(
        (item) => item.jobId === draggedIds[0]
      );

      if (dragStartIndex === -1) {
        dragStartIndex = jobPool?.findIndex(
          (item) => item.jobId === draggedIds[0]
        );
      }

      if (dragStartIndex === -1) {
        return;
      }

      const jobsFromTour = draggedIds
        .map((jobId) => {
          return tourJobs.find((job) => job.jobId === jobId);
        })
        .filter(Boolean) as EditingTourJob[];

      if (jobsFromTour.length) {
        const jobsToInsert = jobsFromTour.map(mapEditingTourJobToJobModel);
        const [newPool, newTourJobs] = insertItemsIntoDropTarget(
          jobsToInsert,
          jobPool,
          tourJobs,
          dropIndex
        );
        handleTourJobsChange(
          newTourJobs.map((job, index) => ({
            ...job,
            visitOrder: index,
          }))
        );
        setJobPool(newPool);
      } else {
        const newPool = reorderSourceArray(jobPool, draggedIds, dropIndex);
        setJobPool(newPool);
      }

      setSelectedPoolJobs([]);
      setSelectedTourJobs([]);
    },
    [handleTourJobsChange, jobPool, setJobPool, tourJobs]
  );

  return (
    <Grid container direction="row" spacing={5} overflow="hidden" height="100%">
      <DndProvider debugMode={true} backend={HTML5Backend}>
        <ESTableRowDragLayer />
        <Grid
          item
          xs={6}
          display="flex"
          gap={4}
          height="100%"
          sx={{ flexDirection: 'column' }}
        >
          {TourJobsTableHeader}
          <TourJobsDndTable
            tourJobs={tourJobs ?? []}
            dndRowsOptions={{
              dragRowsSelectId: (row) => row.original.jobId,
              dragRole: { drag: true, drop: true },
              onDrop: handleDropItemsToTour,
              canDrag: (row) => {
                return (
                  row.original.status !== 'DONE' &&
                  row.original.status !== 'ENROUTE'
                );
              },
              accept: (row) => {
                return (row.original.status === 'DONE' ||
                  row.original.status === 'ENROUTE') &&
                  !isLastDisabledRow(row.index, tourJobs)
                  ? 'none'
                  : 'row';
              },
              isLastDisabledRow: (row) =>
                isLastDisabledRow(row.index, tourJobs),
              onRowClick: (event, row, filteredTourJobs) => {
                handleMultiselect(
                  event,
                  row.original,
                  filteredTourJobs,
                  setSelectedTourJobs,
                  selectedTourJobs,
                  (item) => item.status === 'DONE' || item.status === 'ENROUTE'
                );
                setSelectedPoolJobs([]);
              },
              selectedItemIds: selectedTourJobs.map((job) => job.jobId),
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          gap={4}
          height="100%"
          sx={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          {isLoadingJobPool ? <ESLoadingIndicator /> : null}
          {isGetJobPoolError ? (
            <RefetchOnError onRefetch={refetchJobPool} />
          ) : null}
          {jobPool ? (
            <JobsPoolDndTable
              jobPool={jobPool}
              addToTour={(job: OptimizedTourJob) =>
                handleDropItemsToTour([job.jobId], '')
              }
              dndRowsOptions={{
                dragRole: { drag: true, drop: true },
                dragRowsSelectId: (row) => row.original.jobId,
                onDrop: handleDropItemsToJobPool,
                onRowClick: (event, row, filteredJobs) => {
                  handleMultiselect(
                    event,
                    row.original,
                    filteredJobs,
                    setSelectedPoolJobs,
                    selectedPoolJobs
                  );
                  setSelectedTourJobs([]);
                },
                selectedItemIds: selectedPoolJobs.map((job) => job.jobId),
              }}
            />
          ) : null}
        </Grid>
      </DndProvider>
    </Grid>
  );
};
