import { appendZToDateString } from '@energy-stacks/shared';
import { OptimizedTourModel } from '../optimizedTourModel';
import { OptimizedTourDto } from '../optimizeToursWebResponse';
import { optimizedTourJobsNormalizer } from './optimizedTourJobsNormalizer';
import ShortUniqueId from 'short-unique-id';
import { emptyRunsMetricsNormalizer } from './emptyRunsNormalizer';
import { transitionsNormalizer } from './transitionsNormalizer';
import { FALLBACK_TIMEZONE_ID } from '@energy-stacks/fleet/shared';

const { randomUUID } = new ShortUniqueId();

export const optimizedTourNormalizer = (
  tourDto: OptimizedTourDto
): OptimizedTourModel => {
  const startDateWithZ = appendZToDateString(tourDto.startDate);
  const endDateWithZ = appendZToDateString(tourDto.endDate);

  return {
    id: randomUUID(),
    vehicleName: tourDto.vehicleName ?? '',
    vehicleId: tourDto.vehicleIdentificationNumber,
    plantId: tourDto.routeJobDetails[0].depotId ?? '',
    date: startDateWithZ,
    startDate: startDateWithZ,
    endDate: endDateWithZ,
    timeZoneId: tourDto.timeZoneId ?? FALLBACK_TIMEZONE_ID,
    confirmedStartDate: tourDto.confirmedStartDate
      ? appendZToDateString(tourDto.confirmedStartDate)
      : undefined,
    confirmedEndDate: tourDto.confirmedEndDate
      ? appendZToDateString(tourDto.confirmedEndDate)
      : undefined,
    estimatedEndDate: tourDto.estimatedEndDate
      ? appendZToDateString(tourDto.estimatedEndDate)
      : undefined,
    isViolatingTimeWindow: tourDto.violatingTimeWindow,
    distance: (tourDto.totalDistance ?? 0) / 1000, // Convert to km
    tourJobs: optimizedTourJobsNormalizer({
      jobs: tourDto.routeJobDetails,
      vehicleBreak: tourDto.vehicleBreak,
      transitionMetrics: tourDto.transitionMetrics,
      contaminationRuleViolations: tourDto.contaminationRuleViolations,
    }),
    duration: tourDto.totalTime ?? 0,
    quantity: tourDto.productQuantities,
    totalJobs: tourDto.totalJobs ?? 0,
    polyline: tourDto.polyline ?? { points: '' },
    emptyRunsMetrics: emptyRunsMetricsNormalizer(tourDto.transitionMetrics),
    transitions: tourDto.transitionMetrics
      ? transitionsNormalizer(tourDto.transitionMetrics.transitions)
      : [],
    note: tourDto.notes || '',
  };
};
