import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { ToursTable } from './ToursTable';
import { useTours } from './useTours';
import { Outlet, useSearchParams } from 'react-router-dom';
import { FleetPage } from '@energy-stacks/fleet/shared';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { parseDateRangeFromSearchParams } from '@energy-stacks/core/ui';

const defaultToursDateRangeFilterValue: DateRange = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

export const ToursPage = () => {
  const [t] = useTranslation('tours');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);
  const [searchParams] = useSearchParams();
  const initialDateRange = parseDateRangeFromSearchParams(searchParams);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(
    () => {
      // Temporary hack until we integrate useESTableBasic hook with BE filters
      // If user cleared all filters don't set initial date range
      if (searchParams.get('display') === 'all') {
        return;
      }

      // If there is no date range in search params, but there are other filters
      // that means that date range filter was manually cleared, so don't set it initially
      // (E.g. User clears date filter and sets only for example status=PLANNED and goes back to this page)
      if (!initialDateRange && searchParams.size) {
        return;
      }

      return initialDateRange || defaultToursDateRangeFilterValue;
    }
  );
  const { data, isLoading, isError, refetch } = useTours({
    dateFrom: dateRangeFilter ? dateRangeFilter.startDate : undefined,
    dateTo: dateRangeFilter ? dateRangeFilter.endDate : undefined,
  });

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data ? (
        <ToursTable
          tours={data}
          enableColumnSelection
          persistPagination={true}
          onDateRangeChange={setDateRangeFilter}
          dateRangeFilter={dateRangeFilter}
        />
      ) : null}
      <Outlet />
    </FleetPage>
  );
};
