import { DateRange } from '@energy-stacks/core/date-range-picker';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { FilterFn } from '@tanstack/react-table';
import { isWithinInterval, parseISO } from 'date-fns';

export const jobsTableDateRangeFilterFn: FilterFn<JobModel> = (
  row,
  _,
  filterValue: DateRange | undefined
) => {
  if (!filterValue) {
    return true;
  }

  const filterInterval = {
    start: filterValue.startDate,
    end: filterValue.endDate,
  };

  const dates = {
    datePickUpFrom: row.original.datePickUpFrom,
    datePickUpTill: row.original.datePickUpTill,
    dateDropOffFrom: row.original.dateDropOffFrom,
    dateDropOffTill: row.original.dateDropOffTill,
    startDate: row.original.startDate,
    endDate: row.original.endDate,
  };

  return Object.values(dates).some((date) => {
    if (date && isWithinInterval(parseISO(date), filterInterval)) {
      return true;
    }
    return false;
  });
};
