import React, { useRef } from 'react';
import {
  DateRange,
  DateRangePicker,
  StartOfWeek,
} from '@energy-stacks/core/date-range-picker';
import { TableDateFilterButton } from './TableDateFilterButton';
import { usePopover } from '@energy-stacks/core/ui';
import { Column } from '@tanstack/react-table';
import { useEffectOnce, useIsFirstRender, useUpdateEffect } from 'usehooks-ts';

interface TableDateRangeFilterProps<T> {
  title: string | React.ReactNode;
  isActive?: boolean;
  defaultDateRange?: DateRange | undefined;
  column?: Column<T, unknown> | undefined;
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
  dateRange?: DateRange | undefined;
  onDateRangeApplied?: (dateRange: DateRange) => void;
  onDateRangeCleared?: () => void;
  testId?: string;
  weekStartsOn?: StartOfWeek;
  selectablePassive?: boolean;
}

export const TableDateRangeFilter = <T,>({
  title,
  isActive,
  defaultDateRange,
  column,
  onDateRangeChange,
  dateRange,
  onDateRangeApplied,
  onDateRangeCleared,
  testId,
  weekStartsOn,
  selectablePassive = false,
}: TableDateRangeFilterProps<T>) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  const defaultDateRangeRef = useRef(defaultDateRange);

  const isFirstRender = useIsFirstRender();

  useEffectOnce(() => {
    if (dateRange) {
      column && column.setFilterValue(dateRange);
    }
  });

  useUpdateEffect(() => {
    if (!column?.getIsFiltered()) {
      onDateRangeChange?.(defaultDateRangeRef.current);
    }
  }, [column, isFirstRender, onDateRangeChange]);

  return (
    <TableDateFilterButton
      title={title}
      testId={testId}
      popoverOpen={popoverOpen}
      isActive={isActive || column?.getIsFiltered()}
      onClick={openPopover}
      onClose={closePopover}
      anchorEl={anchorEl}
      PickerComponent={
        <DateRangePicker
          defaultDateRange={defaultDateRange}
          dateRange={dateRange}
          onDateRangeApplied={(dateRange) => {
            column && column.setFilterValue(dateRange);
            onDateRangeChange?.(dateRange);
            onDateRangeApplied?.(dateRange);
            closePopover();
          }}
          onDateRangeCleared={() => {
            column && column.setFilterValue(undefined);
            onDateRangeChange?.(undefined);
            onDateRangeCleared?.();
            closePopover();
          }}
          weekStartsOn={weekStartsOn}
          selectablePassive={selectablePassive}
        />
      }
    />
  );
};
