import { ESToggleButtonGroup } from '@energy-stacks/core/ui';
import { IconMenu } from '@tabler/icons-react';
import { IconMenu2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

export type TourDetailsJobsTableViewMode = 'standard' | 'compact';

export const TourDetailsJobsTableViewModeToggle = () => {
  const [t] = useTranslation('tours');
  const [viewMode, setViewMode] = useLocalStorage(
    'tour-details-jobs-table-view-mode',
    'standard' as TourDetailsJobsTableViewMode
  );

  return (
    <ESToggleButtonGroup<TourDetailsJobsTableViewMode>
      value={viewMode}
      onChange={setViewMode}
      testId="tourDetailsJobsTableViewMode"
      enforceValueSet
      toggleOptions={[
        {
          value: 'standard',
          icon: <IconMenu2 stroke={2} />,
          tooltipText: t('tourDetailsJobsTableViewMode.standard'),
        },
        {
          value: 'compact',
          icon: <IconMenu stroke={2} />,
          tooltipText: t('tourDetailsJobsTableViewMode.compact'),
        },
      ]}
    />
  );
};
