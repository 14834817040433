import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { formatDateInTimezone } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';

export const JobDueDateCell = ({
  dueDate,
  timeZoneId,
}: {
  dueDate: JobModel['dueDate'] | undefined;
  timeZoneId: string;
}) => {
  if (!dueDate) {
    return <Typography>---</Typography>;
  }

  return (
    <Stack direction="column" gap={1} alignItems="start">
      <Typography fontWeight={700} color="grey.900">
        {formatDateInTimezone(dueDate, 'dd/MM/yyyy', timeZoneId)}
      </Typography>
      <Typography>
        {formatDateInTimezone(dueDate, 'h:mm a', timeZoneId).toLowerCase()}
      </Typography>
    </Stack>
  );
};
