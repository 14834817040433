import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

type JobDurationCellProps = {
  duration: JobModel['estimatedDuration'];
};

export const JobDurationCell: FC<JobDurationCellProps> = ({ duration }) => {
  return duration ? (
    <Box>
      <Typography variant="inherit" fontWeight={400}>
        {`${duration.timeMinutes}min`}
        <Box component="span" sx={{ mx: 1 }}>
          •
        </Box>
        {`${duration.distanceKilometres}km`}
      </Typography>
    </Box>
  ) : (
    <Typography>---</Typography>
  );
};
