import { WorkingHoursDto } from '@energy-stacks/fleet/shared';
import { toPayloadDateFromZonedTime } from '@energy-stacks/shared';
import { EditVehicleOperatingHoursFormData } from '../editVehicleOperatingHoursFormData';
import { endOfDay, startOfDay } from 'date-fns';

export const exceptionalWorkingHoursToPayload = (
  workingHours: EditVehicleOperatingHoursFormData['workingHours']['exceptional'],
  timeZoneId?: string
): Pick<WorkingHoursDto, 'exceptional_openings' | 'exceptional_closings'> => {
  const openings = workingHours
    .filter((e) => e.isOperating)
    .map((e) => {
      // Inputting time via keyboard in TimePicker component doesn't work properly if the date is not set to today
      // Should be removed after @mui/x-date-pickers (v.5.0.6) gets updated to next major version or higher
      // https://github.com/mui/mui-x/issues/6587
      const startDate = new Date(e.date);
      startDate.setHours(e.startTime.getHours());
      startDate.setMinutes(e.startTime.getMinutes());
      startDate.setSeconds(e.startTime.getSeconds());

      const endDate = new Date(e.date);
      endDate.setHours(e.endTime.getHours());
      endDate.setMinutes(e.endTime.getMinutes());
      endDate.setSeconds(e.endTime.getSeconds());

      return {
        period_begin: toPayloadDateFromZonedTime(startDate, timeZoneId),
        period_end: toPayloadDateFromZonedTime(endDate, timeZoneId),
      };
    });
  const closings = workingHours
    .filter((e) => !e.isOperating)
    .map((e) => {
      return {
        period_begin: toPayloadDateFromZonedTime(
          startOfDay(e.date),
          timeZoneId
        ),
        period_end: toPayloadDateFromZonedTime(endOfDay(e.date), timeZoneId),
      };
    });

  return {
    exceptional_openings: openings,
    exceptional_closings: closings,
  };
};
