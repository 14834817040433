import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography, IconButton } from '@mui/material';
import { EditingTourJob } from './editingTourJobModel';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { SmartRecommendationJobsTable } from './SmartRecommendationTable';

interface SmartRecommendationDialogProps {
  onCancel: () => void;
  onAction: (job: EditingTourJob) => void;
  isLoading?: boolean;
  isOpen?: boolean;
  tourJobId: string | null;
}

export const SmartRecommendationDialog: React.FC<
  SmartRecommendationDialogProps
> = ({ onCancel, onAction, isOpen = false, tourJobId }) => {
  const [t] = useTranslation('tours');
  const [selectedJob, setSelectedJob] = useState<EditingTourJob | null>(null);
  const [isError, setIsError] = useState(false);

  const handleCloseDialog = useCallback(() => {
    onCancel();
    setSelectedJob(null);
    setIsError(false);
  }, [onCancel]);

  const handleCTA = useCallback(() => {
    if (!selectedJob) {
      return;
    }
    onAction(selectedJob);
    handleCloseDialog();
  }, [handleCloseDialog, onAction, selectedJob]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog}
      PaperProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <ESDialogTitle
        data-testid="smartRecommendationDialogTitle"
        sx={{ fontSize: 16, paddingBottom: 0 }}
      >
        {t('smartRecommendation')}
        <IconButton
          data-testid="smartRecommendationDialogCloseButton"
          onClick={handleCloseDialog}
          sx={{ m: 2, position: 'absolute', top: 0, right: 0 }}
        >
          <IconX stroke={1.5} />
        </IconButton>
      </ESDialogTitle>
      <ESDialogContent data-testid="smartRecommendationDialogContent">
        <Typography variant="body1" marginBottom={6}>
          {t('smartRecommendationDialog.content')}
        </Typography>
        <SmartRecommendationJobsTable
          tourJobId={tourJobId}
          selectedJob={selectedJob}
          onError={() => {
            setIsError(true);
          }}
          onJobSelected={(job) => setSelectedJob(job)}
        />
      </ESDialogContent>
      <ESDialogActions>
        {!isError ? (
          <ESDialogActionButton
            data-testid="smartRecommendationDialogDeleteButton"
            variant="contained"
            color="primary"
            onClick={handleCTA}
            disabled={!selectedJob}
          >
            {t('smartRecommendationDialog.action')}
          </ESDialogActionButton>
        ) : null}
      </ESDialogActions>
    </Dialog>
  );
};
