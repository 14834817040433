import { useGetVehiclesQuery } from '@energy-stacks/fleet/feature-vehicles-data';
import { toPayloadDate } from '@energy-stacks/shared';

export interface GetVehiclesConfig {
  dateFrom?: Date | undefined;
  dateTo?: Date | undefined;
  skip?: boolean;
}

export const useVehicles = (config?: GetVehiclesConfig) => {
  return useGetVehiclesQuery(
    {
      dateFrom: config?.dateFrom ? toPayloadDate(config?.dateFrom) : undefined,
      dateTo: config?.dateTo ? toPayloadDate(config?.dateTo) : undefined,
    },
    { skip: config?.skip }
  );
};
