import { ESButton } from '@energy-stacks/core/ui';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEditTourDetailsContext } from './useEditTourDetailsContext';

type TourDetailsEditModeActionsProps = {
  onSave: () => void;
  shouldDisableSave?: boolean;
};

export const TourDetailsEditModeActions: React.FC<
  TourDetailsEditModeActionsProps
> = ({ onSave, shouldDisableSave = false }) => {
  const { t } = useTranslation('tours');
  const { handleCancelEditing } = useEditTourDetailsContext();
  return (
    <Stack
      direction="row"
      sx={{
        '&.MuiLoadingButton-root': {
          paddingX: 7,
        },
      }}
      alignItems="center"
      gap={3}
    >
      <ESButton onClick={handleCancelEditing} variant="text" color="error">
        {t('editTourDetailsCancelButton')}
      </ESButton>
      <ESButton
        onClick={onSave}
        sx={{
          '&.MuiLoadingButton-root': {
            paddingX: 7,
          },
        }}
        disabled={shouldDisableSave}
      >
        {t('editTourDetailsRecalculateButton')}
      </ESButton>
    </Stack>
  );
};
