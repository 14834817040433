import React, { createContext, useCallback, useMemo, useRef } from 'react';
import { useJobsPool } from '../useJobsPool';
import {
  OptimizedTourJob,
  OptimizedTourModel,
  contaminationRulesValidationDataToPayload,
  contaminationViolationNormalizer,
  useValidateContaminationRulesMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { mapEditingTourJobToJobModel } from './mapEditingTourJobToJobModel';
import { isEqual } from 'lodash-es';
import { TourDetails } from '../create-tour-steps/ReviewStep';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { useAppLocation, usePromptDisplay } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useNavigate } from 'react-router-dom';

export const EditTourDetailsContext = createContext<
  EditTourDetailsState | undefined
>(undefined);

type EditTourDetailsStateProps = {
  config: EditTourDetailsStateConfig;
  children: React.ReactNode;
};

export type EditTourDetailsStateConfig = {
  isEditTourModeActive: boolean;
  tourDetails: TourDetails | undefined;
  jobIds: string[];
  onCancelEditing?: () => void;
  onTourDetailsChange?: (tourDetails: TourDetails | undefined) => void;
  nextVirtualTour?: OptimizedTourModel;
  previousVirtualTour?: OptimizedTourModel;
};

export type EditTourDetailsState = {
  tourDetails: TourDetails | undefined;
  handleTourJobsChange: (newTourJobs: OptimizedTourJob[]) => void;
  handleCancelEditing: () => void;
  isTourChanged: boolean;
  isValidatingContaminationRules: boolean;
  jobPool: JobModel[] | undefined;
  setJobPool: (newJobPool: JobModel[]) => void;
  isLoadingJobPool: boolean;
  isGetJobPoolError: boolean;
  refetchJobPool: () => void;
};

export const EditTourDetailsContextProvider: React.FC<
  EditTourDetailsStateProps
> = ({
  config: {
    isEditTourModeActive,
    tourDetails,
    jobIds,
    onCancelEditing,
    onTourDetailsChange,
    nextVirtualTour,
    previousVirtualTour,
  },
  children,
}) => {
  const [tShared] = useTranslation('shared');
  const location = useAppLocation();
  const navigate = useNavigate();

  const { jobPool, setJobPool, isLoadingJobPool, isGetJobPoolError, refetch } =
    useJobsPool(
      {
        jobIds: jobIds,
        startDate: tourDetails?.initialTour?.startDate,
        endDate: tourDetails?.initialTour?.endDate,
      },
      { skip: !tourDetails?.initialTour }
    );
  const [
    validateContaminationRules,
    { isLoading: isValidatingContaminationRules },
  ] = useValidateContaminationRulesMutation();
  const validateContaminationRulesRequestRef = useRef<ReturnType<
    typeof validateContaminationRules
  > | null>(null);

  const isTourChanged = useMemo(
    () =>
      !isEqual(
        tourDetails?.initialTour.tourJobs.jobs.map((job) => job.jobId),
        tourDetails?.tour?.tourJobs.jobs.map((job) => job.jobId)
      ),
    [tourDetails?.initialTour.tourJobs.jobs, tourDetails?.tour?.tourJobs.jobs]
  );

  const resetContextState = useCallback(() => {
    if (!tourDetails) {
      return;
    }
    validateContaminationRulesRequestRef.current?.abort();
    setJobPool((prevPool) => {
      const currentTourJobs = tourDetails.tour.tourJobs.jobs || [];
      const initialTourJobs = tourDetails.initialTour.tourJobs.jobs;
      const jobsDifference = currentTourJobs
        .filter(
          (job) =>
            !initialTourJobs?.find(
              (initialJob) => initialJob.jobId === job.jobId
            )
        )
        .map(mapEditingTourJobToJobModel);

      return [...jobsDifference, ...(prevPool || [])];
    });
    onTourDetailsChange?.({ ...tourDetails, tour: tourDetails.initialTour });
  }, [onTourDetailsChange, setJobPool, tourDetails]);

  const isFromToursOverview =
    location.state && location.state.from?.pathname === FleetRoutes.Tours;

  const handleCancelEditing = usePromptDisplay({
    onAction: () => {
      if (isFromToursOverview) {
        navigate(`${FleetRoutes.Tours}${location.state.from?.search}`);
        return;
      }
      onCancelEditing?.();
      resetContextState();
    },
    promptMessage: tShared('closeConfirmContent'),
    shouldPrompt:
      // If user came to tour edit mode from tour overview, after cancelation we are navigating back, therefore we can bypass prompt display
      // in order not to have two prompts shown since navigation prompt will handle cancel confirmation.
      isEditTourModeActive && isTourChanged,
  });

  const handleTourJobsChange = useCallback(
    (newTourJobs: OptimizedTourJob[]) => {
      if (!tourDetails?.tour) {
        return;
      }

      if (validateContaminationRulesRequestRef.current) {
        validateContaminationRulesRequestRef.current.abort();
      }

      onTourDetailsChange?.({
        ...tourDetails,
        tour: {
          ...tourDetails.tour,
          tourJobs: {
            jobs: newTourJobs.map((job) => ({ ...job })),
            // TBD: If tour is edited processes will likely change. Maybe set them to empty array?
            processes: tourDetails.tour.tourJobs.processes,
          },
        },
      });

      if (newTourJobs.length) {
        const contaminationRules = validateContaminationRules(
          contaminationRulesValidationDataToPayload({
            tourDetails: tourDetails.tour,
            tourJobs: newTourJobs,
            nextTourJobs: nextVirtualTour
              ? nextVirtualTour.tourJobs.jobs
              : undefined,
            previousTourJobs: previousVirtualTour
              ? previousVirtualTour.tourJobs.jobs
              : undefined,
          })
        );

        validateContaminationRulesRequestRef.current = contaminationRules;

        contaminationRules.unwrap().then((res) => {
          const flatViolations = Object.values(
            res && res.contaminationRuleViolations
              ? res.contaminationRuleViolations
              : {}
          ).flat();
          const updatedTourJobs = newTourJobs.map((job, _, jobs) => {
            return {
              ...job,
              contaminationViolation: contaminationViolationNormalizer(
                flatViolations,
                job,
                jobs
              ),
            };
          });

          onTourDetailsChange?.({
            ...tourDetails,
            tour: {
              ...tourDetails.tour,
              tourJobs: {
                jobs: updatedTourJobs,
                processes: tourDetails.tour.tourJobs.processes,
              },
            },
          });
          validateContaminationRulesRequestRef.current = null;
        });
      }
    },
    [
      nextVirtualTour,
      onTourDetailsChange,
      previousVirtualTour,
      tourDetails,
      validateContaminationRules,
    ]
  );

  const state: EditTourDetailsState = useMemo(() => {
    return {
      tourDetails,
      handleTourJobsChange,
      handleCancelEditing,
      isTourChanged,
      isValidatingContaminationRules,
      jobPool,
      setJobPool,
      isLoadingJobPool,
      isGetJobPoolError,
      refetchJobPool: refetch,
    };
  }, [
    tourDetails,
    handleCancelEditing,
    handleTourJobsChange,
    isLoadingJobPool,
    isTourChanged,
    isValidatingContaminationRules,
    jobPool,
    setJobPool,
    isGetJobPoolError,
    refetch,
  ]);

  return (
    <EditTourDetailsContext.Provider value={state}>
      {children}
    </EditTourDetailsContext.Provider>
  );
};
