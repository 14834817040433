import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '@energy-stacks/shared';
import { environment } from '@energy-stacks/feature-config';
import { TourModel } from './tourModel';
import { toursNormalizer } from './normalizers/toursNormalizer';
import { OptimizedTours, OptimizedTourModel } from './optimizedTourModel';
import { OptimizeToursPayload } from './optimizeToursPayload';
import { CreateTourPayload } from './createTourPayload';
import { optimizedToursNormalizer } from './normalizers/optimizedToursNormalizer';
import { ContaminationRuleJobResponse } from './contaminationRuleJobResponse';
import { ContaminationRulesValidationRequest } from './contaminationRulesValidationRequest';
import { RecalculateTourDetailsPayload } from './recalculateTourDetailsPayload';
import { RecalculateTourDetailsResponse } from './recalculateTourDetailsResponse';
import { recalculateTourDetailsResponseNormalizer } from './normalizers/recalculateTourDetailsResponseNormalizer';
import { RecalculateTourDetailsResponseDto } from './recalculateTourDetailsResponseDto';
import { editTourDataToPayload } from './editTourDataToPayload';
import { EditVirtualTourDetailsPayload } from './editVirtualTourDetailsPayload';
import { TourDto } from './tourDto';
import { getDataByPlantId } from '@energy-stacks/fleet/shared';
import { EditTourNoteRequestParam } from './editTourNoteDataToPayload';
import { tourDetailsNormalizer } from './normalizers/tourDetailsNormalizer';

export type GetToursRequestParams =
  | {
      startDateFrom?: string;
      endDateTo?: string;
    }
  | undefined;

export const toursApi = createApi({
  reducerPath: 'tours',
  tagTypes: ['Tours', 'TourDetails'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(environment.ocppServiceUrl),
  endpoints: (builder) => ({
    getTours: builder.query<TourModel[], GetToursRequestParams>({
      queryFn: async (params, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          params,
          url: '/tours',
          transformResponse: toursNormalizer,
        });
      },
      providesTags: ['Tours'],
    }),
    getTourDetails: builder.query<OptimizedTourModel, OptimizedTourModel['id']>(
      {
        query: (tourId) => ({
          url: `/tours/${tourId}/details`,
          method: 'GET',
        }),
        providesTags: (result) => [
          {
            type: 'TourDetails',
            id: result?.id,
          },
        ],
        transformResponse: tourDetailsNormalizer,
        structuralSharing: false, // Creates new reference for cache entry each time cache is invalidated (e.g. making useEffect fire when it changes)
      }
    ),
    optimizeTours: builder.mutation<OptimizedTours, OptimizeToursPayload>({
      query: (body) => ({
        url: 'routes/optimize',
        method: 'POST',
        body,
      }),
      transformResponse: optimizedToursNormalizer,
    }),
    createTours: builder.mutation<void, CreateTourPayload[]>({
      query: (body) => ({
        url: '/tours',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tours'],
    }),
    deleteTour: builder.mutation<void, string>({
      query: (tourId) => ({
        url: `/tours/${tourId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tours'],
    }),
    validateContaminationRules: builder.mutation<
      {
        contaminationRuleViolations: Record<
          string,
          ContaminationRuleJobResponse[]
        >;
      },
      ContaminationRulesValidationRequest
    >({
      query: (body) => ({
        url: `/contamination-rules/validation`,
        method: 'POST',
        body,
      }),
    }),
    editVirtualTourDetails: builder.mutation<
      RecalculateTourDetailsResponse,
      EditVirtualTourDetailsPayload
    >({
      query: (body) => ({
        url: `/routes/optimize/job-precedence`,
        method: 'POST',
        body,
      }),
      transformResponse: recalculateTourDetailsResponseNormalizer,
    }),
    editTourDetails: builder.mutation<
      RecalculateTourDetailsResponse,
      {
        body: RecalculateTourDetailsPayload;
        tourId: OptimizedTourModel['id'];
        tourNote: OptimizedTourModel['note'];
      }
    >({
      queryFn: async (
        { body: requestBody, tourId, tourNote },
        _api,
        _extraArgs,
        baseQuery
      ) => {
        // Tour gets re-calculated but not saved in the database yet
        const {
          data: recalculateTourResponseData,
          error: recalculateTourResponseError,
        } = await baseQuery({
          url: `/routes/optimize/${tourId}/job-precedence`,
          method: 'PUT',
          body: requestBody,
        });

        if (recalculateTourResponseError) {
          return { error: recalculateTourResponseError };
        }

        const recalculatedTour = recalculateTourDetailsResponseNormalizer(
          recalculateTourResponseData as RecalculateTourDetailsResponseDto
        );

        // Request to actually save the re-calculated tour in the database
        const { error: RecalculateTourDetailsResponseError } = await baseQuery({
          url: `/tours/${tourId}`,
          method: 'PUT',
          body: editTourDataToPayload({
            ...recalculatedTour.optimizedTour,
            note: tourNote,
          }),
        });

        if (RecalculateTourDetailsResponseError) {
          return { error: RecalculateTourDetailsResponseError };
        }

        return {
          data: recalculatedTour,
        };
      },
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: 'TourDetails', id: arg.tourId }, 'Tours'] : [],
    }),
    editTourNote: builder.mutation<
      void,
      { tourId: TourDto['tourUid']; notes: EditTourNoteRequestParam }
    >({
      query: ({ tourId, notes }) => ({
        url: `/tours/${tourId}`,
        method: 'PATCH',
        body: {
          notes,
        },
      }),
      invalidatesTags: ['Tours'],
    }),
  }),
});

export const {
  useGetToursQuery,
  useOptimizeToursMutation,
  useCreateToursMutation,
  useGetTourDetailsQuery,
  useDeleteTourMutation,
  useValidateContaminationRulesMutation,
  useEditTourDetailsMutation,
  useEditVirtualTourDetailsMutation,
  useEditTourNoteMutation,
} = toursApi;
