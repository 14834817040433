export const toursApiErrors: Record<string, string> = {
  FLEET_TOUR_UID_NOT_FOUND: 'fleetTourUidNotFound',
  VEHICLES_CORE_NOT_FOUND: 'vehiclesCoreNotFound',
  FLEET_JOB_UIDS_NOT_FOUND: 'fleetJobUidsNotFound',
  CANNOT_MODIFY_FINISHED_FLEET_TOUR: 'cannotModifyFinishedFleetTour',
  ERROR_WHILE_PUSHING_MODIFIED_TOUR_TO_NAVISION:
    'errorWhilePushingModifiedTourToNavision',
  ERROR_WHILE_PUSHING_DELETED_TOUR_TO_NAVISION:
    'errorWhilePushingDeletedTourToNavision',
  CANNOT_PATCH_DETAILS_OF_FINISHED_FLEET_TOUR:
    'cannotPatchDetailsOfFinishedFleetTour',
  ERROR_WHILE_PUSHING_NEW_TOURS_TO_NAVISION:
    'errorWhilePushingNewToursToNavision',
  JOB_WITH_INVALID_BUSINESS_ACCOUNT: 'jobWithInvalidBusinessAccount',
  LOCATIONS_ASSOCIATED_WITH_JOBS_NOT_FOUND:
    'locationsAssociatedWithJobsNotFound',
  GEO_LOCATION_NOT_ASSIGNED_TO_JOB: 'geoLocationNotAssignedToJob',
  INVALID_JOB_ORDER_SEQUENCE: 'invalidJobOrderSequence',
  JOB_PRODUCT_NOT_ASSIGNED_TO_ORIGIN_LOCATION:
    'jobProductNotAssignedToOriginLocation',
  ORIGIN_LOCATION_DOES_NOT_HAVE_ASSIGNED_PRODUCTS:
    'originLocationDoesNotHaveAssignedProducts',
  TOUR_CANNOT_BE_OPTIMIZED: 'tourCannotBeOptimized',
  INVALID_UPDATED_TOUR_JOB_VISIT_ORDER: 'invalidUpdatedTourJobVisitOrder',
  OPTIMIZE_TOUR_JOBS_OUTSIDE_OF_GLOBAL_TIME_WINDOW:
    'optimizeTourJobsOutsideOfGlobalTimeWindow',
  JOB_WITH_INVALID_LOCATION: 'jobWithInvalidLocation',
  INVALID_CONTAMINATION_RULE_TYPE: 'invalidContaminationRuleType',
  NONE_OF_THE_JOBS_FIT_IN_TOUR: 'noneOfTheJobsFitInTheTour',
};
