import {
  JobModel,
  useGetJobPoolQuery,
} from '@energy-stacks/fleet/feature-jobs-data';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { toPayloadDate } from '@energy-stacks/shared';
import { useEffect, useState } from 'react';

type Params = {
  jobIds: JobModel['jobId'][];
  startDate: string | undefined;
  endDate: string | undefined;
};

export const useJobsPool = (
  { jobIds, startDate, endDate }: Params,
  { skip = false }
) => {
  const [jobPool, setJobPool] = useState<JobModel[]>();
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );

  const { data, isError, isLoading, refetch, isFetching } = useGetJobPoolQuery(
    {
      jobUids: jobIds,
      depotId: selectedPlantId || '',
      status: 'TO_DO',
      endDate: toPayloadDate(
        endDate ? new Date(endDate) : new Date(),
        'yyyy-MM-dd'
      ),
      startDate: toPayloadDate(
        startDate ? new Date(startDate) : new Date(),
        'yyyy-MM-dd'
      ),
    },
    { skip }
  );

  useEffect(() => {
    if (data && !isFetching) {
      setJobPool(data);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (isError) {
      setJobPool(undefined);
    }
  }, [isError]);

  return {
    jobPool,
    setJobPool,
    isLoadingJobPool: isLoading,
    isGetJobPoolError: isError,
    refetch,
  };
};
