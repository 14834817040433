import {
  appendZToDateString,
  zeroOutSecondsAndMilliseconds,
} from '@energy-stacks/shared';
import { TourDto } from '../tourDto';
import { TourModel } from '../tourModel';
import {
  FALLBACK_TIMEZONE_ID,
  jobStatusMap,
  jobStatusesDto,
} from '@energy-stacks/fleet/shared';
import {
  emptyRunsNormalizer,
  emptyRunsMetricsNormalizer,
} from './emptyRunsNormalizer';
import { transitionsNormalizer } from './transitionsNormalizer';
import { differenceInSeconds } from 'date-fns';

export const toursNormalizer = (toursDto: TourDto[]): TourModel[] => {
  return toursDto.map(tourNormalizer);
};

export const tourNormalizer = (tourDto: TourDto): TourModel => {
  // Needed in order for rounding to work.
  // If displayed duration is computed from startDate and andDate there could be mismatch with totalTime BE field
  const durationWithoutSeconds = Math.abs(
    differenceInSeconds(
      zeroOutSecondsAndMilliseconds(new Date(tourDto.startDate)),
      zeroOutSecondsAndMilliseconds(new Date(tourDto.endDate))
    )
  );

  return {
    tourId: tourDto.tourUid,
    plantId: tourDto.depotId,
    status: tourDto.status,
    date: appendZToDateString(tourDto.startDate),
    startDate: appendZToDateString(tourDto.startDate),
    endDate: appendZToDateString(tourDto.endDate),
    timeZoneId: tourDto.timeZoneId ?? FALLBACK_TIMEZONE_ID,
    confirmedStartDate: appendZToDateString(tourDto.confirmedStartDate),
    confirmedEndDate: appendZToDateString(tourDto.confirmedEndDate),
    forecastedEndDate: tourDto.estimatedEndDate
      ? appendZToDateString(tourDto.estimatedEndDate)
      : null,
    isViolatingTimeWindow: Boolean(tourDto.violatingTimeWindow),
    duration: {
      time: durationWithoutSeconds,
      distance: tourDto.totalDistance,
    },
    totalJobs: tourDto.numberOfJobs,
    quantity: tourDto.productQuantities,
    vehicle: {
      brandId: tourDto.vehicle.brandUuid,
      name: tourDto.vehicle.name,
      id: tourDto.vehicle.vehicleIdentificationNumber,
      isDeleted: Boolean(tourDto.vehicle.deleted),
    },
    isViolatingContaminationRules: tourDto.hasPenaltyRuleViolation,
    jobsCountPerStatus: jobsStatusProgressNormalizer(
      tourDto.jobsStatusProgress
    ),
    emptyRunsMetrics: emptyRunsMetricsNormalizer(tourDto.transitionMetrics),
    emptyRuns: tourDto.transitionMetrics?.transitions
      ? emptyRunsNormalizer(
          tourDto.transitionMetrics.transitions.filter((t) => t.vehicleIsEmpty)
        )
      : [],
    transitions: tourDto.transitionMetrics
      ? transitionsNormalizer(tourDto.transitionMetrics.transitions)
      : [],
    note: tourDto.notes || '',
  };
};

export const jobsStatusProgressNormalizer = (
  jobsStatusProgress: TourDto['jobsStatusProgress']
): TourModel['jobsCountPerStatus'] => {
  const jobsCountPerStatus = {} as TourModel['jobsCountPerStatus'];
  jobStatusesDto.forEach((statusDto) => {
    jobsCountPerStatus[jobStatusMap[statusDto]] =
      jobsStatusProgress[statusDto]?.length ?? 0;
  });
  return jobsCountPerStatus;
};
