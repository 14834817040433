import { JobStatus } from '@energy-stacks/fleet/shared';
import { EmptyRunsMetrics } from './emptyRunsMetrics';
import { Process } from './optimizedTourModel';
import { TransitionModel } from './transitionModel';

export type TourModel = {
  tourId: string;
  plantId: string;
  status: TourStatus;
  date: string;
  polyline: string;
  startDate: string;
  endDate: string;
  confirmedStartDate: string | null;
  confirmedEndDate: string | null;
  forecastedEndDate: string | null;
  isViolatingTimeWindow?: boolean | null;
  duration: {
    time: number;
    distance: number;
  };
  totalJobs: number;
  quantity: Record<string, number>;
  vehicle: TourVehicle;
  isViolatingContaminationRules?: boolean;
  jobsCountPerStatus: Record<JobStatus, number>;
  emptyRunsMetrics: EmptyRunsMetrics;
  emptyRuns: Process[];
  transitions: TransitionModel[];
  note: string;
};

export const tourStatuses = [
  'ENROUTE',
  'PLANNED',
  'DELAYED',
  'FAILED',
  'DONE',
  'INCOMPLETE',
] as const;

export type TourStatus = (typeof tourStatuses)[number];

export type TourVehicle = {
  brandId: string;
  name: string;
  id: string;
};
