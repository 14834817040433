import { Radio, Stack, styled, Typography } from '@mui/material';
import { EditingTourJob } from './editingTourJobModel';
import { useTranslation } from 'react-i18next';
import {
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import {
  JobDueDateCell,
  JobLocationCell,
} from '@energy-stacks/fleet/feature-jobs';
import { ProductTypeChip } from '@energy-stacks/fleet/shared';
import {
  ESLoadingIndicator,
  NoTableData,
  RefetchButton,
  RefetchOnError,
} from '@energy-stacks/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { ReactComponent as SmartRecommendationIconChecked } from './smart-recommendation-check-icon.svg';
import { useSmartRecommendationQuery } from '@energy-stacks/fleet/feature-tours-data';
import { useParams } from 'react-router-dom';

type SmartRecommendationJobsTableProps = {
  onJobSelected: (job: EditingTourJob) => void;
  selectedJob: EditingTourJob | null;
  tourJobId: string | null;
  onError: () => void;
};

const SmartRecommendationRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 15,
  height: 15,
  border: '1px solid',
  borderColor: theme.palette.grey['500'],
}));

export const SmartRecommendationJobsTable: React.FC<
  SmartRecommendationJobsTableProps
> = ({ selectedJob, onJobSelected, tourJobId, onError }) => {
  const [t] = useTranslation('jobs');
  const [tTours] = useTranslation('tours');
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const { tourId } = useParams<{ tourId: string }>();
  const { isLoading, data, isError, refetch } = useSmartRecommendationQuery(
    {
      selectedJobUid: tourJobId || '',
      tourId: tourId || '', // Should never be undefined
    },
    {
      skip: !tourJobId || !tourId,
    }
  );

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError, onError]);

  const columnHelper = createColumnHelper<EditingTourJob>();
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.jobId, {
        id: 'selection',
        header: () => null,
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return (
            <Radio
              onClick={(e) => e.stopPropagation()}
              sx={{ padding: 0 }}
              checked={row.getIsSelected()}
              icon={<SmartRecommendationRadioIcon />}
              checkedIcon={<SmartRecommendationIconChecked />}
              onChange={() => {
                onJobSelected(row.original);
              }}
            />
          );
        },
        size: 10,
        enableSorting: false,
        minSize: 10,
      }),
      columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
        id: 'jobId',
        header: () => t('jobId'),
        footer: (props) => props.column.id,
        cell: (info) => removePlantIdPrefix(info.getValue()),
        size: 140,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.origin?.id, {
        id: 'origin',
        header: () => t('origin'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            supplierName={row.original.supplier.supplierName}
            jobType={row.original.jobType}
            locationType="origin"
            location={row.original.origin}
          />
        ),
        enableSorting: false,
        meta: {
          canDisable: true,
        },
      }),
      columnHelper.accessor((row) => row.destination?.id, {
        id: 'destination',
        header: () => t('destination'),
        footer: (props) => props.column.id,
        size: 250,
        cell: ({ row }) => (
          <JobLocationCell
            location={row.original.destination}
            supplierName={row.original.supplier.supplierName}
            jobType={row.original.jobType}
            locationType="destination"
          />
        ),
        enableSorting: false,
        meta: {
          canDisable: true,
        },
      }),
      columnHelper.accessor('product.name', {
        id: 'product',
        header: () => t('productType'),
        footer: (props) => props.column.id,
        size: 125,
        enableSorting: false,
        cell: ({ row, table }) =>
          row.original.product ? (
            <ProductTypeChip productName={row.original.product.name} />
          ) : null,
        enableGlobalFilter: false,
        meta: {
          canDisable: true,
        },
      }),
      columnHelper.accessor('dueDate', {
        header: () => t('dueDate'),
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <JobDueDateCell
            dueDate={row.original.dueDate}
            timeZoneId={row.original.timeZoneId}
          />
        ),
        size: 130,
      }),
    ],
    [columnHelper, onJobSelected, removePlantIdPrefix, t]
  );

  const rowSelectionState = useMemo(() => {
    if (!selectedJob) {
      return {};
    }

    return {
      [selectedJob.jobId]: true,
    };
  }, [selectedJob]);

  const { instance, allRows } = useESTableBasic(data, columns, {
    getRowId: (row) => row.jobId,
    manualPagination: true,
    state: {
      rowSelection: rowSelectionState,
    },
  });

  if (isLoading) {
    return (
      <Stack height={200} justifyContent="center">
        <ESLoadingIndicator />
      </Stack>
    );
  }

  if (isError) {
    return (
      <Stack height={200} justifyContent="center" alignItems="center">
        <Typography variant="body1" sx={{ mb: 4 }}>
          {tTours('couldNotRecommendJobs')}
        </Typography>
        <RefetchButton onRefetch={refetch} />
      </Stack>
    );
  }

  return (
    <ESTableWrapper>
      <ESTable maxHeight="42vh" minHeight={200}>
        <ESTableHead instance={instance} />
        {!isError ? (
          <ESTableBody
            rows={allRows}
            onRowClick={(row) => {
              onJobSelected(row.original);
            }}
          />
        ) : (
          <RefetchOnError onRefetch={refetch} />
        )}
      </ESTable>
      {allRows.length === 0 ? (
        <NoTableData message={tTours('couldNotRecommendJobs')} />
      ) : null}
    </ESTableWrapper>
  );
};
