import { useAppSelector } from '@energy-stacks/fleet/store';
import { useLocalStorage } from 'usehooks-ts';

export const usePersistedVehicleFilter = (tableId?: string) => {
  // Needed to distinguish vehicle filter selection between plants
  const selectedPlantId = useAppSelector(
    (state) => state.plant.selectedPlantId
  );
  return useLocalStorage(
    `${tableId ? tableId + '-' : ''}vehicle-column-filter-${selectedPlantId}`,
    [] as string[]
  );
};
