import { DateRange } from '@energy-stacks/core/date-range-picker';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { FilterFn } from '@tanstack/react-table';
import {
  areIntervalsOverlapping,
  isAfter,
  isEqual,
  isWithinInterval,
  Interval,
  parseISO,
} from 'date-fns';

const isJobWindowWithinDateRange = (
  dateRange: Interval,
  jobWindow: Interval | null
) => {
  if (
    jobWindow &&
    (isAfter(jobWindow.end, jobWindow.start) ||
      isEqual(jobWindow.end, jobWindow.start))
  ) {
    return areIntervalsOverlapping(dateRange, jobWindow);
  }
};

export const jobsTableDateRangeFilterFn: FilterFn<JobModel> = (
  row,
  _,
  filterValue: DateRange | undefined
) => {
  if (!filterValue) {
    return true;
  }

  const filterInterval = {
    start: filterValue.startDate,
    end: filterValue.endDate,
  };

  const dates = {
    datePickUpFrom: row.original.datePickUpFrom,
    datePickUpTill: row.original.datePickUpTill,
    dateDropOffFrom: row.original.dateDropOffFrom,
    dateDropOffTill: row.original.dateDropOffTill,
    startDate: row.original.startDate,
    endDate: row.original.endDate,
  };

  const jobPickUpWindow =
    dates.datePickUpFrom && dates.datePickUpTill
      ? {
          start: new Date(dates.datePickUpFrom),
          end: new Date(dates.datePickUpTill),
        }
      : null;

  const jobDropOffWindow =
    dates.dateDropOffFrom && dates.dateDropOffTill
      ? {
          start: new Date(dates.dateDropOffFrom),
          end: new Date(dates.dateDropOffTill),
        }
      : null;

  const jobPlannedWindow =
    dates.startDate && dates.endDate
      ? {
          start: new Date(dates.startDate),
          end: new Date(dates.endDate),
        }
      : null;

  if (
    isJobWindowWithinDateRange(filterInterval, jobPickUpWindow) ||
    isJobWindowWithinDateRange(filterInterval, jobDropOffWindow) ||
    isJobWindowWithinDateRange(filterInterval, jobPlannedWindow)
  ) {
    return true;
  }

  // If there is no job time window check individual values. Shouldn't happen quite often.
  return Object.values(dates).some((date) => {
    if (date && isWithinInterval(parseISO(date), filterInterval)) {
      return true;
    }
    return false;
  });
};
