import { APICategory, APICategoryName } from './apiCategory';

const apiCategorySortOrder: APICategoryName[] = [
  'CPO Services',
  'Broker Services',
  'MSP Services',
  'Fleet Services',
  'Other',
];

export const apiCategorySort = (
  categoryA: APICategory,
  categoryB: APICategory
) => {
  const indexA = apiCategorySortOrder.indexOf(categoryA.name);
  const indexB = apiCategorySortOrder.indexOf(categoryB.name);

  return indexA - indexB;
};
