import {
  ESFullScreenLoadingIndicator,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  OptimizedTourModel,
  SkippedJob,
  useEditTourDetailsMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { editTourApiErrors } from './editTourApiErrors';
import { TourDetailsEditMode } from './TourDetailsEditMode';
import { EditTourDetailsContextProvider } from './EditTourDetailsContext';
import { TourDetailsEditModeHeader } from './TourDetailsEditModeHeader';
import { TourDetails } from '../create-tour-steps/ReviewStep';
import { SkippedJobsAlert } from '../create-tour-steps/SkippedJobsAlert';
import { useInvalidateJobPool } from '@energy-stacks/fleet/feature-jobs';
import { useUpdateEffect } from 'usehooks-ts';
import { TourJobsTableHeader } from '../tour-details/TourJobsTableHeader';

type EditTourDetailsPageProps = {
  tourDetails: OptimizedTourModel;
  onCancelEditing: () => void;
};

export const EditTourDetailsPage: React.FC<EditTourDetailsPageProps> = ({
  tourDetails,
  onCancelEditing,
}) => {
  const { tourId } = useParams<{ tourId: string }>();
  const [t] = useTranslation('tours');
  useDocumentTitle(t('pageTitle'));
  const [tourDetailsState, setTourDetailsState] = useState<TourDetails>({
    index: 0,
    tour: tourDetails,
    initialTour: tourDetails,
  });
  const { showSnackbar } = useESSnackbar();
  const [editTourDetails, { isLoading: isEditingTour }] =
    useEditTourDetailsMutation();
  const [skippedJobs, setSkippedJobs] = useState<SkippedJob[]>([]);
  const invalidateJobPool = useInvalidateJobPool();

  useUpdateEffect(() => {
    // Invalidate local state after tour details are re-fetched
    setTourDetailsState({
      index: 0,
      tour: tourDetails,
      initialTour: tourDetails,
    });
  }, [tourDetails]);

  const handleRecalculateTour = useCallback(() => {
    if (!tourDetailsState.tour || !tourId) {
      return;
    }

    editTourDetails({
      body: {
        jobs: tourDetailsState.tour.tourJobs.jobs.map((job) => ({
          jobUID: job.jobId,
          jobPrecedence: job.visitOrder,
        })),
      },
      tourId,
      tourNote: tourDetailsState.tour.note,
    })
      .unwrap()
      .then((response) => {
        if (response.skippedJobs.length) {
          invalidateJobPool();
        }
        setSkippedJobs(response.skippedJobs);
        showSnackbar('success', 'editTourSuccess', 'tours');
      })
      .catch((error) => {
        const errorCode = error.data?.errorCode;
        showSnackbar(
          'error',
          errorCode && editTourApiErrors[errorCode]
            ? `editTourDetailsApiErrors.${editTourApiErrors[errorCode]}`
            : undefined,
          'tours'
        );
      });
  }, [
    editTourDetails,
    invalidateJobPool,
    showSnackbar,
    tourDetailsState.tour,
    tourId,
  ]);

  return (
    <EditTourDetailsContextProvider
      config={{
        isEditTourModeActive: true,
        tourDetails: tourDetailsState,
        jobIds: tourDetails
          ? tourDetails.tourJobs.jobs.map((tourJob) => tourJob.jobId)
          : [],
        onCancelEditing,
        onTourDetailsChange: (value) => {
          if (!value) {
            // Should never be undefined
            return;
          }
          setTourDetailsState(value);
        },
      }}
    >
      <Stack sx={{ minHeight: '100%' }} gap={4}>
        <SkippedJobsAlert
          skippedJobs={skippedJobs ?? []}
          totalJobsCount={
            skippedJobs
              ? tourDetails.tourJobs.jobs.length + skippedJobs.length
              : 0
          }
          onDismiss={() => setSkippedJobs([])}
        />
        <Stack direction="column" gap={6}>
          <TourDetailsEditModeHeader
            onRecalculateTour={handleRecalculateTour}
            shouldDisableSave={
              !tourDetailsState ||
              !tourDetailsState.tour.tourJobs.jobs.length ||
              tourDetailsState.tour.tourJobs.jobs.some(
                (job) => job.status === 'CANCELLED' || job.status === 'FAILED'
              )
            }
          />
        </Stack>
        <TourDetailsEditMode
          tourJobs={tourDetailsState.tour.tourJobs.jobs}
          TourJobsTableHeader={
            <TourJobsTableHeader
              vehicleName={tourDetails.vehicleName}
              vehicleIndex={0}
              startDate={tourDetails.startDate}
            />
          }
        />
      </Stack>
      {isEditingTour ? (
        <ESFullScreenLoadingIndicator text={t('editTourDetailsLoadingText')} />
      ) : null}
    </EditTourDetailsContextProvider>
  );
};
