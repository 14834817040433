import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery, toPayloadDate } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { vehiclesNormalizer } from './normalizers/vehiclesNormalizer';
import { VehicleDto } from './vehicleDto';
import { CoreVehicleBrandDto, CoreVehicleDto } from './coreVehicleDto';
import { AddVehiclePayload } from './addVehiclePayload';
import { VehicleModel } from './vehicleModel';
import { endOfDay, startOfDay } from 'date-fns';
import { VehicleDetailsModel } from './vehicleDetailsModel';
import { vehicleDetailsNormalizer } from './normalizers/vehicleDetailsNormalizer';
import { editVehicleGeneralInfoToPayload } from './normalizers/editVehicleGeneralInfoToPayload';
import { WorkingHours } from './workingHours';
import { regularWorkingHoursToPayload } from './normalizers/regularWorkingHoursToPayload';
import { exceptionalWorkingHoursToPayload } from './normalizers/exceptionalWorkingHoursToPayload';

type VehicleBrandImage = {
  id: string;
  image: string;
};

export interface GetVehiclesRequestBody {
  dateFrom?: string;
  dateTo?: string;
}

export type EditVehicleGeneralInfoData = Partial<{
  plantId: VehicleDetailsModel['plantId'];
  name: VehicleDetailsModel['name'];
  coreVehicleId: string;
  licencePlate?: VehicleDetailsModel['licencePlate'];
  note: VehicleDetailsModel['note'];
}>;

export type EditVehicleRegularOperatingHoursData = {
  regularWorkingHours: WorkingHours['regular'];
  vehicleIdentificationNumber: VehicleDetailsModel['vehicleId'];
};

export type EditVehicleExceptionalOperatingHoursData = {
  exceptionalWorkingHours: WorkingHours['exceptional'];
  vehicleIdentificationNumber: VehicleDetailsModel['vehicleId'];
};

export const vehiclesApi = createApi({
  reducerPath: 'vehicles',
  keepUnusedDataFor: 0,
  tagTypes: [
    'Vehicles',
    'VehicleDetails',
    'CoreVehicles',
    'CoreVehicleBrands',
    'VehicleBrandImages',
  ],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/vehicles`),
  endpoints: (builder) => ({
    getVehicles: builder.query<
      VehicleModel[],
      GetVehiclesRequestBody | undefined
    >({
      query: (searchParams) => ({
        url: `/`,
        method: 'GET',
        params: {
          dateFrom:
            searchParams?.dateFrom ?? toPayloadDate(startOfDay(new Date())),
          dateTo: searchParams?.dateTo ?? toPayloadDate(endOfDay(new Date())),
        },
      }),
      providesTags: ['Vehicles'],
      transformResponse: vehiclesNormalizer,
    }),
    getVehicleDetails: builder.query<VehicleDetailsModel, string>({
      query: (vehicleId) => `/${vehicleId}`,
      transformResponse: vehicleDetailsNormalizer,
      providesTags: (result) => [
        {
          type: 'VehicleDetails',
          id: result?.vehicleId,
        },
      ],
    }),
    createVehicle: builder.mutation<VehicleDto, AddVehiclePayload>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: body,
        params: {
          vehicleIdentificationNumber: body.vehicleIdentificationNumber,
        },
      }),
      invalidatesTags: ['Vehicles'],
    }),
    deleteVehicle: builder.mutation<void, string>({
      query: (vehicleId) => ({
        url: `/${vehicleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vehicles'],
    }),
    editVehicleGeneralInfo: builder.mutation<
      void,
      { body: EditVehicleGeneralInfoData; vehicleId: VehicleModel['vehicleId'] }
    >({
      query: ({ body, vehicleId }) => ({
        url: `/${vehicleId}`,
        method: 'PATCH',
        body: editVehicleGeneralInfoToPayload(body),
      }),
      invalidatesTags: ['Vehicles'],
    }),
    editVehicleRegularOperatingHours: builder.mutation<
      void,
      EditVehicleRegularOperatingHoursData
    >({
      query: (body) => ({
        url: `/${body.vehicleIdentificationNumber}/regular-working-hours/`,
        method: 'PUT',
        body: regularWorkingHoursToPayload(body.regularWorkingHours),
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { type: 'VehicleDetails', id: arg.vehicleIdentificationNumber },
              'Vehicles',
            ]
          : [],
    }),
    editVehicleExceptionalOperatingHours: builder.mutation<
      void,
      EditVehicleExceptionalOperatingHoursData
    >({
      query: (body) => ({
        url: `/${body.vehicleIdentificationNumber}/exceptional-working-hours/`,
        method: 'PUT',
        body: exceptionalWorkingHoursToPayload(body.exceptionalWorkingHours),
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { type: 'VehicleDetails', id: arg.vehicleIdentificationNumber },
              'Vehicles',
            ]
          : [],
    }),
    getCoreVehicles: builder.query<CoreVehicleDto[], void>({
      query: () => '/core',
      providesTags: ['CoreVehicles'],
    }),
    getCoreVehicleBrands: builder.query<CoreVehicleBrandDto[], void>({
      query: () => '/core/vehicle-brands',
      providesTags: ['CoreVehicleBrands'],
    }),
    getVehicleBrandImages: builder.query<
      VehicleBrandImage[],
      CoreVehicleBrandDto['uuid'][]
    >({
      keepUnusedDataFor: 120,
      async queryFn(brandIds, _queryApi, _extraOptions, fetchWithBQ) {
        const logos = [];
        let error;

        for (const brandId of brandIds) {
          const response = await fetchWithBQ({
            url: `/core/vehicle-brands/${brandId}/image`,
            responseHandler: async (response) => {
              const blob = await response.blob();
              return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64String = reader.result as string;
                  resolve(base64String);
                };
                reader.onerror = () => {
                  reject(new Error('Failed to read image file.'));
                };
                reader.readAsDataURL(blob);
              });
            },
          });
          if (response.error) {
            error = response.error;
          } else {
            logos.push({
              id: brandId,
              image: response.data,
            } as VehicleBrandImage);
          }
        }
        if (logos.length > 0) {
          return { data: logos };
        }
        return error ? { error: error } : { data: logos };
      },
      providesTags: (result) => {
        return (
          result?.map((brand) => ({
            type: 'VehicleBrandImages',
            id: brand.id,
          })) ?? []
        );
      },
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetVehicleDetailsQuery,
  useCreateVehicleMutation,
  useDeleteVehicleMutation,
  useEditVehicleGeneralInfoMutation,
  useEditVehicleExceptionalOperatingHoursMutation,
  useEditVehicleRegularOperatingHoursMutation,
  useGetCoreVehiclesQuery,
  useGetCoreVehicleBrandsQuery,
  useGetVehicleBrandImagesQuery,
} = vehiclesApi;
