import { appendZToDateString } from '@energy-stacks/shared';
import { TourJobs } from '../optimizedTourModel';
import { RouteJobWebResponse } from '../routeJobWebResponse';
import {
  jobStatusMap,
  productNormalizer,
  routePointNormalizer,
} from '@energy-stacks/fleet/shared';
import { OptimizedTourDto } from '../optimizeToursWebResponse';
import { contaminationViolationNormalizer } from './contaminationViolationNormalizer';
import { TourStatusDto } from '../tourDto';
import { TransitionMetricsDto } from '../transitionMetricsDto';
import { emptyRunsNormalizer } from './emptyRunsNormalizer';

type VehicleBreak = {
  startDateTime: string;
  endDateTime: string;
  // id of a job AFTER which vehicle takes a break. If tour starts with break jobUid will be null.
  jobUid: string | null;
};

export type TourDetailsDto = {
  tourUid: string;
  vehicle: {
    vehicleIdentificationNumber: string;
    name: string;
  };
  totalTime: number;
  totalDistance: number;
  polyline: string;
  startDate: string;
  endDate: string;
  status?: TourStatusDto;
  jobs: RouteJobWebResponse[];
  vehicleBreak: VehicleBreak;
  transitionMetrics?: TransitionMetricsDto;
  contaminationRuleViolations: OptimizedTourDto['contaminationRuleViolations'];
  notes?: string | null;
};

const DEFAULT_BREAK_ID = 'break';

type Params = {
  vehicleBreak: TourDetailsDto['vehicleBreak'];
  transitionMetrics: TourDetailsDto['transitionMetrics'];
  jobs: TourDetailsDto['jobs'];
  contaminationRuleViolations: TourDetailsDto['contaminationRuleViolations'];
};

export const optimizedTourJobsNormalizer = ({
  jobs,
  transitionMetrics,
  vehicleBreak,
  contaminationRuleViolations,
}: Params): TourJobs => {
  //NOTE: Only one break is supported for now. Can be extended in the future
  const vehicleBreaksNormalized = [vehicleBreak].map((vehicleBreak) => ({
    // NOTE: Since break id is used only for column accessor in the table, and there will only be one break,
    // if the vehicleBreak.jobUid is null it should be safe to default it to hardcoded string.
    id: vehicleBreak.jobUid || DEFAULT_BREAK_ID,
    type: 'BREAK' as const,
    startTime: appendZToDateString(vehicleBreak.startDateTime),
    endTime: appendZToDateString(vehicleBreak.endDateTime),
  }));
  const emptyRunsNormalized = transitionMetrics?.transitions
    ? emptyRunsNormalizer(
        transitionMetrics.transitions.filter((t) => t.vehicleIsEmpty)
      )
    : [];

  return {
    jobs: jobs
      .map((jobDto) => {
        return {
          jobId: jobDto.jobUid,
          plantId: jobDto.depotId ?? '',
          jobType: jobDto.jobType,
          visitOrder: jobDto.jobVisitOrder,
          businessAccountUid: jobDto.businessAccountUid || '',
          supplier: jobDto.businessAccountUid
            ? {
                supplierId: jobDto.businessAccountUid,
                supplierName: jobDto.companyName ?? '',
              }
            : {
                supplierId: '',
                supplierName: '',
              },
          startTime: appendZToDateString(jobDto.startDate),
          endTime: appendZToDateString(jobDto.endDate),
          confirmedStartTime: jobDto.confirmedStartDate
            ? appendZToDateString(jobDto.confirmedStartDate)
            : null,
          confirmedEndTime: jobDto.confirmedEndDate
            ? appendZToDateString(jobDto.confirmedEndDate)
            : null,
          datePickUpTill: jobDto.datePickUpTill
            ? appendZToDateString(jobDto.datePickUpTill)
            : null,
          dateDropOffTill: jobDto.dateDropOffTill
            ? appendZToDateString(jobDto.dateDropOffTill)
            : null,
          datePickUpFrom: jobDto.datePickUpFrom
            ? appendZToDateString(jobDto.datePickUpFrom)
            : null,
          dateDropOffFrom: jobDto.dateDropOffFrom
            ? appendZToDateString(jobDto.dateDropOffFrom)
            : null,
          duration: jobDto.totalTime ?? 0,
          product: productNormalizer(jobDto.product ?? undefined),
          origin: routePointNormalizer(jobDto.origin ?? undefined),
          destination: routePointNormalizer(jobDto.destination ?? undefined),
          contaminationViolation: null,
          status: jobDto.status ? jobStatusMap[jobDto.status] : undefined,
        };
      })
      .map((job, _, jobs) => ({
        ...job,
        contaminationViolation: contaminationViolationNormalizer(
          Object.values(contaminationRuleViolations ?? []).flat(),
          job,
          jobs
        ),
      })),
    processes: [...vehicleBreaksNormalized, ...emptyRunsNormalized],
  };
};
