import {
  appendZToDateString,
  PageEntry,
  zeroOutSecondsAndMilliseconds,
} from '@energy-stacks/shared';
import { DashboardTourDto } from '../dashboardTourDto';
import { DashboardTourModel, DashboardTours } from '../dashboardTourModel';
import {
  emptyRunsMetricsNormalizer,
  emptyRunsNormalizer,
  jobsStatusProgressNormalizer,
  transitionsNormalizer,
} from '@energy-stacks/fleet/feature-tours-data';
import { differenceInSeconds } from 'date-fns';

export const dashboardToursNormalizer = (
  dashboardToursEntry: PageEntry<DashboardTourDto>
): DashboardTours => {
  return {
    totalElements: dashboardToursEntry.totalElements ?? 0,
    totalPages: dashboardToursEntry.totalPages ?? -1,
    tours:
      dashboardToursEntry?.content?.map((tourDto) => {
        // Needed in order for rounding to work.
        // If displayed duration is computed from startDate and andDate there could be mismatch with totalTime BE field
        const durationWithoutSeconds = Math.abs(
          differenceInSeconds(
            zeroOutSecondsAndMilliseconds(new Date(tourDto.startDate)),
            zeroOutSecondsAndMilliseconds(new Date(tourDto.endDate))
          )
        );
        const tour: DashboardTourModel = {
          tourId: tourDto.tourUid,
          plantId: tourDto.depotId ?? '',
          status: tourDto.status,
          date: appendZToDateString(tourDto.startDate),
          startDate: appendZToDateString(tourDto.startDate),
          endDate: appendZToDateString(tourDto.endDate),
          confirmedStartDate: appendZToDateString(tourDto.confirmedStartDate),
          confirmedEndDate: appendZToDateString(tourDto.confirmedEndDate),
          forecastedEndDate: tourDto.estimatedEndDate
            ? appendZToDateString(tourDto.estimatedEndDate)
            : null,
          isViolatingTimeWindow: Boolean(tourDto.violatingTimeWindow),
          duration: {
            time: durationWithoutSeconds,
            distance: tourDto.totalDistance,
          },
          totalJobs: tourDto.numberOfJobs,
          isViolatingContaminationRules: Boolean(
            tourDto.hasPenaltyRuleViolation
          ),
          vehicle: {
            brandId: tourDto.vehicle.brandUuid,
            name: tourDto.vehicle.name ?? '',
            id: tourDto.vehicle.vehicleIdentificationNumber,
            note: tourDto.vehicle.notes ?? '',
          },
          jobsCountPerStatus: jobsStatusProgressNormalizer(
            tourDto.jobsStatusProgress
          ),
          emptyRunsMetrics: emptyRunsMetricsNormalizer(
            tourDto.transitionMetrics
          ),
          emptyRuns: tourDto.transitionMetrics?.transitions
            ? emptyRunsNormalizer(
                tourDto.transitionMetrics.transitions.filter(
                  (t) => t.vehicleIsEmpty
                )
              )
            : [],
          transitions: tourDto.transitionMetrics
            ? transitionsNormalizer(tourDto.transitionMetrics.transitions)
            : [],
          note: tourDto.notes || '',
        };

        return tour;
      }) ?? [],
  };
};
