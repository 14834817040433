import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useAppLocation,
} from '@energy-stacks/shared';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  FleetPage,
  FleetRoutes,
  useNavigateBack,
} from '@energy-stacks/fleet/shared';
import { EditTourDetailsPage } from '../edit-tour/EditTourDetailsPage';
import { TourDetailsPage } from '../tour-details/TourDetailsPage';
import { EditTourInfoDialog } from '../edit-tour/EditTourInfoDialog';
import { useTranslation } from 'react-i18next';
import { useTourDetails } from './useTourDetails';
import { IconButton, Stack } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';

const TourDetailsPageTitle = () => {
  const { t } = useTranslation('tours');
  const navigateBack = useNavigateBack();
  return (
    <Stack gap={2} direction="row" alignItems="center">
      <IconButton onClick={navigateBack} sx={{ color: 'grey.500' }}>
        <IconArrowLeft />
      </IconButton>
      {t('tourDetails')}
    </Stack>
  );
};

export const TourPage = () => {
  const { tourDetails, isLoading, isError, refetch } = useTourDetails();
  const { tourId } = useParams<{ tourId: string }>();
  const [t] = useTranslation('tours');
  const handleCloseDialog = useNavigateBack(
    `${FleetRoutes.Tours}${tourId ? `/${tourId}` : ''}`
  );
  const location = useAppLocation<{ shouldEdit: boolean }>();
  const [isEditTour, setIsEditTour] = useState(
    location.state && location.state.shouldEdit
  );

  const tourInfo = useMemo(() => {
    if (tourDetails) {
      return {
        polyline: tourDetails.polyline.points,
        distance: tourDetails.distance,
        endDate: tourDetails.endDate,
        tourJobs: tourDetails.tourJobs,
        totalJobs: tourDetails.totalJobs,
        vehicleName: tourDetails.vehicleName,
        startDate: tourDetails.startDate,
        tourIndex: 0,
        tourStatus: tourDetails.status,
        emptyRunsMetrics: tourDetails.emptyRunsMetrics,
      };
    }

    return undefined;
  }, [tourDetails]);

  if (
    tourInfo &&
    tourInfo?.tourStatus !== 'PLANNED' &&
    tourInfo?.tourStatus !== 'ENROUTE' &&
    isEditTour
  ) {
    // Do not allow user to see EditTourDetailsPage if tour cannot be edited
    return (
      <>
        <EditTourInfoDialog onClose={handleCloseDialog} />
        <TourDetailsPage
          tourInfo={tourInfo}
          onEnterEditMode={() => setIsEditTour(true)}
        />
      </>
    );
  }

  return (
    <FleetPage
      title={
        isEditTour ? (
          <Stack gap={2} direction="row" alignItems="center">
            {t('editTour')}
          </Stack>
        ) : (
          <TourDetailsPageTitle />
        )
      }
    >
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {tourInfo && tourDetails ? (
        <>
          {isEditTour ? (
            <EditTourDetailsPage
              tourDetails={tourDetails}
              onCancelEditing={() => {
                setIsEditTour(false);
              }}
            />
          ) : (
            <TourDetailsPage
              tourInfo={tourInfo}
              onEnterEditMode={() => setIsEditTour(true)}
            />
          )}
        </>
      ) : null}
    </FleetPage>
  );
};
