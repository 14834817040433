import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESFormDialog,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditVehicleGeneralInfoData,
  EditVehicleGeneralInfoFormData,
  VehicleModel,
  useEditVehicleGeneralInfoMutation,
  useGetCoreVehiclesQuery,
  useGetVehicleDetailsQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { IconX } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { FleetRoutes, useNavigateBack } from '@energy-stacks/fleet/shared';
import { getEditVehicleDefaultGeneralInfo } from './getEditVehicleDefaultGeneralInfo';
import { vehicleApiErrors } from '../vehicleApiErrors';
import * as yup from 'yup';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { EditVehicleGeneralInfoFields } from './EditVehicleGeneralInfoFields';

export const EditVehicleGeneralInfo = () => {
  const [t] = useTranslation('vehicles');
  const { vehicleId } = useParams<{ vehicleId: VehicleModel['vehicleId'] }>();
  const { data: vehicle, isError } = useGetVehicleDetailsQuery(vehicleId ?? '');
  const { data: coreVehicles } = useGetCoreVehiclesQuery();
  const [editVehicle, { isLoading: isEditing }] =
    useEditVehicleGeneralInfoMutation();
  const { showSnackbar } = useESSnackbar();
  const plantIds = useAppSelector((state) => state.plant.plantIds);
  const isVehicleOperating =
    vehicle?.status === 'PLANNED' || vehicle?.status === 'ENROUTE';

  const defaultEditVehicleValues: EditVehicleGeneralInfoFormData = useMemo(
    () => getEditVehicleDefaultGeneralInfo(vehicle),
    [vehicle]
  );

  const editVehicleGeneralInfoValidationSchema = useMemo(() => {
    return yup.object().shape({
      plantId: yup.object().shape({
        label: yup
          .string()
          .oneOf(plantIds ?? [])
          .required('vehicleFormErrors.plantIdRequired'),
        values: yup.string(),
      }),
      name: yup
        .string()
        .trim()
        .max(20, 'vehicleFormErrors.nameTooLong')
        .required('vehicleFormErrors.nameRequired'),
      brand: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.brandRequired'),
        value: yup.string(),
      }),
      model: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.modelRequired'),
        value: yup.string(),
      }),
      variant: yup.object().shape({
        label: yup.string().required('vehicleFormErrors.variantRequired'),
        value: yup.string(),
      }),
      vehicleId: yup
        .string()
        .trim()
        .max(30, 'vehicleFormErrors.vehicleIdTooLong')
        .required('vehicleFormErrors.vehicleIdRequired'),
      licencePlate: yup
        .string()
        .trim()
        .max(15, 'vehicleFormErrors.licencePlateTooLong'),
      note: yup.string().max(100, 'vehicleFormErrors.noteTooLong'),
    });
  }, [plantIds]);

  const methods = useForm<EditVehicleGeneralInfoFormData>({
    defaultValues: defaultEditVehicleValues,
    mode: 'onTouched',
    resolver: yupResolver(editVehicleGeneralInfoValidationSchema),
  });

  const {
    formState: { isDirty, isValid, dirtyFields },
    handleSubmit,
    reset: resetForm,
  } = methods;

  useEffect(() => {
    if (defaultEditVehicleValues) {
      resetForm(defaultEditVehicleValues);
    }
  }, [defaultEditVehicleValues, resetForm]);

  const handleCloseDialog = useNavigateBack(FleetRoutes.Vehicles);
  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isDirty,
    onClose: () => {
      handleCloseDialog();
      resetForm();
    },
  });

  const onSubmit = useCallback(
    (data: EditVehicleGeneralInfoFormData) => {
      const coreVehicleId = coreVehicles?.find(
        (cv) =>
          cv.brandName === data.brand.value &&
          cv.model === data.model.value &&
          cv.variant === data.variant.value
      )?.vehicleCoreUuid;

      if (!coreVehicleId || !vehicle) {
        return;
      }

      const modifiedValues = Object.keys(dirtyFields).reduce((acc, field) => {
        const formField = data[field as keyof EditVehicleGeneralInfoFormData];
        return {
          ...acc,
          [field]:
            // Support values from select element which will return { label: string, value: string }
            typeof formField === 'object' && 'value' in formField
              ? formField.value
              : formField,
        };
      }, {} as EditVehicleGeneralInfoData);

      editVehicle({
        body: { ...modifiedValues },
        vehicleId: vehicle.vehicleId,
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'responseMessages.successfulUpdate',
            'vehicles'
          );

          handleCloseDialog();
        })
        .catch((error) => {
          const errorCode = vehicleApiErrors[error.data?.errorCode];
          showSnackbar(
            'error',
            errorCode ? `responseMessages.${errorCode}` : undefined,
            'vehicles'
          );
        });
    },
    [
      coreVehicles,
      dirtyFields,
      editVehicle,
      handleCloseDialog,
      showSnackbar,
      vehicle,
    ]
  );

  useEffect(() => {
    if (isError) {
      showSnackbar('error', undefined, 'vehicles');
      handleCloseDialog();
    }
  }, [isError, showSnackbar, handleCloseDialog]);

  if (!vehicle) return null;

  return (
    <ESFormDialog
      open
      fullWidth
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          showExitConfirmation();
        }
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        flexDirection="row"
        alignItems="start"
        justifyContent="space-between"
      >
        <ESDialogTitle>{t('editVehicleGeneralInfo')}</ESDialogTitle>
        <IconButton onClick={showExitConfirmation} sx={{ m: 2 }}>
          <IconX stroke={1} />
        </IconButton>
      </Stack>
      <FormProvider {...methods}>
        <ESDialogContent
          sx={{
            borderTop: '1px solid',
            borderColor: 'grey.100',
            height: 483,
          }}
        >
          <Grid container spacing={6} sx={{ paddingY: 4 }}>
            <Grid item xs={6}>
              <Typography variant="h4" fontWeight={500} color="text.dark">
                {t(`editVehicleGeneralInfoFormTitle`)}
              </Typography>
            </Grid>
            <EditVehicleGeneralInfoFields
              isVehicleOperating={isVehicleOperating}
            />
          </Grid>
        </ESDialogContent>
        <ESDialogActions>
          <ESDialogActionButton
            type="submit"
            disabled={!isValid || !isDirty}
            loading={isEditing}
            variant="contained"
          >
            {t('save')}
          </ESDialogActionButton>
        </ESDialogActions>
      </FormProvider>
    </ESFormDialog>
  );
};
