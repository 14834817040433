import { ESPopover } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { IconPackageOff } from '@tabler/icons-react';
import { useTheme } from '@mui/material';

export const CancelledJobsWarning = () => {
  const [t] = useTranslation('tours');
  const { palette } = useTheme();

  return (
    <ESPopover content={t('cancelledJobsWarning')} breakWord>
      <IconPackageOff stroke={1.5} color={palette.error.dark} />
    </ESPopover>
  );
};
