import { toPayloadDate } from '@energy-stacks/shared';
import { OptimizedTourJob, OptimizedTourModel } from './optimizedTourModel';
import { ContaminationRulesValidationRequest } from './contaminationRulesValidationRequest';

export const contaminationRulesValidationDataToPayload = ({
  tourDetails,
  tourJobs,
  nextTourJobs,
  previousTourJobs,
}: {
  tourDetails: OptimizedTourModel;
  tourJobs: OptimizedTourJob[];
  nextTourJobs?: OptimizedTourJob[];
  previousTourJobs?: OptimizedTourJob[];
}): ContaminationRulesValidationRequest => {
  return {
    vehicleIdentificationNumber: tourDetails.vehicleId,
    startRouteTime: toPayloadDate(new Date(tourDetails.startDate)),
    endRouteTime: toPayloadDate(new Date(tourDetails.endDate)),
    tourRuleRequests: tourJobs.map((job) => {
      return {
        destinationLocationUid: job.destination?.id || '',
        originLocationUid: job.origin?.id || '',
        jobUid: job.jobId,
        jobType: job.jobType,
        jobVisitOrder: job.visitOrder,
        jobStartDate: job.startTime
          ? toPayloadDate(new Date(job.startTime))
          : undefined,
        productUid: job.product?.id || '',
        businessAccountUid: job.businessAccountUid,
      };
    }),
    previousVirtualTourRuleRequests: previousTourJobs
      ? previousTourJobs.map((job) => {
          return {
            destinationLocationUid: job.destination?.id || '',
            originLocationUid: job.origin?.id || '',
            jobUid: job.jobId,
            jobStartDate: toPayloadDate(new Date(job.startTime)),
            productUid: job.product?.id || '',
            jobType: job.jobType,
            businessAccountUid: job.businessAccountUid,
          };
        })
      : undefined,
    nextVirtualTourRuleRequests: nextTourJobs
      ? nextTourJobs.map((job) => {
          return {
            destinationLocationUid: job.destination?.id || '',
            originLocationUid: job.origin?.id || '',
            jobUid: job.jobId,
            jobStartDate: toPayloadDate(new Date(job.startTime)),
            productUid: job.product?.id || '',
            jobType: job.jobType,
            businessAccountUid: job.businessAccountUid,
          };
        })
      : undefined,
  };
};
