import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESFormDialog,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { Grid, IconButton, Stack, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditVehicleOperatingHoursFormData,
  VehicleModel,
  useEditVehicleRegularOperatingHoursMutation,
  useEditVehicleExceptionalOperatingHoursMutation,
  useGetVehicleDetailsQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { IconX } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { FleetRoutes, useNavigateBack } from '@energy-stacks/fleet/shared';
import { getEditVehicleDefaultOperatingHours } from './getEditVehicleDefaultOperatingHours';
import { vehiclesApiErrors } from '../vehiclesApiErrors';
import * as yup from 'yup';
import { workingHoursValidationSchema } from '../workingHoursValidationSchema';
import { exceptionalHoursValidationSchema } from './exceptionalHoursValidationSchema';
import { EditVehicleRegularOperatingHours } from './EditVehicleRegularOperatingHours';
import { EditVehicleExceptionalOperatingHours } from './EditVehicleExceptionalOperatingHours';

const editVehicleOperatingHoursValidationSchema = yup.object().shape({
  workingHours: yup.object().shape({
    regular: yup.object().shape({
      MONDAY: workingHoursValidationSchema,
      TUESDAY: workingHoursValidationSchema,
      WEDNESDAY: workingHoursValidationSchema,
      THURSDAY: workingHoursValidationSchema,
      FRIDAY: workingHoursValidationSchema,
      SATURDAY: workingHoursValidationSchema,
      SUNDAY: workingHoursValidationSchema,
    }),
    exceptional: yup.array().of(exceptionalHoursValidationSchema),
  }),
});

type TabId = 0 | 1;
type TabsType = {
  [K in TabId]: {
    id: K;
    labelTranslationKey: string;
    onAction: (data: EditVehicleOperatingHoursFormData) => void;
    contentComponent: React.ReactNode;
  };
};

export const EditVehicleOperatingHours = () => {
  const [t] = useTranslation('vehicles');
  const { vehicleId } = useParams<{ vehicleId: VehicleModel['vehicleId'] }>();
  const { data: vehicle, isError } = useGetVehicleDetailsQuery(vehicleId ?? '');
  const [editVehicleRegularHours, { isLoading: isEditingRegularHours }] =
    useEditVehicleRegularOperatingHoursMutation();
  const [
    editVehicleExceptionalHours,
    { isLoading: isEditingExceptionalHours },
  ] = useEditVehicleExceptionalOperatingHoursMutation();
  const { showSnackbar } = useESSnackbar();
  const [activeTab, setActiveTab] = useState<TabId>(0);

  const defaultEditVehicleValues: EditVehicleOperatingHoursFormData = useMemo(
    () => getEditVehicleDefaultOperatingHours(vehicle, vehicle?.timeZoneId),
    [vehicle]
  );

  const methods = useForm<EditVehicleOperatingHoursFormData>({
    defaultValues: defaultEditVehicleValues,
    mode: 'onTouched',
    resolver: yupResolver(editVehicleOperatingHoursValidationSchema),
  });

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    reset: resetForm,
  } = methods;

  useEffect(() => {
    if (defaultEditVehicleValues) {
      resetForm(defaultEditVehicleValues);
    }
  }, [defaultEditVehicleValues, resetForm]);

  const handleCloseDialog = useNavigateBack(FleetRoutes.Vehicles);
  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isDirty,
    onClose: () => {
      handleCloseDialog();
      resetForm();
    },
  });

  const handleEditRegularHours = useCallback(
    (data: EditVehicleOperatingHoursFormData) => {
      if (!vehicle) {
        return;
      }
      editVehicleRegularHours({
        regularWorkingHours: data.workingHours.regular,
        vehicleIdentificationNumber: vehicle.vehicleId,
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'responseMessages.successfulUpdate',
            'vehicles'
          );
        })
        .catch((error) => {
          const errorCode = vehiclesApiErrors[error.data?.errorCode];
          showSnackbar(
            'error',
            errorCode ? `responseMessages.${errorCode}` : undefined,
            'vehicles'
          );
        });
    },
    [editVehicleRegularHours, showSnackbar, vehicle]
  );

  const handleEditExceptionalHours = useCallback(
    (data: EditVehicleOperatingHoursFormData) => {
      if (!vehicle) {
        return;
      }
      editVehicleExceptionalHours({
        exceptionalWorkingHours: data.workingHours.exceptional,
        vehicleIdentificationNumber: vehicle.vehicleId,
        timeZoneId: vehicle.timeZoneId,
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'responseMessages.successfulUpdate',
            'vehicles'
          );
        })
        .catch((error) => {
          const errorCode = vehiclesApiErrors[error.data?.errorCode];
          showSnackbar(
            'error',
            errorCode ? `responseMessages.${errorCode}` : undefined,
            'vehicles'
          );
        });
    },
    [editVehicleExceptionalHours, showSnackbar, vehicle]
  );

  const tabs: TabsType = useMemo(() => {
    return {
      0: {
        id: 0,
        labelTranslationKey: 'regularOperatingHoursTabLabel',
        onAction: handleEditRegularHours,
        contentComponent: <EditVehicleRegularOperatingHours />,
      },
      1: {
        id: 1,
        labelTranslationKey: 'exceptionalOperatingHoursTabLabel',
        onAction: handleEditExceptionalHours,
        contentComponent: (
          <EditVehicleExceptionalOperatingHours
            defaultOperatingTime={
              Object.entries(
                defaultEditVehicleValues.workingHours.regular
              )[0][1]
            }
          />
        ),
      },
    };
  }, [
    defaultEditVehicleValues.workingHours.regular,
    handleEditExceptionalHours,
    handleEditRegularHours,
  ]);

  useEffect(() => {
    if (isError) {
      showSnackbar('error', undefined, 'vehicles');
      handleCloseDialog();
    }
  }, [isError, showSnackbar, handleCloseDialog]);

  if (!vehicle) return null;

  return (
    <ESFormDialog
      open
      fullWidth
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          showExitConfirmation();
        }
      }}
      onSubmit={handleSubmit(tabs[activeTab].onAction)}
    >
      <Stack
        flexDirection="row"
        alignItems="start"
        justifyContent="space-between"
      >
        <ESDialogTitle>{t('editVehicleOperatingHours')}</ESDialogTitle>
        <IconButton onClick={showExitConfirmation} sx={{ m: 2 }}>
          <IconX stroke={1} />
        </IconButton>
      </Stack>
      <FormProvider {...methods}>
        <ESDialogContent
          sx={{
            borderTop: '1px solid',
            borderColor: 'grey.100',
            height: 483,
          }}
        >
          <Grid container spacing={6} sx={{ paddingY: 4 }}>
            <Tabs
              data-testid="editVehicleOperatingHoursTabs"
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              sx={{
                width: '100%',
                '& .MuiTabs-flexContainer': {
                  borderBottom: 'none',
                },
              }}
            >
              {Object.values(tabs).map((tab) => (
                <Tab
                  // User needs to save the changes in the current tab in order to switch to the new one
                  disabled={tab.id !== activeTab && isDirty}
                  key={tab.id}
                  data-testid={`editVehicleOperatingHoursTabs${tab.id}`}
                  label={t(tab.labelTranslationKey)}
                  value={tab.id}
                />
              ))}
            </Tabs>
            {tabs[activeTab].contentComponent}
          </Grid>
        </ESDialogContent>
        <ESDialogActions>
          <ESDialogActionButton
            type="submit"
            disabled={!isValid || !isDirty}
            loading={isEditingRegularHours || isEditingExceptionalHours}
            variant="contained"
          >
            {t('save')}
          </ESDialogActionButton>
        </ESDialogActions>
      </FormProvider>
    </ESFormDialog>
  );
};
