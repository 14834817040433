import { Row, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTableWrapper,
  ESTableFilters,
  useESTableBasic,
  ESTableBasicConfig,
  useElementScrollRestoration,
  ESVirtualizedTable,
} from '@energy-stacks/core/ui';
import {
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnSelect,
  TableRowsCount,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import { BusinessAccountModel } from '@energy-stacks/fleet/feature-business-accounts-data';
import { TanksCountCell } from './TanksCountCell';
import { useNavigate } from 'react-router-dom';
import { useRemovePlantIdPrefix } from './useRemovePlantIdPrefix';
import { useCallback } from 'react';
import { FleetRoutes } from '@energy-stacks/fleet/shared';

interface BusinessAccountsTableProps
  extends ESTableBasicConfig<BusinessAccountModel> {
  businessAccounts: BusinessAccountModel[];
}

const ROW_HEIGHT = 90;
const TABLE_SCROLL_RESTORATION_ID = 'businessAccountsTable';

export const BusinessAccountsTable: React.FC<BusinessAccountsTableProps> = ({
  tableId = 'businessAccounts',
  businessAccounts,
}) => {
  const [t] = useTranslation('businessAccounts');
  const columnHelper = createColumnHelper<BusinessAccountModel>();
  const navigate = useNavigate();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.id), {
      id: 'id',
      sortingFn: 'alphanumeric',
      header: () => t('accountNumber'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {removePlantIdPrefix(info.getValue())}
        </Typography>
      ),
      enableGlobalFilter: true,
      size: 100,
    }),
    columnHelper.accessor('accountType', {
      sortingFn: 'alphanumeric',
      header: () => t('accountType'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">{info.getValue()}</Typography>
      ),
      enableGlobalFilter: true,
      size: 140,
      enableSorting: false,
    }),
    columnHelper.accessor('companyName', {
      sortingFn: 'alphanumeric',
      header: () => t('accountName'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="column">
          <Typography>{info.row.original.phoneNumber}</Typography>
          <Typography>{info.getValue()}</Typography>
        </Stack>
      ),
      enableGlobalFilter: true,
      size: 140,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => `${row.address.city} ${row.address.street}`,
      {
        id: 'address',
        sortingFn: 'alphanumeric',
        header: () => t('address'),
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return (
            <Stack direction="column">
              <Typography>{row.original.address.city || '---'}</Typography>
              <Typography>{row.original.address.street || '---'}</Typography>
            </Stack>
          );
        },
        enableGlobalFilter: true,
        size: 140,
        enableSorting: false,
      }
    ),
    columnHelper.accessor('locations', {
      header: () => t('numberOfTanks'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        return (
          <TanksCountCell
            rawCount={row.original.locations.raw}
            degassedCount={row.original.locations.degassed}
          />
        );
      },
      enableGlobalFilter: false,
      size: 140,
      enableSorting: false,
    }),
  ];

  const { instance, rows, globalFilter, onGlobalFilterChange } =
    useESTableBasic(businessAccounts, columns, {
      tableId,
      fitRowHeight: ROW_HEIGHT,
      initialSorting: [{ id: 'id', desc: true }],
      manualPagination: true,
      persistSorting: true,
      enableColumnResizing: true,
    });

  const scrollCache = useElementScrollRestoration({
    id: TABLE_SCROLL_RESTORATION_ID,
  });

  const handleRowClick = useCallback(
    (row: Row<BusinessAccountModel>) =>
      navigate(`${FleetRoutes.BusinessAccounts}/${row.original.id}`, {
        state: { from: FleetRoutes.BusinessAccounts },
      }),
    [navigate]
  );

  return (
    <>
      <ESTableFilters>
        <ESTooltip title={t('searchBusinessAccountPlaceholder')}>
          <TableSearchField
            placeholder={t('searchBusinessAccountPlaceholder')}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </ESTooltip>
        <Box sx={{ marginLeft: 'auto' }}>
          <TableColumnSelect instance={instance} />
        </Box>
      </ESTableFilters>
      <ESTableWrapper>
        <ESVirtualizedTable
          instance={instance}
          rows={rows}
          onRowClick={handleRowClick}
          rowHeight={ROW_HEIGHT}
          scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
          initialScrollTop={scrollCache.scrollY}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoBusinessAccounts')} />
        ) : null}{' '}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
    </>
  );
};
