import { appendZToDateString } from '@energy-stacks/shared';
import { VehicleDetailsDto } from '../vehicleDetailsDto';
import { ExceptionalHours, VehicleDetailsModel } from '../vehicleDetailsModel';
import {
  ExceptionalHoursDto,
  FALLBACK_TIMEZONE_ID,
} from '@energy-stacks/fleet/shared';

export const vehicleDetailsNormalizer = (
  vehicleDetailsDto: VehicleDetailsDto
): VehicleDetailsModel => {
  return {
    vehicleId: vehicleDetailsDto.vehicleIdentificationNumber,
    plantId: vehicleDetailsDto.depotId,
    name: vehicleDetailsDto.name,
    licencePlate: vehicleDetailsDto.licensePlateNumber,
    status: vehicleDetailsDto.status,
    brand: vehicleDetailsDto.brandName,
    model: vehicleDetailsDto.model,
    variant: vehicleDetailsDto.variant,
    // Setting default values for type and group because values are mandatory, but vehicles created before introduction of those fields wont have them in the database
    type: vehicleDetailsDto.ownershipType || 'internal',
    group: vehicleDetailsDto.usagePriority || 'primary',
    hasUVFilter: vehicleDetailsDto.skippingPenaltyRules || false,
    note: vehicleDetailsDto.notes || '',
    workingHours: {
      regular:
        vehicleDetailsDto.workingHours.regular_hours?.map((day) => ({
          weekday: day.weekday,
          startTime: day.period_begin,
          endTime: day.period_end,
        })) ?? [],
      exceptional: [
        ...exceptionalHoursNormalizer(
          vehicleDetailsDto.workingHours.exceptional_openings ?? [],
          true
        ),
        ...exceptionalHoursNormalizer(
          vehicleDetailsDto.workingHours.exceptional_closings ?? [],
          false
        ),
      ],
    },
    timeZoneId: vehicleDetailsDto.timeZoneId ?? FALLBACK_TIMEZONE_ID,
  };
};

const exceptionalHoursNormalizer = (
  exceptionalHours: ExceptionalHoursDto[],
  isOperating: boolean
): ExceptionalHours[] => {
  return exceptionalHours.map((e) => ({
    isOperating,
    date: appendZToDateString(e.period_begin),
    startDate: appendZToDateString(e.period_begin),
    endDate: appendZToDateString(e.period_end),
  }));
};
