import { LinearProgress } from '@mui/material';

export const TableDataFetchingIndicator = () => {
  return (
    <tr style={{ width: '100%', zIndex: 10, position: 'relative' }}>
      <td style={{ width: '100%', position: 'absolute' }}>
        <LinearProgress />
      </td>
    </tr>
  );
};
