import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { EditingTourJob } from './editingTourJobModel';

export const mapEditingTourJobToJobModel = (
  editingTourJob: EditingTourJob
): JobModel => {
  return {
    dueDate: editingTourJob.dueDate ?? '',
    earliestStartDate: editingTourJob.startTime ?? '',
    endDate: editingTourJob.endTime ?? '',
    plantId: editingTourJob.plantId,
    startDate: editingTourJob.startTime ?? '',
    status: editingTourJob.status || 'TO_DO',
    destination: editingTourJob.destination,
    jobId: editingTourJob.jobId,
    jobType: editingTourJob.jobType,
    origin: editingTourJob.origin,
    product: editingTourJob.product
      ? editingTourJob.product
      : {
          id: '',
          name: '',
          quantity: 0,
          risk: '',
        },
    supplier: editingTourJob.supplier,
    estimatedDuration: editingTourJob.estimatedDuration ?? null,
    businessAccountUid: editingTourJob.businessAccountUid,
    timeZoneId: editingTourJob.timeZoneId,
  };
};
