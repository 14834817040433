import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '@energy-stacks/shared';
import { environment } from '@energy-stacks/feature-config';
import { OpenAPIV3_1 } from 'openapi-types';
import { groupBy, toArray, unionBy } from 'lodash';
import { APICategory } from './apiCategory';
import { apiCategorySort } from './apiCategorySort';
import { OpenAPIV3_1_DocumentWithCustomHeaders } from './openAPIV3_1_DocumentWithCustomHeaders';

export const apiSpecsApi = createApi({
  reducerPath: 'apiSpecs',
  tagTypes: ['apiSpecs'],
  baseQuery: createBaseQuery(environment.storageServerUrl),
  endpoints: (builder) => ({
    getApiSpecs: builder.query<APICategory[], void>({
      queryFn: async (_, _api, _extraArgs, fetchWithBQ) => {
        /**
         * This endpoint fetches OpenAPI specification for each of the back-end services available(broker-web, csms-web, telemetry-service, alert-management-service etc.)
         * These jsons are used to populate the Swagger or Redoc.
         *
         * Each time back-end code is updated, an array of OpenAPI spec JSONs will be rebuilt in the pipeline. These JSONs will then be copied to a storage server to be consumed
         * by API Portal front-end.
         */

        /**
         * Step 1: fetch the list of all available JSONs, this txt list is rebuilt each time back-end code is updated.
         *
         * // doc-list.txt
         * broker-web.json
         * csms-web.json
         * telemetry-service.json
         * alert-management-service.json
         *
         */
        const { error, data: apiListText } = await fetchWithBQ({
          url: 'doc-list.txt',
          method: 'GET',
          responseHandler: 'text',
        });

        if (error) {
          return { error };
        }

        /**
         * Step 2: split txt file into rows to populate an array of json names. Exclude empty rows
         *
         * const filenames = ['broker-web.json', 'csms-web.json', 'telemetry-service.json', 'alert-management-service.json'];
         */
        const filenames = (apiListText as string)
          .split('\n')
          .filter((name) => name);

        /**
         * Step 3: Fetch each JSON from storage server by filename and return all of them at once to be consumed by Components.
         */
        const apiJSONFetches = filenames.map((filename) => {
          return fetchWithBQ({
            url: filename,
            method: 'GET',
          });
        });

        return Promise.all(apiJSONFetches)
          .then((results) => {
            // if any of individual json fetches failed, we consider that a failure for the whole endpoint
            const error = results.find((result) => result.error);
            if (error) {
              return {
                error: error?.error,
              };
            }

            /**
             * We are extending API documentation JSONs to include some custom metadata(custom selected display name of
             * the service to be shown in the home page and "category" in which this service belongs). We are storing
             * this metadata in the "headers" field of each document.
             *
             * Since by OpenAPI the key can be any kind of string, we're at this moment restricting/typing "headers"
             * field to just the keys that FE and BE agreed on: "api-category" and "display-name"
             */
            const jsons = results.map(
              (res) => res.data
            ) as (OpenAPIV3_1.Document &
              OpenAPIV3_1_DocumentWithCustomHeaders)[];

            const specJSONsByCategory = toArray(
              groupBy(
                jsons,
                (json) =>
                  json.components?.headers?.['api-category']?.description
              )
            );

            return {
              data: unionBy(
                specJSONsByCategory
                  .map((singleCategorySpecJSONs) => ({
                    name:
                      singleCategorySpecJSONs[0].components?.headers?.[
                        'api-category'
                      ]?.description || 'Other',
                    services: singleCategorySpecJSONs,
                  }))
                  .sort(apiCategorySort),
                (singleCategorySpecJSONs) => singleCategorySpecJSONs.name
              ),
            };
          })
          .catch((error) => {
            return { error };
          });
      },
      providesTags: ['apiSpecs'],
    }),
  }),
});

export const { useGetApiSpecsQuery } = apiSpecsApi;
