import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ESButton,
  ESDialogContent,
  useIsElementScrollable,
} from '@energy-stacks/core/ui';
import {
  Container,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  setHardResetRequiredForStation,
  useAppDispatch,
  useAppSelector,
} from '@energy-stacks/broker/store';
import { useChargingStationIdentityKey } from '@energy-stacks/shared';
import { brokerManagementApi } from '@energy-stacks/broker/feature-charging-station-management-data';
import { ConfigurationOverviewTable } from './ConfigurationOverviewTable';
import {
  ConfigurationKeyStatus,
  configurationKeyStatusSorter,
} from '@energy-stacks/feature-charging-station-configuration';
import { EditConfigurationListItem } from './edit-configuration-list-item/EditConfigurationListItem';
import { HardResetRequiredDialog } from './HardResetRequiredDialog';
import { BrokerRoutes } from '@energy-stacks/broker/shared';

export const EditConfigurationOverview = () => {
  const dispatch = useAppDispatch();
  const identityKey = useChargingStationIdentityKey();
  const navigate = useNavigate();
  const [configurationUpdateState, setConfigurationUpdateState] = useState<
    'idle' | 'loading' | 'completed' | 'retrying'
  >('idle');
  const keyCompletionResults = useRef<
    Record<string, { status: ConfigurationKeyStatus; value: string }>
  >({});
  const configuration = useAppSelector(
    (state) => state.configurationState.configuration
  );
  const sortedConfiguration = configuration.slice().sort((entryA, entryB) => {
    return configurationKeyStatusSorter(
      keyCompletionResults.current[entryA.key]?.status,
      keyCompletionResults.current[entryB.key]?.status
    );
  });
  const shouldSort =
    configurationUpdateState === 'completed' ||
    configurationUpdateState === 'retrying';
  const displayedConfiguration = shouldSort
    ? sortedConfiguration
    : configuration;
  const [t] = useTranslation('chargingStations');
  const [hardResetDialogOpen, setHardResetDialogOpen] = useState(false);
  const dialogContentScrollable = useIsElementScrollable(
    '#es-configuration-dialog-content',
    6 * 4 // margin between dialog content and dialog actions
  );

  const handleDone = () => {
    dispatch(brokerManagementApi.util.invalidateTags(['ConfigurationShadow']));
    keyCompletionResults.current = {};
    navigateToConfigurationTable();
  };

  const handleCancel = () => {
    navigateToConfigurationTable();
  };

  const navigateToConfigurationTable = useCallback(() => {
    navigate(
      `${BrokerRoutes.ChargingStations}/${identityKey}/${BrokerRoutes.ChargingStationDetailsConfigurationTab}`,
      {
        replace: true,
      }
    );
  }, [navigate, identityKey]);

  const handleSendNewConfiguration = () => {
    const initialStatuses: Record<string, ConfigurationKeyStatus> = {};
    configuration.forEach((config) => {
      initialStatuses[config.key] = ConfigurationKeyStatus.Loading;
    });
    setConfigurationUpdateState('loading');
  };

  const handleKeyComplete = async ({
    key,
    value,
    status,
  }: {
    key: string;
    value: string;
    status: ConfigurationKeyStatus;
  }) => {
    keyCompletionResults.current[key] = {
      status,
      value,
    };
    if (
      Object.keys(keyCompletionResults.current).length === configuration.length
    ) {
      setConfigurationUpdateState('completed');
      checkIfHardResetIsRequired();
    }
  };

  const checkIfHardResetIsRequired = () => {
    const hardResetRequired = Object.values(keyCompletionResults.current).some(
      (result) => result.status === ConfigurationKeyStatus.RebootRequired
    );

    if (hardResetRequired) {
      setHardResetDialogOpen(true);
      dispatch(setHardResetRequiredForStation(identityKey));
    }
  };

  useEffect(() => {
    if (configuration.length === 0) {
      navigateToConfigurationTable();
    }
  }, [configuration, navigateToConfigurationTable]);

  return (
    <>
      <DialogTitle sx={{ px: 8, pt: 8, pb: 6 }}>
        {configurationUpdateState === 'idle' ? (
          <Typography variant="h6" textAlign="center">
            {t('configurationKeysOverviewTitle')}
          </Typography>
        ) : null}

        {configurationUpdateState === 'loading' ||
        configurationUpdateState === 'retrying' ? (
          <Typography variant="h6" textAlign="center">
            {t('configurationKeysUpdating')}
          </Typography>
        ) : null}

        {configurationUpdateState === 'completed' ? (
          <Typography variant="h6" textAlign="center">
            {t('configurationKeyUpdateResultTitle')}
          </Typography>
        ) : null}
      </DialogTitle>

      <ESDialogContent dividers id="es-configuration-dialog-content">
        <Container maxWidth="sm" sx={{ my: 6, display: 'flex', flex: 1 }}>
          {configurationUpdateState === 'idle' ? (
            <ConfigurationOverviewTable
              configuration={configuration}
              onCancel={handleCancel}
              onSend={handleSendNewConfiguration}
            />
          ) : (
            <Stack spacing={2}>
              {displayedConfiguration.map((configurationEntry) => (
                <EditConfigurationListItem
                  configurationEntry={configurationEntry}
                  onComplete={handleKeyComplete}
                  key={configurationEntry.key}
                  onRetry={() => {
                    setConfigurationUpdateState('retrying');
                  }}
                />
              ))}
            </Stack>
          )}
        </Container>

        {/* If there is enough vertical space, show the button just below the list of updated keys */}
        {configurationUpdateState === 'completed' &&
        !dialogContentScrollable ? (
          <DialogActions
            sx={{
              justifyContent: 'center',
              pt: 6,
              pb: 9,
            }}
          >
            <ESButton onClick={handleDone}>
              {t('goBackToConfiguration')}
            </ESButton>
          </DialogActions>
        ) : null}
      </ESDialogContent>

      {/* If not enough vertical space, show default MUI sticky footer and scroll the content above it */}
      {configurationUpdateState === 'completed' && dialogContentScrollable ? (
        <DialogActions
          id="nav-container"
          sx={{
            justifyContent: 'center',
            pt: 6,
            pb: 9,
          }}
        >
          <ESButton onClick={handleDone}>{t('goBackToConfiguration')}</ESButton>
        </DialogActions>
      ) : null}

      <HardResetRequiredDialog
        open={hardResetDialogOpen}
        hardResetEntries={sortedConfiguration.filter(
          (entry) =>
            keyCompletionResults.current[entry.key]?.status ===
            ConfigurationKeyStatus.RebootRequired
        )}
        onDismiss={() => {
          setHardResetDialogOpen(false);
        }}
      />
    </>
  );
};
