import { DatePicker } from '@energy-stacks/core/date-range-picker';
import { usePopover } from '@energy-stacks/core/ui';
import { TableDateFilterButton } from '@energy-stacks/shared';
import { isBefore, isSameDay, startOfDay, subDays } from 'date-fns';

interface TourDetailsDateFilterProps {
  title: string | React.ReactNode;
  isActive?: boolean;
  onDateChange: (date: Date | undefined) => void;
  date?: Date;
  disabled?: boolean;
  currentVehiclePlannedDays: string[];
}

export const TourDetailsDateFilter: React.FC<TourDetailsDateFilterProps> = ({
  title,
  isActive,
  date,
  onDateChange,
  disabled,
  currentVehiclePlannedDays,
}) => {
  const { anchorEl, openPopover, closePopover, popoverOpen } = usePopover();

  return (
    <TableDateFilterButton
      title={title}
      popoverOpen={popoverOpen}
      isActive={isActive}
      onClick={openPopover}
      onClose={closePopover}
      anchorEl={anchorEl}
      disabled={!date || disabled}
      PickerComponent={
        <DatePicker
          date={date || new Date()}
          onDateChange={onDateChange}
          weekStartsOn={1}
          selectablePassive
          scheduledDays={currentVehiclePlannedDays}
          disabledDay={(date) => {
            return (
              isBefore(date, startOfDay(subDays(new Date(), 7))) ||
              !currentVehiclePlannedDays.find((plannedDate) =>
                isSameDay(new Date(plannedDate), new Date(date))
              )
            );
          }}
        />
      }
    />
  );
};
