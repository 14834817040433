import { OpenAPIV3_1 } from 'openapi-types';

export const apiCategoryNames = [
  'CPO Services',
  'Broker Services',
  'MSP Services',
  'Fleet Services',
  'Other',
] as const;
export type APICategoryName = (typeof apiCategoryNames)[number];
export type APICategory = {
  name: APICategoryName;
  services: OpenAPIV3_1.Document[];
};
