import { CommunicationDirectionModel } from '@energy-stacks/broker/feature-ocpp-network-logs-data';
import {
  BrokerToChargingStation,
  BrokerToServer,
  ChargingStationToBroker,
} from '@energy-stacks/shared';
import React, { memo } from 'react';

interface CommunicationDirectionProps {
  communicationDirection: CommunicationDirectionModel;
}

const communicationDirectionMap: Record<
  CommunicationDirectionModel,
  React.ReactElement
> = {
  chargingStationToBroker: <ChargingStationToBroker />,
  brokerToServer: <BrokerToServer />,
};

export const CommunicationDirection: React.FC<CommunicationDirectionProps> =
  memo(({ communicationDirection }) => {
    return communicationDirectionMap[communicationDirection];
  });
