import { PatchObject } from '@energy-stacks/shared';
import { TourModel } from './tourModel';

export type EditTourNoteRequestParam = PatchObject<string>;

export const editTourNoteDataToPayload = (
  note: TourModel['note']
): EditTourNoteRequestParam => {
  return note.trim()
    ? {
        value: note,
        resetValue: false,
      }
    : {
        resetValue: true,
      };
};
