import React, { useState } from 'react';
import { Coords } from '@energy-stacks/fleet/shared';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import {
  IconX,
  IconClockHour5,
  IconArrowLeft,
  IconArrowRight,
  IconArrowRightRhombus,
  IconArrowLeftRhombus,
  IconZoomIn,
} from '@tabler/icons-react';
import { OptimizedTourJob } from '@energy-stacks/fleet/feature-tours-data';
import { formatDate } from '@energy-stacks/shared';
import { formatDuration } from '@energy-stacks/core/ui';
import { JobLocationIcon } from '@energy-stacks/fleet/feature-jobs';
import { useTranslation } from 'react-i18next';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';

type JobMapPopupCardProps = Coords & {
  jobs: OptimizedTourJob[];
  jobPool?: OptimizedTourJob[];
  onAddToTour?: (job: OptimizedTourJob) => void;
  onClose: () => void;
  clusterId: number | undefined;
  style?: React.CSSProperties;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  onShowOnMap: (clusterId: number) => void;
};

export const JobMapPopupCard: React.FC<JobMapPopupCardProps> = ({
  jobs,
  jobPool = [],
  onAddToTour,
  onClose,
  onShowOnMap,
  clusterId,
  style,
  onMouseOver,
  onMouseLeave,
}) => {
  const [t] = useTranslation('tours');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedJob = jobs[selectedIndex];
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  return (
    <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <Box
        width={275}
        bgcolor="white"
        style={style}
        boxShadow="1px 3px 6px rgba(56, 67, 107, 0.25)"
        padding="12px 12px 6px 12px"
        borderRadius={(theme) => theme.spacing(1.5)}
      >
        <Box
          mb="8px"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
          >
            {jobs.length > 1 ? (
              <Typography
                variant="h5"
                sx={{ display: 'inline', fontWeight: 400, fontSize: '1rem' }}
              >
                {selectedIndex + 1}/{jobs.length}{' '}
              </Typography>
            ) : null}
            {removePlantIdPrefix(selectedJob.jobId)}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <IconX size={14} stroke={1.5} />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap="4px"
        >
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box mr="8px">
              {selectedJob.jobType === 'INBOUND' ? (
                <IconArrowRightRhombus style={{ width: 16, height: 16 }} />
              ) : (
                <IconArrowLeftRhombus style={{ width: 16, height: 16 }} />
              )}
            </Box>
            <Typography
              sx={{ fontWeight: 400, lineHeight: '20px', fontSize: '0.75rem' }}
            >
              {selectedJob.jobType} ({selectedJob.product?.quantity})
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap="4px"
        >
          {selectedJob.startTime && selectedJob.endTime && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Box mr="8px" width={16} height={16}>
                <IconClockHour5 style={{ width: 16, height: 16 }} />
              </Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  lineHeight: '20px',
                  fontSize: '0.75rem',
                }}
              >
                {formatDate(selectedJob.startTime, 'HH:mm').toLowerCase()} -{' '}
                {formatDate(selectedJob.endTime, 'HH:mm').toLowerCase()} •{' '}
                {formatDuration(
                  new Date(selectedJob.startTime),
                  new Date(selectedJob.endTime)
                )}
              </Typography>
            </Box>
          )}
          <Box display={'flex'} flexDirection={'row'} alignItems="center">
            {selectedJob.jobType === 'INBOUND' ? (
              <Stack flexDirection="row" alignItems="center">
                {selectedJob.origin && (
                  <JobLocationIcon location={selectedJob.origin} />
                )}
                <Typography
                  sx={{
                    fontWeight: 400,
                    lineHeight: '20px',
                    fontSize: '0.75rem',
                  }}
                >
                  {selectedJob.origin?.id &&
                    removePlantIdPrefix(selectedJob.origin.id)}
                </Typography>
              </Stack>
            ) : (
              <Stack flexDirection="row" alignItems="center">
                {selectedJob.destination && (
                  <JobLocationIcon location={selectedJob.destination} />
                )}
                <Typography
                  sx={{
                    fontWeight: 400,
                    lineHeight: '20px',
                    fontSize: '0.75rem',
                  }}
                >
                  {selectedJob.destination?.id &&
                    removePlantIdPrefix(selectedJob.destination.id)}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 400,
                lineHeight: '20px',
                fontSize: '0.75rem',
              }}
            >
              {selectedJob.product?.name}
            </Typography>
          </Box>
        </Box>
        {jobs.length > 1 && (
          <Stack
            direction="row"
            mt={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={() => onShowOnMap(clusterId as number)}
              size="small"
              variant="contained"
            >
              <IconZoomIn size={16} stroke={1.5} style={{ marginRight: 12 }} />
              {t('showOnMap')}
            </Button>
            <Stack direction="row" gap={2}>
              <IconButton
                size="small"
                onClick={() =>
                  setSelectedIndex((index) =>
                    index - 1 >= 0 ? index - 1 : jobs.length - 1
                  )
                }
              >
                <IconArrowLeft size={16} stroke={1.5} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() =>
                  setSelectedIndex((index) => (index + 1) % jobs.length)
                }
              >
                <IconArrowRight size={16} stroke={1.5} />
              </IconButton>
            </Stack>
          </Stack>
        )}
        {jobPool.find((job) => job.jobId === selectedJob.jobId) && (
          <Stack
            direction="row"
            mt={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={() => onAddToTour?.(selectedJob)}
              size="small"
              style={{ flex: 1 }}
              variant="contained"
            >
              {t('addToTour')}
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
};
