import { TableColumnFilter } from '@energy-stacks/shared';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetVehicleNamesQuery } from '@energy-stacks/fleet/feature-vehicles-data';
import { useAppSelector } from '@energy-stacks/fleet/store';
import { useUpdateEffect } from 'usehooks-ts';

type TableVehicleFilterProps<T> = {
  column: Column<T, unknown> | undefined;
  onApplyFilter?: (value: string[]) => void;
  plant?: 'all' | 'current';
};

export const TableVehicleFilter = <T,>({
  column,
  onApplyFilter,
  plant = 'current',
}: TableVehicleFilterProps<T>) => {
  const { t } = useTranslation('vehicles');
  const plantIds = useAppSelector((state) => state.plant.plantIds);
  const currentPlantId = useAppSelector((state) => state.plant.selectedPlantId);
  const { data: vehicleOptions } = useGetVehicleNamesQuery(
    plant === 'current' ? [currentPlantId ?? ''] : plantIds ?? []
  );

  const filterOptions = useMemo(() => {
    return Object.entries(vehicleOptions ?? {})
      .map(([vehicleName, identificationNumbers]) => ({
        label: vehicleName,
        value: identificationNumbers,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [vehicleOptions]);

  useUpdateEffect(() => {
    if (!column) {
      return;
    }
    if (column.getIsFiltered()) {
      const prev = column.getFilterValue() as string[];
      const newFilters = prev.filter(
        (value) =>
          !!Object.values(vehicleOptions ?? {})
            .flat()
            .find((vehicleId) => vehicleId === value)
      );

      if (prev.length !== newFilters.length) {
        column.setFilterValue(newFilters.length ? newFilters : undefined);
        onApplyFilter?.(newFilters);
      }
    }
  }, [vehicleOptions]);

  return (
    <TableColumnFilter
      isSearchable
      shouldScroll
      column={column}
      columnLabel={t('tableVehicleFilterLabel')}
      onApplyFilter={onApplyFilter}
      options={filterOptions}
      isClearable
    />
  );
};
