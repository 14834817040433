import { alpha, Box, styled } from '@mui/material';
import { DateRangeClasses } from './dateRangeClasses';

export const CalendarWrapper = styled(Box)(({ theme }) => ({
  [`& .${DateRangeClasses.Wrapper}`]: {
    fontFamily: theme.typography.fontFamily,
    paddingTop: 0,
  },
  [`& .${DateRangeClasses.Wrapper} select`]: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${DateRangeClasses.MonthAndYearWrapper}`]: {
    paddingTop: 0,
  },
  [`& .${DateRangeClasses.MonthAndYearWrapper} select option`]: {
    textAlign: 'left',
  },
  [`& .${DateRangeClasses.Wrapper} select:hover`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${DateRangeClasses.NextPrevButton}`]: {
    borderRadius: '1000px',
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${DateRangeClasses.InRange}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  [`& .${DateRangeClasses.InRange} ~ .${DateRangeClasses.DayNumber} span`]: {
    color: `${theme.palette.text.primary} !important`,
  },
  [`& .${DateRangeClasses.Edge}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  [`& .${DateRangeClasses.Edge}::after`]: {
    content: "''",
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    borderRadius: '1000px',
    left: 0,
  },
}));
