import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { formatDateInTimezone } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { JobTimeIcon } from './JobTimeIcon';

export const JobDateTimeCell = ({
  date,
  showWarning = false,
  timeZoneId,
}: {
  date: JobModel['startDate'] | null | undefined;
  showWarning?: boolean;
  timeZoneId: string;
}) => {
  if (!date) {
    return <Typography>---</Typography>;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <JobTimeIcon showWarning={showWarning} />
      <Stack gap={1} alignItems="start">
        <Typography fontWeight={500} color="grey.900">
          {formatDateInTimezone(date, 'h:mm a', timeZoneId).toLowerCase()}
        </Typography>
        <Typography>
          {formatDateInTimezone(date, 'dd/MM/yyyy', timeZoneId)}
        </Typography>
      </Stack>
    </Stack>
  );
};
