import { WebSocketInteraction } from '../NetworkLogEntry';
import { CommunicationDirectionModel } from '../ocppNetworkLogsModel';

export const communicationDirectionNormalizer = (
  messageDirection: WebSocketInteraction
): CommunicationDirectionModel => {
  return communicationDirectionMap[messageDirection];
};

export const communicationDirectionMap: Record<
  WebSocketInteraction,
  CommunicationDirectionModel
> = {
  BROKER_CSMS: 'brokerToServer',
  CS_BROKER: 'chargingStationToBroker',
};

export const communicationDirectionToEnum: Record<
  CommunicationDirectionModel,
  WebSocketInteraction
> = {
  brokerToServer: 'BROKER_CSMS',
  chargingStationToBroker: 'CS_BROKER',
};
