export const CSMSRoutes = {
  Dashboard: '/dashboard',
  ChargingStations: '/asset-management/charging-stations',
  ChargingStationDetails: ':identityKey',
  ChargingStationDetailsGeneralTab: '16/general',
  ChargingStationDetailsConfigurationTab: '16/configuration',
  ChargingStationDetailsConfigurationTabEdit: 'edit',
  ChargingStationDetailsChargingRecordsTab: 'charging-records',
  ChargingStationDetailsChargingSessionsTab: 'charging-sessions',
  ChargingStationDetailsLogsTab: '16/logs',
  AddChargingStation: 'add',
  DeleteChargingStation: ':identityKey/delete',
  Locations: '/asset-management/locations',
  AddChargingLocation: '/asset-management/locations/add',
  Users: '/account-management/users',
  EditUser: ':userId/edit',
  UserAccessRequests: 'requests',
  DeleteLocation: ':locationId/delete',
  MobilityTokens: '/driver-management/mobility-tokens',
  AddMobilityToken: 'add',
  EditMobilityToken: ':uid/edit',
  LocationDetails: '/asset-management/locations/:locationName/:locationId',
  LocationDetailsGeneralTab: 'general',
  ChargingStationGroupsTab: 'charging-station-groups',
  AddChargingStationGroup: 'add',
  RenameChargingStationGroup: ':chargingStationGroupUuid/rename',
  DeleteChargingStationGroup: ':chargingStationGroupUuid/delete',
  Providers: '/account-management/providers',
  AddProvider: 'add',
  EditProvider: ':providerName/edit',
  EntityManagement: '/account-management/entity-management',
  AddEntity: 'add',
  EntityDetails: 'account-management/entity-management/:name/:id',
  AddEntityUser: 'add-entity-user',
  Contracts: '/driver-management/contracts',
  Tariffs: '/asset-management/tariffs',
  AddTariff: 'add',
};
