import { appendZToDateString } from '@energy-stacks/shared';
import { OptimizedTourModel } from '../optimizedTourModel';
import { emptyRunsMetricsNormalizer } from './emptyRunsNormalizer';
import {
  optimizedTourJobsNormalizer,
  TourDetailsDto,
} from './optimizedTourJobsNormalizer';
import { transitionsNormalizer } from './transitionsNormalizer';
import { FALLBACK_TIMEZONE_ID } from '@energy-stacks/fleet/shared';

export const tourDetailsNormalizer = (
  tourDetailsDto: TourDetailsDto
): OptimizedTourModel => {
  const tourJobs = optimizedTourJobsNormalizer({
    contaminationRuleViolations: tourDetailsDto.contaminationRuleViolations,
    jobs: tourDetailsDto.jobs,
    transitionMetrics: tourDetailsDto.transitionMetrics,
    vehicleBreak: tourDetailsDto.vehicleBreak,
  });

  return {
    id: tourDetailsDto.tourUid,
    vehicleName: tourDetailsDto.vehicle.name,
    vehicleId: tourDetailsDto.vehicle.vehicleIdentificationNumber,
    isVehicleDeleted: Boolean(tourDetailsDto.vehicle.deleted),
    date: appendZToDateString(tourDetailsDto.startDate),
    startDate: appendZToDateString(tourDetailsDto.startDate),
    endDate: appendZToDateString(tourDetailsDto.endDate),
    duration: tourDetailsDto.totalTime,
    distance: tourDetailsDto.totalDistance,
    timeZoneId: tourDetailsDto.timeZoneId ?? FALLBACK_TIMEZONE_ID,
    tourJobs: {
      jobs: tourJobs.jobs,
      processes: [...tourJobs.processes],
    },
    totalJobs: tourJobs.jobs.length,
    polyline: {
      // NOTE: If polyline doesn't exist BE will return "UNAVAILABLE", so we don't show anything on the map
      points:
        tourDetailsDto.polyline === 'UNAVAILABLE'
          ? ''
          : tourDetailsDto.polyline,
    },
    status: tourDetailsDto.status,
    emptyRunsMetrics: emptyRunsMetricsNormalizer(
      tourDetailsDto.transitionMetrics
    ),
    transitions: tourDetailsDto.transitionMetrics
      ? transitionsNormalizer(tourDetailsDto.transitionMetrics.transitions)
      : [],
    note: tourDetailsDto.notes || '',
  };
};
